import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import queryString from 'query-string';
import moment from 'moment';
import { downloadExcel } from "../../../helper/exportToExcel";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFileExcel,
} from "@fortawesome/free-solid-svg-icons"

import {
	TITLE_HEADER,
	HEADER,
	BODY_CUSTOMER2,
	BODY_CUSTOMER,
	COLOR_PRIMARY
} from "../../../consts"
import { CUSTOMMER_DETAIL, CUSTOMMER_LIST } from '../../../consts/router'
import "../utils/index.css"
import { USERS } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import AnimationLoading from "../../../helper/loading.js"
import PaginationHelper from "../../../helper/PaginationHelper";

export default function Customer() {
	const { history, location, match } = useReactRouter()
	const parsed = queryString.parse(location.state);

	const { _limit, _page, Pagination_helper } = PaginationHelper();

	// fillter ======>
	var newDate = new Date();
	const [fitterName, setFitterName] = useState(parsed?.localfirstname ? parsed?.localfirstname : '')
	const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
	const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))

	const _customerDetail = (id) => {
		history.push(`${CUSTOMMER_DETAIL}${id}`, id);
	}
	const [dataCustomer, setDataCustomer] = useState()
	const [exportExcelData, setExportExcelData] = useState();
	const [isLoading, setIsLoading] = useState(false)
	useEffect(() => {
		if (!fitterName?.localfirstname) {
			const fetchData = async () => {
				const _localData = await getLocalData()
				if (_localData) {
					getData(_localData)
					getDataExportExcel(_localData)
				}
			}
			fetchData();
		}
	}, [fitterName])
	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				getData(_localData)
				getDataExportExcel(_localData)
			}
		}
		fetchData();
	}, [startDate && endDate])
	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				getData(_localData)
				getDataExportExcel(_localData)
			}
		}
		fetchData();
	}, [endDate && startDate])
	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				getData(_localData)
				getDataExportExcel(_localData)
			}
		}
		fetchData();
	}, [])

	//data for export 
	const getDataExportExcel = async (token) => {
		await fetch(USERS + `limit/${100000}/page/${_page}/?${fitterName ? `&&localfirstname=${fitterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}`, {
			method: "GET",
			headers: token
		}).then(response => response.json())
			.then(json => setExportExcelData(json));
		setIsLoading(false)
	}


	const getData = async (token) => {
		setIsLoading(true)
		await fetch(USERS + `limit/${_limit}/page/${_page}/?${fitterName ? `&&localfirstname=${fitterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}`, {
			method: "GET",
			headers: token
		}).then(response => response.json())
			.then(json => setDataCustomer(json));
		setIsLoading(false)
	}
	const _fitterName = (item) => {
		setFitterName(item?.target?.value)
	}
	// ===== pagegination
	useEffect(() => {
		if (dataCustomer?.total > 0) {
			_getArrayPageNumber()
		}
	}, [dataCustomer])
	const [totalPage, setTotalPage] = useState([]);
	const _getArrayPageNumber = () => {
		let rowPage = [];
		let _total = 0;
		if (dataCustomer?.total % parseInt(_limit) != 0) {
			_total = (parseInt(dataCustomer?.total) / parseInt(_limit)) + 1;
		} else {
			_total = parseInt(dataCustomer?.total) / parseInt(_limit);
		}
		for (let i = 1; i <= _total; i++) {
			rowPage.push(i);
		}
		setTotalPage(rowPage);
	};
	const _startDate = (e) => {
		setstartDate(e?.target?.value)
	}
	const _endDate = (e) => {
		setendDate(e?.target?.value)
	}
	const _nextPage = (page) => {
		history.push(`${CUSTOMMER_LIST}/limit/${_limit}/page/${page}/?${fitterName ? `&&localfirstname=${fitterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}&&` : ''}`)
	}

	// const formatPhone = (data) => {
	// 	let phone = data.split("+856")
	// 	console.log("phone: ", phone[1])

	// 	return phone[1].toString();
	// }

	const exportToExcel = async () => {
		console.log("exportExcelData: ")
		if (exportExcelData) {
			const rows = await exportExcelData?.results?.map((data, index) => ({
				index: index + 1,
				customercd: data?.customercd ?? "",
				localfirstname: data?.localfirstname ?? "",
				localfamilyname: data?.localfamilyname ?? "",
				expresscardstatus: data?.expresscardstatus === "active" ? "Member" : "Non Member",
				mobileno: `${data?.mobileno?.replace("+856", "0")}` ?? "",
				createdAt: moment(data?.createdAt).format('DD/MM/YYYY') ?? ""
			}));

			const titles = ["NO.",
				"Customer Code",
				"Name",
				"LastName",
				"Customer type",
				"Phone number",
				"Registration date",
			]

			const fileName = `Customer-${moment().format("DDMMYYYYHHmmss")}.xlsx`
			await downloadExcel(titles, rows, fileName)
		}
	}



	return (
		<div style={TITLE_HEADER}>

			<h6 style={HEADER}>
				<p>Customer » </p>
			</h6>
			<div style={BODY_CUSTOMER}>
				<div>
					<p style={{ fontWeight: "bold" }}>Search</p>
					<input type="text" className="col-md-12 form-control" value={fitterName} placeholder="Search By Name..." onChange={(item) => _fitterName(item)} />
				</div>
				<div style={{ height: 10 }}></div>
				<div className="row">
					<div className="col-md-4">
						<p style={{ fontWeight: "bold" }}>Date from</p>
						<input type="date" className="form-control col-12" value={startDate} onChange={(e) => _startDate(e)} />
					</div>
					<div className="col-md-4">
						<p style={{ fontWeight: "bold" }}>Date to</p>
						<input type="date" className="form-control col-12" value={endDate} onChange={(e) => _endDate(e)} />
					</div>
				</div>
			</div>
			<div style={BODY_CUSTOMER2}>
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					<p style={{ padding: 20, fontSize: 25 }} > Customer ({dataCustomer?.total ? dataCustomer?.total : dataCustomer?.results?.length})</p>
					{/* <div style={{ padding: 20, paddingTop: 50 }} >
						<ExcelButton name="Customers"/>
					</div> */}
					<div style={{ padding: 20, paddingTop: 30 }}>
						<div>
							<center>
								<button
									className="btn"
									style={{
										border: `1px solid ${exportExcelData ? COLOR_PRIMARY : "grey"}`,
										backgroundColor: "white",
										color: exportExcelData ? COLOR_PRIMARY : "grey",
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
										// padding: '10px'
									}}
									onClick={exportToExcel}
								><FontAwesomeIcon icon={faFileExcel} color={exportExcelData ? COLOR_PRIMARY : "grey"} />
									<span style={{ marginLeft: 10 }}>Download Excel</span>
								</button>
								<div style={{ fontSize: 14 }}>(Download only 10,000 items per time)</div>
							</center>
						</div>
					</div>
				</div>
				{/* <p style={{ padding: 20, fontSize: 15 }} >1-{dataCustomer?.results?.length}</p> */}
				<div>
					{isLoading ? <AnimationLoading /> : <div>
						<div className="col-sm-12" style={{ marginTop: 20 }}>
							<table className="table table-hover" id="table-to-xls">
								<thead className="thead-light">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Name</th>
										<th scope="col">Last name</th>
										<th scope="col">Customer type</th>
										<th scope="col">Phone number</th>
										<th scope="col">Registration date</th>
									</tr>
								</thead>
								<tbody>
									{dataCustomer?.results?.map((data, index) => {
										return (
											<tr key={index} onClick={() => _customerDetail(data?._id)}>
												<th scope="row">{index + 1 + (parseInt(_limit) * parseInt(_page - 1))}</th>
												<td>{data?.localfirstname}</td>
												<td>{data?.localfamilyname}</td>
												<td>{data?.expresscardstatus}</td>
												<td>{data?.mobileno}</td>
												<td>{formatDate(data?.createdAt)}</td>
											</tr>
										)
									})}
								</tbody>
							</table>
							<div className="row center">
								{Pagination_helper(dataCustomer?.total ? dataCustomer?.total : dataCustomer?.results?.length, "/customerList", `${fitterName ? `&&localfirstname=${fitterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}&&` : ''}`)}
								{/* {totalPage?.map((item, index) => {
									return (
										<button style={{
											width: 30,
											height: 30,
											border: "solid 1px #816aae",
											marginLeft: 2,
											backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
											color: parseInt(_page) == index + 1 ? "#fff" : "#000",
										}} onClick={() => _nextPage(item)}
											key={item}
										>{item}</button>
									)
								})} */}
							</div>
						</div>
					</div>
					}
				</div>
			</div>
		</div>
	)
}
