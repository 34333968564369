import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import axios from 'axios';
import Image from "react-bootstrap/Image"

import {
    TITLE_HEADER,
    HEADER,
    BODY,
} from "../../../consts"
import {
    STAFF_DETAIL,
    STAFF_ADD,
    STAFF_LIST
} from '../../../consts/router'
import profileImage from "../../../image/profile.png"
import { STAFFS_LIST, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import AnimationLoading from "../../../helper/loading.js"

export default function StaffList() {
    const { history, location, match } = useReactRouter()
    const _limit = match.params.limit;
    const _page = match.params.page;
    const _staffDetail = (id) => {
        history.push(`${STAFF_DETAIL}${id}`, id)
    }
    const _staffAdd = () => {
        history.push(`${STAFF_ADD}`)
    }
    const [staffData, setStaffData] = useState()
    const [isLoading, setIsLoading] = useState(false)
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(STAFFS_LIST + "/limit/10/page/1/", {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setStaffData(json));
        setIsLoading(false)
    }
    useEffect(() => {
        if (staffData?.total > 0) {
            _getArrayPageNumber()
        }
    }, [staffData])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (staffData?.total % parseInt(_limit) != 0) {
            _total = (parseInt(staffData?.total) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(staffData?.total) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _nextPage = (page) => {
        history.push(`${STAFF_LIST}/limit/${_limit}/page/${page}`)
    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading /> : <div>
                <h6 style={HEADER}>
                    <p>All employees » </p>
                </h6>
                <div style={BODY}>
                    <div className="row" style={{ padding: 30 }}>
                        <div className="col-md-12" style={{ fontSize: "20px" }}>All employees ({staffData?.total})</div>
                    </div>
                    <div style={{ paddingBottom: 20 }}>
                        <div className="col-md-12" >
                            <button type="button" className="btn btn-secondary btn-app col-2 " style={{ float: "right" }} onClick={() => _staffAdd()}> Add staff </button>
                        </div>
                        <div className="row" style={{ paddingLeft: 30 }}>
                            <p style={{ color: "#5C5C5C" }}>{_page}-{staffData?.total}</p>
                        </div>
                    </div>
                    <div>
                        <div className="col-sm-12">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Pictures</th>
                                        <th scope="col">Email</th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Last name</th>
                                        <th scope="col">Role</th>
                                        <th scope="col">Phone number</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {staffData?.result?.map((data, index) => {
                                        return (
                                            <tr onClick={() => _staffDetail(data?._id)}>
                                                <th scope="row">{index + 1 + parseInt(_limit) * parseInt(_page - 1)}</th>
                                                <td>
                                                    {data?.image ? (
                                                        <center>
                                                            <Image src={URL_PHOTO_AW3 + data?.image} alt="AeonIcon" width="150" height="150" style={{
                                                                height: 50,
                                                                width: 50,
                                                                borderRadius: '50%',
                                                            }} />
                                                        </center>
                                                    ) : (<center>
                                                        <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                            height: 50,
                                                            width: 50,
                                                            borderRadius: '50%',
                                                        }} />
                                                    </center>)}
                                                </td>
                                                <td>{data?.email}</td>
                                                <td>{data?.firstName}</td>
                                                <td>{data?.lastName}</td>
                                                <td>{data?.userauth?.role ? data?.userauth?.role : " - "}</td>
                                                <td>{data?.phoneNumber}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="row center">
                                {totalPage?.map((item, index) => {
                                    return (
                                        <button style={{
                                            width: 30,
                                            height: 30,
                                            border: "solid 1px #816aae",
                                            marginLeft: 2,
                                            backgroundColor: parseInt(_page) == index + 1 ? "#816aae" : "#fff",
                                            color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                        }} onClick={() => _nextPage(item)}
                                            key={item}
                                        >{item}</button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}