// custommer
export const CUSTOMMER_LIST = "/customerList";
export const CUSTOMMER_DETAIL = "/customerList/customerDetail/";
export const CUSTOMMER_HISTORY = "/customerList/CustomerHistory/";

// promotion
export const PROMOTION_LIST = "/promotionList";
export const PROMOTION_ADD = "/promotionList/promotionAdd";
export const PROMOTION_SLIDE_ADD = "/promotionList/promotionSlideAdd";
export const PROMOTION_POPUP_ADD = "/promotionList/promotionPopupAdd";
export const PROMOTION_POPUP_EDIT = "/promotionList/promotionPopupEdit/";
export const PROMOTION_SLIDE_DETAIL = "/promotionList/promotionSlideDetail";
export const PROMOTION_EDIT = "/promotionList/promotionEdit/";
export const PROMOTION_DETAIL = "/promotionList/promotionDetail/";
export const PROMOTION_SLIDE_LIST = "/promotionList/slideList";
export const PROMOTION_POPUP_LIST = "/promotionList/popUp";

//notice
export const NOTIFY_LIST = "/notifyList";
export const NOTIFY_ADD = "/notifyList/notifyAdd";
export const NOTIFY_DETAIL = "/notifyList/notifyDetail/";
export const NOTIFY_EDIT = "/notifyList/notifyEdit/";

// Store
export const STORE = "/store";
export const STORE_DISCOUNT_LIST = "/store/discountList";
export const DISCOUNT_SHOP_DETAIL = "/store/discountShopDetail/";
export const DEALER_SHOP_DETAIL = "/store/dealerShopDetail/";
export const DEALER_SHOP_ADD = "/store/dealerShopAdd";
export const DISCOUNT_SHOP_ADD = "/store/discountShopAdd";
export const DEALER_SHOP_EDIT = "/store/dealerShopEdit/";
export const DISCOUNT_SHOP_EDIT = "/store/discountShopEdit/";

// Admin Portal
export const ADMIN_PORTAL = "/admin-portal";
export const ALL_PRODUCT_REQUESTING = "/requesting";

// PRODUCT
export const PRODUCT = "/product";

// PRODUCT
export const MODEL = "/model";

// notification
export const NOTIFICATION_LIST = "/notificationList";
export const NOTIFICATION_ADD = "/notificationAdd";
export const NOTIFICATION_DETAIL = "/notificationDetail";
export const NOTIFICATION_EDIT = "/notificationEdit";

// issueReporT
export const ISSUE_REPORT_LIST = "/issueReportList";
export const ISSUE_REPORT_DETAIL = "/issueReportList/issueReportDetail/";

// staff
export const STAFF_LIST = "/staffList";
export const STAFF_DETAIL = "/staffList/staffDetail/";
export const STAFF_ADD = "/staffList/staffAdd";
export const STAFF_EDIT = "/staffList/staffEdit/";

// Setting
export const SETTING_LIST = "/settingList";
export const CACULARTOR_LIST = "/settingList/configCaculartor";
export const CACULARTOR_DETAIL = "/settingList/settingCategoryDetail/";
export const CACULARTOR_EDIT = "/settingList/settingCategoryEdit";
export const CACULARTOR_ADD = "/settingList/settingCategoryAdd";

// locationList
export const LOCATION_LIST = "/locationList";
export const LOCATION_LAST_LOGIN = "/locationList/locationLastlogin";
export const LOCATION_LAST_LONG = "/locationList/historyLastLogin/";

// dashboard
export const DASHBOARD = "/dashboard";
