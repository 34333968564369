import React, { useState, useEffect } from "react";
import axios from "axios";
import useReactRouter from "use-react-router";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DEALERLIST2,
  BODY_DEALERLIST,
  LIMIT,
} from "../../../consts";
import { ENPOINTE_API } from "../../../consts/api";
import { ADMIN_PORTAL } from "../../../consts/router";
import { getLocalData } from "../../../consts/tokken";
import Detail from "./Detail";
import ProductWaiting from "./ProductWaiting";
import Product from "./Product";
import ProductReject from "./ProductReject";

export default function AdminPortalDetail() {
  const { history, location, match } = useReactRouter();
  const shopId = match.params.id;
  const limit = 40;
  const pageNumber = match.params.page;
  var detailTap = match.params.tap;
  const [unReadApproved, setUnReadApproved] = useState(
    () => Number(localStorage.getItem("unReadApproved")) || 0
  );
  const [unReadRequesting, setUnReadRequesting] = useState(
    () => Number(localStorage.getItem("unReadRequesting")) || 0
  );
  const [unReadRejected, setUnReadRejected] = useState(
    () => Number(localStorage.getItem("unReadRejected")) || 0
  );

  useEffect(() => {
    const fetchData = async (token) => {
      const res = await axios(
        `${ENPOINTE_API}/dealer-product/v2/limit/${limit}/page/${pageNumber}?shopPortal=${shopId}`,
        {
          method: "GET",
          headers: token,
        }
      );

      const data = await res.data;
      console.log("data", data);
      setUnReadApproved(data?.unReadNewApprovedTotal);
      setUnReadRequesting(data?.unReadNewRequestingTotal);
      setUnReadRejected(data?.unReadNewRejectedTotal);
    };

    const getToken = async () => {
      const token = await getLocalData("token");
      if (token) {
        fetchData(token);
      }
    };

    getToken();
  }, [pageNumber, shopId]);

  useEffect(() => {
    localStorage.setItem("unReadApproved", unReadApproved);
    localStorage.setItem("unReadRequesting", unReadRequesting);
    localStorage.setItem("unReadRejected", unReadRejected);
  }, [unReadApproved, unReadRequesting, unReadRejected]);

  useEffect(() => {
    if (detailTap === "2") {
      localStorage.setItem("unReadApproved", unReadApproved);
    }
    if (detailTap === "3") {
      localStorage.setItem("unReadRequesting", unReadRequesting);
    }
    if (detailTap === "4") {
      localStorage.setItem("unReadRejected", unReadRejected);
    }
  }, [detailTap, unReadApproved, unReadRequesting, unReadRejected]);

  return (
    <div style={TITLE_HEADER}>
      <h6 style={HEADER}>
        <p>
          <span
            onClick={() => history.push(`${ADMIN_PORTAL}/limit/40/page/1`)}
            style={{
              textDecoration: "underline",
              color: "blue",
              cursor: "pointer",
            }}
          >
            Admin Portal
          </span>{" "}
          » Detail
        </p>
      </h6>
      <div style={{ width: "95%", marginRight: 80, marginLeft: 30 }}>
        <ul className="nav nav-tabs">
          <li className="nav-item">
            <span
              onClick={() =>
                history.push(
                  `${ADMIN_PORTAL}/detail/${shopId}/tap/1/limit/40/page/1`
                )
              }
              className={"nav-link " + (detailTap == 1 ? "active" : "")}
              style={{ color: "#1492E6", cursor: "pointer" }}
            >
              Shop Detail
            </span>
          </li>
          <li className="nav-item">
            <span
              onClick={() =>
                history.push(
                  `${ADMIN_PORTAL}/detail/${shopId}/tap/2/limit/40/page/1`
                )
              }
              className={"nav-link " + (detailTap == 2 ? "active" : "")}
              style={{ color: "grey", cursor: "pointer" }}
            >
              All Product{" "}
              <span
                style={{
                  color: "red",
                }}
              >{`(${unReadApproved})`}</span>
            </span>
          </li>
          <li className="nav-item">
            <span
              onClick={() =>
                history.push(
                  `${ADMIN_PORTAL}/detail/${shopId}/tap/3/limit/40/page/1`
                )
              }
              className={"nav-link " + (detailTap == 3 ? "active" : "")}
              style={{ color: "#1492E6", cursor: "pointer" }}
            >
              Product pending approval{" "}
              <span
                style={{
                  color: "red",
                }}
              >{`(${unReadRequesting})`}</span>
            </span>
          </li>
          <li className="nav-item">
            <span
              onClick={() =>
                history.push(
                  `${ADMIN_PORTAL}/detail/${shopId}/tap/4/limit/40/page/1`
                )
              }
              className={"nav-link " + (detailTap == 4 ? "active" : "")}
              style={{ color: "#1492E6", cursor: "pointer" }}
            >
              Product Reject{" "}
              <span
                style={{
                  color: "red",
                }}
              >{`(${unReadRejected})`}</span>
            </span>
          </li>
        </ul>
      </div>
      <div
        className="col-sm-12"
        style={{ ...BODY_DEALERLIST, height: "100%", paddingTop: 16 }}
      >
        <div className="tab-content" id="myTabContent">
          {detailTap == 2 ? (
            <Product id={shopId} />
          ) : detailTap == 1 ? (
            <Detail id={shopId} />
          ) : detailTap == 3 ? (
            <ProductWaiting />
          ) : (
            <ProductReject />
          )}
        </div>
      </div>
    </div>
  );
}
