import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import profileImage from "../../../image/profile.png"
import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    SETTING_LIST,
    CACULARTOR_DETAIL,
    CACULARTOR_ADD,
    CACULARTOR_LIST
} from '../../../consts/router'
import AnimationLoading from "../../../helper/loading.js"
import { formatDateTime } from '../../../common/super'
import { SETTING_STORE_CATEGORY, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

export default function ConfigCaculartor() {
    const { history, location, match } = useReactRouter()
    const _limit = match.params.limit;
    const _page = match.params.page;
    const _caculartor = () => {
        history.push(`${SETTING_LIST}`)
    }
    const _settingCategoryDetail = (id) => {
        history.push(`${CACULARTOR_DETAIL}${id}`, id)
    }
    const _settingCategoryAdd = () => {
        history.push(`${CACULARTOR_ADD}`)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [settingList, setSettingList] = useState()
    const [allPage, setAllPage] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
        _getArrayPageNumber()
    }, [])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(SETTING_STORE_CATEGORY + "/limit/" + _limit + "/page/" + _page, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => {
                setSettingList(json?.results)
                setAllPage(json?.total)
            });
        setIsLoading(false)
    }


    useEffect(() => {
        if (allPage > 0) {
            _getArrayPageNumber()
        }
    }, [allPage])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (allPage % parseInt(_limit) != 0) {
            _total = (parseInt(allPage) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(allPage) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _nextPage = (page) => {
        history.push(`${CACULARTOR_LIST}/limit/${_limit}/page/${page}`)
    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading />
                : <div>
                    <h6 style={HEADER}>
                        <a href="#" onClick={() => _caculartor()}>Setting » </a> Set up a Shopfront
            </h6>
                    <div style={BODY}>
                        <div className="row" style={{ padding: 30 }}>
                            <div className="col-md-12" style={{ fontSize: "20px" }}>Set up a Shopfront for all Shops ( {settingList?.length} )</div>
                        </div>
                        <div className="col-md-12" >
                            <button type="button" className="btn btn-secondary btn-app col-2 " style={{ float: "right" }} onClick={() => _settingCategoryAdd()}> Add Loan calculation </button>
                        </div>
                        <div style={{ paddingBottom: 20 }}>
                            <div className="row" style={{ paddingLeft: 30 }}>
                                <p style={{ color: "#5C5C5C" }}>{_page}-{settingList?.length}</p>
                            </div>
                        </div>
                        <div>
                            <div className="col-sm-12">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Picture</th>
                                            <th scope="col">Shop category</th>
                                            <th scope="col">Member percentage</th>
                                            <th scope="col">Non member percentage</th>
                                            <th scope="col">Create date</th>
                                            <th scope="col">Update date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {settingList?.map((item, index) => {
                                            return (
                                                <tr onClick={() => _settingCategoryDetail(item?._id)}>
                                                    <th scope="row">{index + 1 + parseInt(match?.params?.limit) * parseInt(match?.params?.page - 1)}</th>
                                                    <td>
                                                        {item?.image ? (
                                                            <center>
                                                                <Image src={URL_PHOTO_AW3 + item?.image} alt="AeonIcon" width="150" height="150" style={{
                                                                    height: 50,
                                                                    width: 50,
                                                                    borderRadius: '50%',
                                                                }} />
                                                            </center>
                                                        ) : (<center>
                                                            <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                                height: 50,
                                                                width: 50,
                                                                borderRadius: '50%',
                                                            }} />
                                                        </center>)}
                                                    </td>
                                                    <td>{item?.name}</td>
                                                    <td>{item?.member_interest ? item?.member_interest : "0"} %</td>
                                                    <td>{item?.non_member_interest ? item?.non_member_interest : "0"} %</td>
                                                    <td>{formatDateTime(item?.createdAt)}</td>
                                                    <td>{formatDateTime(item?.updatedAt)}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div className="row center">
                                {totalPage?.map((item, index) => {
                                    return (
                                        <button style={{
                                            width: 35,
                                            height: 35,
                                            border: "solid 1px #816aae",
                                            marginLeft: 2,
                                            backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                                            color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                        }} onClick={() => _nextPage(item)}
                                            key={item}
                                        >{item}</button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div >
            }
        </div>
    )
}