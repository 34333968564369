


import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"

import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts"
import {
	NOTIFICATION_LIST,
	NOTIFICATION_EDIT
}from "../../../consts/router"
export default function NotificationDetail() {
    const { history, location, match } = useReactRouter()
    // history
    const _notificationList = () => {
        history.push(`${NOTIFICATION_LIST}`)
    }
    const _notificationEdit = () => {
        history.push(`${NOTIFICATION_EDIT}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _notificationList()}>ແຈ້ງເຕືອນທັງໝົດ » </a>ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ
            </h6>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ padding: 20 }}>
                    <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ</div>
                    <div className="col-md-12" style={{ textAlign: 'right' }}>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px #816AAE", color: "#816AAE" }} >ລຶບ</button>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _notificationEdit()}><i className="fas fa-pencil-alt"></i> ແກ້ໄຂ</button>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-3">
                            <div style={{height:"300px"}}></div>
                            <div className="row">
                                <div className="col-4">
                                    <p style={{ color: "#909090" }}>ສ້າງໂດຍ</p>
                                </div>
                                <div className="col-8">
                                    <p>ກັນລະຍາ</p>
                                    <p>2020/02/19 20:23</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-4">
                                    <p style={{ color: "#909090" }}>ອັບເດດໂດຍ</p>
                                </div>
                                <div className="col-8">
                                    <p>ຕ່າຍ</p>
                                    <p>2020/02/18 17:00</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>ໂປໂມຊັນທີ່ເລືອກ</p>
                                </div>
                                <div className="col-9">
                                    <p>ໂປຣໂມຊັນເຄື່ອງໄຊ້ໄຟຟ້າ</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>ຫົວຂໍ້</p>
                                </div>
                                <div className="col-9">
                                    <p>ແຈກໆໂປຣໂມຊັນເຄື່ອງໄຊ້ໄຟ້</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>ລາຍລະອຽດ</p>
                                </div>
                                <div className="col-9">
                                    <p>ພຽງແຕ່ທ່ານຊື້ອຸປະກອນໄຟຟ້າຕາມຮ້ານຄ້າທີ່ຮ່ວມບໍລິການໃກ້ບ້ານທ່ານ</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>ເວລາແຈ້ງເຕືອນ</p>
                                </div>
                                <div className="col-9">
                                    <p>10/29/2020 - 7:00 Am</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
