import moment from "moment";
import { ImBin } from "react-icons/im";
import React, { useState, useEffect } from "react";
import axios from "axios";
import useReactRouter from "use-react-router";
import { Modal, Button } from "react-bootstrap";
import {
  ADMIN_KEY,
  BODY_DEALERLIST,
  BODY_DEALERLIST2,
  COLOR_PRIMARY,
  customStyles,
} from "../../../consts";
import {
  GET_MANY_SHOP_PORTAL,
  PRODUCT_BRAND,
  PRODUCT_MODEL,
  SHOP_PRODUCT_API,
  GET_ALL_DEALER_PRODUCT,
  DELETE_DEALER_REJEDT_ONE,
  DELETE_DEALER_ALL_REJECT,
  ENPOINTE_API,
} from "../../../consts/api";
import { ADMIN_PORTAL } from "../../../consts/router";
import { getLocalData } from "../../../consts/tokken";
import "./style.css";
import styled from "styled-components";
import AnimationLoading from "../../../helper/loading";
import Select from "react-select";

//helper
import { formatDate, thousand } from "../../../helper";

export default function ProductReject({ id }) {
  const { match, history } = useReactRouter();
  const _limit = 40;
  const _page = match.params.page;
  const shopPortalId = match?.params?.id;

  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brands, setBrands] = useState([]);

  const [modelId, setModelId] = useState("");
  const [modelName, setModelName] = useState("");
  const [models, setModels] = useState([]);

  const [adminShopPortalData, setAdminShopPortalData] = useState({});
  const [shopCategories, setShopCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [shopProducts, setShopProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);

  const [getTokken, setgetTokken] = useState();
  const [totalPage, setTotalPage] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [productData, setProductData] = useState();

  const [show, setShow] = useState(false);

  const handleCloseConfrimDelete = () => setShow(false);
  const handleShowConfirm = () => setShow(true);

  const [showAllDelete, setShowAllDelete] = useState(false);

  const handleCloseAllDelete = () => setShowAllDelete(false);
  const handleShowAllDelete = () => setShowAllDelete(true);

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        console.log(_localData?.Authorization);
        setgetTokken(_localData);
        getProductDatas(_localData);
        fetchAdminShopPortalData(_localData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getShopProductByFilter();
  }, [categoryId, brandId, startDate, endDate]);

  useEffect(() => {
    getShopProductByFilter();
  }, [pageNumber]);

  const getProductDatas = async (token) => {
    try {
      setIsLoading(true);
      let reqUrl = `&&${categoryId ? `productCategory=${categoryId}&&` : ""}${
        brandId ? `brand=${brandId}&&` : ""
      }${modelId ? `model=${modelId}&&` : ""}${
        startDate && endDate
          ? `startDate=${startDate}&&endDate=${moment(
              moment(endDate).add(1, "days")
            ).format("YYYY-MM-DD")}`
          : ""
      }`;
      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${_page}/?shopPortal=${match.params.id}&status=REJECTED${reqUrl}`,
        {
          method: "GET",
          headers: token,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setProductTotal(json?.total ?? 0);
          if (json?.total > 0) {
            _getArrayPageNumber(json?.total);
          }
          setShopProducts(json?.result ?? []);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const getShopProductByFilter = async () => {
    try {
      var data;
      if (categoryId && !brandId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REJECTED&shopPortal=${shopPortalId}&productCategory=${categoryId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if (brandId && !categoryId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REJECTED&shopPortal=${shopPortalId}&brand=${brandId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if (categoryId && brandId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REJECTED&shopPortal=${shopPortalId}&productCategory=${categoryId}&brand=${brandId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if ((!categoryId && !brandId && startDate) || endDate) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REJECTED&shopPortal=${shopPortalId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      }
      console.log("data ==>>> ", data);
      if (data.status >= 300) return { error: true, msg: "NOT_FOUND" };
      if (data?.data) {
        setTotalItems(data?.data?.total);
        setShopProducts(data?.data?.result);
        _getArrayPageNumber(data?.data?.total);
        setTotalPage(parseInt(data?.data?.total / _limit) + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdminShopPortalData = async (token) => {
    try {
      setIsLoading(true);
      await fetch(`${GET_MANY_SHOP_PORTAL}/${match?.params?.id}`, {
        method: "GET",
        headers: token,
      })
        .then((response) => response.json())
        .then((json) => {
          setAdminShopPortalData(json);
          if (json.shopCategories) setShopCategories(json.shopCategories);
        });
      setIsLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
    }
  };

  const handleChangeCategory = (id) => {
    if (id) {
      getBrand(id);
    }
    setCategoryId(id);
    setBrandName("");
    setBrands(null);
    setBrandId("");
  };

  const getBrand = async (category) => {
    try {
      await fetch(
        `${PRODUCT_BRAND}/?${category ? `category=${category}` : ""}`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          const options = json.map((d) => ({
            value: d._id,
            label: d.name,
          }));
          setBrands(options ?? []);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const _startDate = (e) => {
    setstartDate(e?.target?.value);
  };
  const _endDate = (e) => {
    setendDate(e?.target?.value);
  };
  const _nextPage = async (page) => {
    setPageNumber(parseInt(page));
  };

  const _getArrayPageNumber = (_dataCustomerTotal) => {
    let rowPage = [];
    let _total = 0;
    if (_dataCustomerTotal % parseInt(_limit) != 0) {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setProductData(rowPage);
  };

  const [productId, setProductId] = useState();

  const deleteProductReject = async () => {
    try {
      setIsLoading(true);
      const _deleteProduct = await axios(
        `${(DELETE_DEALER_REJEDT_ONE, DELETE_DEALER_ALL_REJECT)}${productId}`,
        {
          method: "delete",
          headers: getTokken,
        }
      );

      if (_deleteProduct?.data?.message === "SUCCESSFULLY") {
        await handleCloseConfrimDelete();
        await getProductDatas();
        await setIsLoading(false);
      }
    } catch (err) {
      console.log("err::::", err);
    }
  };

  const deleteProductALLReject = async () => {
    try {
      setIsLoading(true);
      const _deleteProduct = await axios(`${DELETE_DEALER_ALL_REJECT}`, {
        method: "delete",
        headers: getTokken,
        data: {
          shopPortal: shopPortalId,
        },
      });

      if (_deleteProduct?.data?.message === "SUCCESSFULLY") {
        await handleCloseAllDelete();
        await getProductDatas();
        await setIsLoading(false);
      }
    } catch (err) {
      console.log("err::::", err);
    }
  };

  const handleChangeUnread = async (id) => {
    try {
      let dataToUpdate = {
        unReadNewRejected: false,
      };
      const res = await axios(
        `${ENPOINTE_API}/dealer-product/v2/update-unread-product-status/${id}`,
        {
          method: "put",
          headers: getTokken,
          data: dataToUpdate,
        }
      );

      if (res) console.log(res);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const _gotoProductDetail = (id) => {
    handleChangeUnread(id);
    history.push(`${ADMIN_PORTAL}/product/detail/${id}/page/${1}`);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          padding: "0 20px",
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: 18 }}>
          Products Rejected ({productTotal ?? 0})
        </p>
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginBottom: 20,
          // marginLeft: '20px',
          //padding: 50,
          width: "100%",
          height: "auto",
          paddingTop: 24,
        }}
      >
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="row">
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Product Category</span>
                <select
                  name="productCategory"
                  className="btn-block form-control"
                  onChange={(e) => handleChangeCategory(e.target.value)}
                  value={categoryId}
                >
                  <option value="">All Categories</option>
                  {shopCategories.length > 0 &&
                    shopCategories.map((item, index) => (
                      <option key={index} value={item?._id}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Brand</span>
                <Select
                  placeholder="All Brands"
                  styles={customStyles}
                  value={brandName}
                  options={brands}
                  onChange={(e) => {
                    setBrandName(e);
                    setBrandId(e.value);
                  }}
                />
              </div>
              <SpaceOnFilter />
              <div className="col-md-2">
                <span style={{ fontWeight: "bold" }}>Registered from</span>
                <input
                  type="date"
                  className="form-control col-12"
                  value={startDate}
                  onChange={(e) => _startDate(e)}
                />
              </div>
              <div className="col-md-2">
                <span style={{ fontWeight: "bold" }}>Register to</span>
                <input
                  type="date"
                  className="form-control col-12"
                  value={endDate}
                  onChange={(e) => _endDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style={{ ...BODY_DEALERLIST2, paddingTop: 24, minHeight: "85vh" }}>
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <p>
                  {" "}
                  {shopProducts.length > 0 ? shopProducts.length : 0} /{" "}
                  {totalItems}
                </p>
              </div>
              <div>
                <button
                  type="button"
                  className="btn"
                  style={{
                    backgroundColor: COLOR_PRIMARY,
                    color: "#ffffff",
                    textAlign: "center",
                  }}
                  onClick={handleShowAllDelete}
                  disabled={shopProducts.length > 0 ? false : true}
                >
                  <ImBin size="18px" className="pb-1" /> Delete All
                </button>
              </div>
            </div>
          </div>
        </div>

        <div>
          {isLoading ? (
            <AnimationLoading />
          ) : (
            <div>
              {/* <div className="col-sm-12"> */}
              <table className="table table-hover mt-2">
                <thead className="thead-light">
                  <tr>
                    <th scope="col">No.</th>
                    <th scope="col">Category</th>
                    <th scope="col">Brand</th>
                    <th scope="col">Model</th>
                    <th scope="col">Description</th>
                    <th scope="col">Price</th>
                    <th scope="col">Currency</th>
                    <th scope="col">Added Date</th>
                    <th scope="col">Reject by</th>
                    <th scope="col">Manage</th>
                  </tr>
                </thead>
                <tbody>
                  {shopProducts &&
                    shopProducts.map((ele, index) => (
                      <tr
                        key={index}
                        className={ele?.unReadNewRejected ? "focus" : ""}
                        onClick={() => _gotoProductDetail(ele?._id)}
                      >
                        <td>{index + 1}</td>
                        <td>{ele?.productCategory?.name ?? "-"}</td>
                        <td style={{ width: 200 }}>
                          {ele?.brand?.name ?? "-"}
                        </td>
                        <td>
                          {ele?.model?.newName ?? ele?.model?.name ?? "-"}
                        </td>
                        <td>
                          <span className="limitText">
                            {ele?.description || "-"}
                          </span>
                        </td>
                        <td>{thousand(ele?.price ?? "") ?? "-"}</td>
                        <td>{ele?.currency ?? "-"}</td>
                        <td style={{ width: 120 }}>
                          {formatDate(ele?.createdAt)}
                        </td>
                        <td>{adminShopPortalData?.username || "-"}</td>
                        <td style={{ display: "flex", width: 120 }}>
                          <button
                            className="btn btn-outline-danger"
                            onClick={(e) => {
                              handleShowConfirm();
                              setProductId(ele?._id);
                            }}
                          >
                            {" "}
                            <ImBin size="18px" className="pb-1" />
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  {/* ))} */}
                </tbody>
              </table>
              {totalPage && productData?.length > 0 && (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <ul className="pagination">
                    {Array.from(Array(totalPage), (e, i) => (
                      <a
                        className="page-link"
                        key={i}
                        style={{
                          backgroundColor:
                            parseInt(pageNumber) == i + 1 ? "#9999ff" : "#fff",
                          color:
                            parseInt(pageNumber) == i + 1 ? "#fff" : "#000",
                          cursor: "pointer",
                        }}
                        onClick={() => _nextPage(i + 1)}
                      >
                        {i + 1}
                      </a>
                    ))}
                  </ul>
                </div>
              )}
              <Modal
                show={show}
                onHide={handleCloseConfrimDelete}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    Delete Product List
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}
                  >
                    Do you want to delete this Product?
                  </div>
                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn"
                      style={{ color: "red" }}
                      onClick={handleCloseConfrimDelete}
                    >
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{ backgroundColor: "#816AAE", border: "none" }}
                      onClick={() => deleteProductReject()}
                    >
                      Delete
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={showAllDelete}
                onHide={handleCloseAllDelete}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    Delete All Product List
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}
                  >
                    Do you want to delete all Product?
                  </div>
                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn text-danger"
                      onClick={handleCloseAllDelete}
                    >
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{ backgroundColor: "#816AAE", border: "none" }}
                      onClick={deleteProductALLReject}
                    >
                      Delete All
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
            // </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SpaceOnFilter = styled.div`
  width: 40px;
`;
