import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import Lottie from 'react-lottie';
import axios from 'axios';
import { Modal, Button } from 'react-bootstrap'
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST
} from "../../../consts"
import { PRODUCT_MODEL, CATEGORY_STORE } from '../../../consts/api'
import { MODEL } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

export default function ModelDuplicate() {
	const { history, location, match } = useReactRouter()
	const modelId = match.params.id;
	const [isLoading, setIsLoading] = useState(false)

	const [model, setModel] = useState({})

	const [isEmptyCategory, setIsEmptyCategory] = useState(false)
	const [categoryId, setCategoryId] = useState('')
	const [categoryName, setCategoryName] = useState('')
	const [storeCategories, setStoreCategories] = useState([])

	const [code, setCode] = useState('')
	const [name, setName] = useState('')
	const [newName, setNewName] = useState('')

	const [isPopupShow, setIsPopupShow] = useState(false);
	const [isPopupSuccess, setIsPopupSuccess] = useState(false);

	const [getTokken, setgetTokken] = useState("")
	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductDatas(_localData);
			}
		}
		fetchData();
	}, [])

	const getProductDatas = async (token) => {
		try {
			setIsLoading(true)
			// get product data
			await fetch(`${PRODUCT_MODEL}/${modelId}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				console.log("DETAIL: ", json)
				setCode(json?.code ?? "")
				setName(json?.name ?? "")
				setNewName(json?.newName ?? "")
				if (json?.category) {
					setCategoryId(json?.category?._id)
					setCategoryName(json?.category?.name)
				}
				setModel(json)
			});
			// get category data
			await fetch(CATEGORY_STORE, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => setStoreCategories(json ?? []));
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}
	const handleChangeCategory = async (e) => {
		const _getSelectId = storeCategories.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setCategoryId(_getSelectId[0]._id)
			setCategoryName(_getSelectId[0].name)
		} else {
			setCategoryId("")
			setCategoryName("")
		}
	}
	const onSaveModel = async () => {
		try {
			const _isEmpty = checkValidate();
			if (!_isEmpty) {
				setIsLoading(true)
				let _data = {
					code: code ?? "",
					name: name ?? "",
					newName: newName ?? "",
					category: categoryId,
				}
				const createProduct = await axios(`${PRODUCT_MODEL}/create`, {
					method: "POST",
					headers: getTokken,
					data: _data
				})
				setIsLoading(false)
				if (createProduct?.data?.message == "SUCCESSFULLY") {
					setIsPopupSuccess(true)
					setIsPopupShow(true)
					setTimeout(() => {
						setIsPopupShow(false)
						history.push(`${MODEL}/limit/40/page/1`)
					}, 2000);
				} else {
					setIsPopupSuccess(false)
					setIsPopupShow(true)
				}
			}
		} catch (error) {
			setIsLoading(false)
			setIsPopupSuccess(false)
			setIsPopupShow(true)
			console.log("error:", error)
		}
	}
	const checkValidate = () => {
		let _status = false
		if (!categoryId) {
			if (!categoryId) setIsEmptyCategory(true)
			else setIsEmptyCategory(false)
			_status = true
		}
		return _status
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p><span onClick={() => history.push(`${MODEL}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>Product Description Template Management</span> » Duplicate Product Description</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%" }}>
				<div className="tab-content" id="myTabContent">
					{isLoading ? <AnimationLoading /> : <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<div className="col-md-9">
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>Product Description</p>
								</div>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Product Category <span style={{ color: "red" }}>*</span></p>
								<select
									name="productCategory"
									className="btn-block form-control"
									style={{ border: isEmptyCategory ? '1px solid red' : '' }}
									onChange={(e) => handleChangeCategory(e)}
									value={categoryName}
								>
									<option value="">Please select</option>
									{storeCategories.length > 0 && storeCategories.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Code <span style={{ color: "red" }}>*</span></p>
								<input className="form-control btn-block"
									type="text"
									name="code"
									onChange={(e) => setCode(e?.target?.value)}
									value={code}
								/>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Name <span style={{ color: "red" }}>*</span></p>
								<input className="form-control btn-block"
									type="text"
									name="name"
									onChange={(e) => setName(e?.target?.value)}
									value={name}
								/>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>New name</p>
								<input className="form-control btn-block"
									type="text"
									name="newName"
									onChange={(e) => setNewName(e?.target?.value)}
									value={newName}
								/>
							</div>
						</div>
						<div style={{ height: 70 }}></div>
						<div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "center", height: 70 }}>
							<div className="col-md-3">
								<button type="button" onClick={() => history.goBack()} className="btn col-md-12" style={{ float: "right", border: "1px solid #969696", color: "#969696" }}>Cancel</button>
							</div>
							<div style={{ width: 100 }}></div>
							<div className="col-md-3">
								<button type="button" onClick={() => onSaveModel()} className="btn btn-secondary col-md-12" style={{ float: "right", backgroundColor: "#816AAE" }}>Add Product Description</button>
							</div>
						</div>
					</div>}
				</div>
			</div>

			<Modal centered show={isPopupShow} onHide={() => setIsPopupShow(false)}>
				<Modal.Body>
					{isPopupSuccess ? <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Successfully</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets9.lottiefiles.com/private_files/lf30_imtpbtnl.json",
							}}
							width={300}
							height={300} />
					</div> : <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Create Failed</div>
						<div style={{ fontSize: 18 }}>Oop!, Some thing when wrong, please try again</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets5.lottiefiles.com/packages/lf20_gzlupphk.json",
							}}
							width={300}
							height={300} />
					</div>}
				</Modal.Body>
				{!isPopupSuccess && <Modal.Footer>
					<Button style={{ backgroundColor: "#816AAE" }} onClick={() => setIsPopupShow(false)}>OK</Button>
				</Modal.Footer>}
			</Modal>

		</div>
	)
}
