import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import { downloadExcel } from "../../../helper/exportToExcel";

import queryString from 'query-string';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faFileExcel,
} from "@fortawesome/free-solid-svg-icons"
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST,
	// LIMIT,
	COLOR_PRIMARY
} from "../../../consts"
import { CATEGORY_STORE, GET_MANY_SHOP_PORTAL } from '../../../consts/api'
import {
	ADMIN_PORTAL
} from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'

export default function AdminPortalList() {
	const { history, location, match } = useReactRouter()
	const _limit = match.params.limit;
	const _page = match.params.page;
	// const parsed = queryString.parse(location.search);
	const newDate = new Date();
	const [authToken, setAuthToken] = useState("");
	const [togglePage, setTogglePage] = useState("");
	const [isLoading, setIsLoading] = useState(false)
	const [adminShopPortalData, setAdminShopPortalData] = useState([])
	const [exportExcelData, setExportExcelData] = useState()

	const [adminShopPortalTotal, setAdminShopPortalTotal] = useState(0)

	const [storeCategories, setStoreCategories] = useState([]);
	const [totalPage, setTotalPage] = useState([]);
	const [pageNumber, setPageNumber] = useState(_page ?? 1);

	// ====state_fillter ===
	const [storeCategoryName, setStoreCategoryName] = useState("")
	const [storeCategoryId, setStoreCategoryId] = useState("")
	// const [startDate, setStartDate] = useState(moment(moment().subtract(90, 'days')).format("YYYY-MM-DD"))
	// const [endDate, setEndDate] = useState(moment(moment(newDate)).format("YYYY-MM-DD"))
	const [startDate, setStartDate] = useState()
	const [endDate, setEndDate] = useState()
	// =====

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				fetchAdminShopPortalData(_localData);
				fetchExcelExportData(_localData);
				fetchCategories(_localData);
				setAuthToken(_localData)
			}
		}
		fetchData();
	}, [])

	const fetchCategories = async (tokken) => {
		try {
			await fetch(CATEGORY_STORE, {
				method: "GET",
				headers: tokken
			}).then(response => response.json()).then(json => setStoreCategories(json ?? []));
		} catch (err) {
			console.log(err)
		}
	}

	const fetchAdminShopPortalData = async (token) => {
		try {
			setIsLoading(true)
			await fetch(GET_MANY_SHOP_PORTAL + `/limit/${_limit}/page/${_page}/?${storeCategoryId ? `&&shopCategory=${storeCategoryId}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				// console.log("allAdminPortal: ", json?.result?.length)
				setAdminShopPortalTotal(json?.result?.length ?? 0)
				setAdminShopPortalData(json?.result ?? [])
				_getArrayPageNumber(json?.total)
			});
			setIsLoading(false)
		} catch (err) {
			console.log("err: ", err)
			setIsLoading(false)
		}
	}

	const fetchExcelExportData = async (token) => {
		try {
			await fetch(GET_MANY_SHOP_PORTAL + `/limit/${10000}/page/${_page}/?${storeCategoryId ? `&&shopCategory=${storeCategoryId}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				setExportExcelData(json?.result ?? [])
			});
		} catch (error) {
			console.log("error: ", error)
		}
	}

	const _nextPage = async (page) => {
		// history.push(`${ADMIN_PORTAL}/limit/${_limit}/page/${page}`)
		try {
			setIsLoading(true);
			setPageNumber(page);
			let _url = GET_MANY_SHOP_PORTAL + `/limit/${_limit}/page/${page}/?${storeCategoryId ? `&&shopCategory=${storeCategoryId}&&` : ''}startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}`
			await fetch(_url, {
				method: "GET",
				headers: authToken,
			}).then((response) => response.json()).then((json) => {
				setAdminShopPortalData(json?.result ?? [])
			});
			setIsLoading(false);
		} catch (error) {
			console.log("error: ", error);
			setIsLoading(false);
		}
	}

	const _category = (e) => {
		const _getSelectId = storeCategories.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setStoreCategoryId(_getSelectId[0]._id)
			setStoreCategoryName(_getSelectId[0].name)
			onSearch(_getSelectId[0]._id, startDate, endDate)
			onSearchExcel(_getSelectId[0]._id, startDate, endDate)
		} else {
			setStoreCategoryId("")
			setStoreCategoryName("")
			onSearch("", startDate, endDate)
			onSearchExcel("", startDate, endDate)
		}
	}

	const onSearch = async (_categoryId, _startDate, _endDate) => {
		try {
			setIsLoading(true)
			await fetch(GET_MANY_SHOP_PORTAL + `/limit/${_limit}/page/${_page}/?${_categoryId ? `&&shopCategory=${_categoryId}&&` : ''}startDate=${_startDate}&&endDate=${moment(moment(_endDate).add(1, "days")).format("YYYY-MM-DD")}`, {
				method: "GET",
				headers: authToken
			}).then(response => response.json()).then(json => {
				setAdminShopPortalTotal(json?.result.length ?? 0)
				setAdminShopPortalData(json?.result ?? [])
				_getArrayPageNumber(json?.total)
			});
			setIsLoading(false)
		} catch (err) {
			console.log("err: ", err)
			setIsLoading(false)
		}
	}

	//export data excel search
	const onSearchExcel = async (_categoryId, _startDate, _endDate) => {
		try {
			await fetch(GET_MANY_SHOP_PORTAL + `/limit/${10000}/page/${_page}/?${_categoryId ? `&&shopCategory=${_categoryId}&&` : ''}startDate=${_startDate}&&endDate=${moment(moment(_endDate).add(1, "days")).format("YYYY-MM-DD")}`, {
				method: "GET",
				headers: authToken
			}).then(response => response.json()).then(json => {
				setExportExcelData(json?.result ?? [])
			});
		} catch (err) {
			console.log("err: ", err)
		}
	}

	const _startDate = (e) => {
		setStartDate(e?.target?.value)
		let _startDate = moment(e?.target?.value).format("YYYY-MM-DD")
		let _endDate = moment(endDate).format("YYYY-MM-DD")
		onSearch(storeCategoryId, _startDate, _endDate)
		onSearchExcel(storeCategoryId, _startDate, _endDate)
	}
	const _endDate = (e) => {
		setEndDate(e?.target?.value)
		let _startDate = moment(startDate).format("YYYY-MM-DD")
		let _endDate = moment(e?.target?.value).format("YYYY-MM-DD")
		onSearch(storeCategoryId, _startDate, _endDate)
		onSearchExcel(storeCategoryId, _startDate, _endDate)
	}
	const goToDetail = (id) => {
		history.push(`${ADMIN_PORTAL}/detail/${id}/tap/1/limit/40/page/1`)
	}

	const _getArrayPageNumber = (_modelTotal) => {
		let rowPage = [];
		let _total = 0;
		if (_modelTotal % parseInt(_limit) != 0) {
			_total = (parseInt(_modelTotal) / parseInt(_limit)) + 1;
		} else {
			_total = parseInt(_modelTotal) / parseInt(_limit);
		}
		for (let i = 1; i <= _total; i++) {
			rowPage.push(i);
		}
		setTotalPage(rowPage);
	};



	const exportToExcel = async () => {
		if (exportExcelData) {
			const rows = await exportExcelData?.map((item, index) => ({
				index: index + 1,
				"storecd": item?.shop?.storecd ?? "",
				"agentcd": item?.shop?.agentcd ?? "",
				"name": item?.shop?.name ?? "",
				"shopCategories": item?.shopCategories?.map(item2=> item2?.name).join(", "),
				"email": item?.shop?.email ?? "",
				"mobile": item?.shop?.mobile ?? "",
				"village": item?.shop?.address?.village ?? "",
				"district": item?.shop?.address?.district ?? "",
				"province": item?.shop?.address?.province ?? "",
				"status": item?.status ? "Active" : "Inactive",
				"createdAt": moment(item?.createdAt).format('DD/MM/YYYY') ?? "",
				"lastLogin": moment(item?.userauth?.lastLogin).format('DD/MM/YYYY') ?? ""
			}));

			const titles = ["NO.",
				"Storecd",
				"Agentcd",
				"Shop name",
				"Shop category",
				"Email",
				"Mobile",
				"Village",
				"District",
				"Province",
				"status",
				"Registration date",
				"Last Login date",
			]

			const fileName = `Admin-Protal-${moment().format("DDMMYYYYHHmmss")}.xlsx`
			await downloadExcel(titles, rows, fileName)
		}

	}



	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p>Admin Portal</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: 100 }}>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row">
							<div className="col-md-6">
								<p style={{ fontWeight: "bold" }}>Admin Portal Category</p>
								<select
									name="productCategory"
									className="btn-block form-control"
									value={storeCategoryName}
									onChange={(e) => _category(e)}
								>
									<option value="">Please select</option>
									{storeCategories.length > 0 && storeCategories.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
							</div>
							<div className="col-md-3">
								<p style={{ fontWeight: "bold" }}>Registered from</p>
								<input type="date" className="form-control col-12" value={startDate} onChange={(e) => _startDate(e)} />
							</div>
							<div className="col-md-3">
								<p style={{ fontWeight: "bold" }}>Register to</p>
								<input type="date" className="form-control col-12" value={endDate} onChange={(e) => _endDate(e)} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-sm-12" style={{
				height: "100%",
				backgroundColor: "white",
				marginBottom: 20,
				marginLeft: '30px',
				paddingBottom: '20px',
				width: "95%"
			}}>
				<div style={{ display: togglePage }}>
					<div style={{ display: "flex", justifyContent: "space-between" }}>
						<div>All Admin Portals ({adminShopPortalTotal ?? 0})</div>
						<div style={{ margin: '10px 0' }}>
							<button type="button" onClick={() => history.push(ADMIN_PORTAL + "/add")} className="btn btn-secondary" style={{ backgroundColor: COLOR_PRIMARY }}>Add Account</button>
							{/* <CSVLink
									data={
										adminShopPortalData?.map((item,index) => ({
											"NO.": index + 1,
											"Storecd": item?.shop?.storecd ?? "",
											"Agentcd": item?.shop?.agentcd ?? "",
											"Name": item?.shop?.name ?? "",
											"Email": item?.shop?.email ?? "",
											"Mobile": item?.shop?.mobile ?? "",
											"Village": item?.shop?.address?.village ?? "",
											"District": item?.shop?.address?.district ?? "",
											"Province": item?.shop?.address?.province ?? ""
										})) || []
									}
									filename={"AdminPortals"}
									style={{ textDecoration: "none" }}
								> */}
							<button
								className="btn"
								style={{
									border: `1px solid ${COLOR_PRIMARY}`,
									backgroundColor: "white",
									color: COLOR_PRIMARY
								}}
								onClick={() => exportToExcel()}
							>
								<FontAwesomeIcon icon={faFileExcel} color={COLOR_PRIMARY} />
								<span style={{ marginLeft: 10 }}>Download Excel</span>
							</button>
							{/* </CSVLink> */}
						</div>
					</div>
					<div>
						{isLoading ? <AnimationLoading /> : <table className="table table-hover">
							<thead className="thead-light">
								<tr>
									<th scope="col">#</th>
									<th scope="col">Shop name</th>
									<th scope="col">Shop categories</th>
									<th scope="col">Shop account</th>
									<th scope="col">Status</th>
									<th scope="col">Registration date</th>
								</tr>
							</thead>
							<tbody>
								{adminShopPortalData.length > 0 && adminShopPortalData.map((item, index) => <tr key={index} onClick={() => goToDetail(item?._id)}>
									<td>{index + 1 + parseInt(_limit) * parseInt(pageNumber - 1)}</td>
									<td>{item?.shop?.name ?? "-"}</td>
									<td>
										{item?.shopCategories?.map((item2, index2) => <span key={index2}>
											{item2?.name}{index2 + 1 < item?.shopCategories?.length ? ", " : ""}
										</span>)}
									</td>
									<td>{item?.userauth?.username ?? "-"}</td>
									<td>
										<div style={{ padding: 6, color: item?.status ? "blue" : "red", fontWeight: "bold", border: "1px solid " + (item?.status ? "blue" : "red"), borderRadius: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
											{item?.status ? "Active" : "Inactive"}
										</div>
									</td>
									<td>{formatDate(item?.createdAt)}</td>
								</tr>)}
							</tbody>
						</table>}
						<div className="row center">
							{totalPage?.map((item, index) => {
								return (
									<button style={{
										width: 30,
										height: 30,
										border: "solid 1px #816aae",
										marginLeft: 2,
										backgroundColor: parseInt(pageNumber) == index + 1 ? "#816aae" : "#fff",
										color: parseInt(pageNumber) == index + 1 ? "#fff" : "#000",
									}} onClick={() => _nextPage(item)}
										key={item}
									>{item}</button>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
