import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import IssueReportUpdate from './IssueReportUpdate'
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    ISSUE_REPORT_LIST
} from '../../../consts/router'
import { GET_ISSU_REPORT } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'

export default function IssueReportDetail() {
    const { history, location, match } = useReactRouter()
    // history
    const _issueReportList = () => {
        history.push(`${ISSUE_REPORT_LIST}/limit/${LIMIT}/page/1/`)
    }
    // modal
    const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
    const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
    const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
    // ======
    const [isLoading, setIsLoading] = useState(false)
    const [getIssueReport, setgetIssueReport] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(GET_ISSU_REPORT + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setgetIssueReport(json));
        setIsLoading(false)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _issueReportList()}>Feedback » </a>
            </h6>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ padding: 20 }}>
                    <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>feedback</div>
                    <div className="col-md-12" style={{ textAlign: 'right' }}>
                        <button type="button" className="btn btn-secondary col-md-2" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _handleShowAddConfirmModalShow()}><i className="fas fa-pencil-alt"></i> Update status</button>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-12">
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>Comment</p>
                                </div>
                                <div className="col-9">
                                    <p>{getIssueReport?.detail}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>Latest update</p>
                                </div>
                                <div className="col-9">
                                    <p>{formatDate(getIssueReport?.updatedAt)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>Phone number</p>
                                </div>
                                <div className="col-9">
                                    <p>{getIssueReport?.phoneNumber}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p style={{ color: "#909090" }}>Status</p>
                                </div>
                                <div className="col-9">
                                    <p
                                        style={{
                                            color: getIssueReport?.status === "NEW" ? "green" : getIssueReport?.status === "CLOSE" ? "red" : getIssueReport?.status === "OPENED" ? "#816aae" : getIssueReport?.status === "REJECT" ? "red" : ''
                                        }}
                                    >{getIssueReport?.status}</p>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div className='col-12 center'>
                            <button type="button" className="btn btn-secondary col-md-2" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _issueReportList()}><i className="fas fa-pencil-alt"></i>Back</button>
                        </div>
                    </div>
                </div>
            </div>
            <IssueReportUpdate
                showAddConfirmModal={showAddConfirmModal}
                _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
            />
        </div>
    )
}
