import React from "react"
import "moment/locale/lo"
import Routes from "./Routes"
import "react-toastify/dist/ReactToastify.css"
import "bootstrap/dist/css/bootstrap.min.css"
import { Provider } from 'react-redux'
import store from "./redux/store"
export default function App() {
	return (
		<Provider store={store}>
			<Routes />
		</Provider>
	)
}


