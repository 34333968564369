import React from 'react'
import { Route, Redirect } from 'react-router-dom'
function PrivateRoute({ component: Component, headerTitle, ...rest }) {
	const isAuthenticated = true
	return (
		<Route
			{...rest}
			render={(props) =>
				isAuthenticated ? (
					<div>
						<Component {...props} />
					</div>
				) : (
					<Redirect to="/" />
				)
			}
		/>
	)
}

export default PrivateRoute
