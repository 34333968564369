import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import NotificationAddModal from "./modal/NotificationAddModal"

import {
    TITLE_HEADER,
    HEADER,
	BODY,
    BODY_DISCOUNT_SHOPDETAIL,
} from "../../../consts"
import {
	NOTIFICATION_LIST,
	NOTIFICATION_DETAIL
}from "../../../consts/router"
export default function NotificationEdit() {
    const { history, location, match } = useReactRouter()
    // history
    const _notificationList = () => {
        history.push(`${NOTIFICATION_LIST}`)
    }
    const _notificationDetail = () => {
        history.push(`${NOTIFICATION_DETAIL}`)
    }
    // ====
    const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
    const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
    const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
    const [checkBox, setCheckBox] = useState('')
	const _onNow = (id) => {
		if (id.target.value === 'NOW') {
			setCheckBox('none')
		} else {
			setCheckBox('')
		}
	}
    // ====
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _notificationList()}>ແຈ້ງເຕືອນທັງໝົດ » </a><a href="#" onClick={() => _notificationDetail()}>ສ້າງແຈ້ງເຕືອນໂປຣໂມຊັນ » </a>ແກ້ໄຂ
            </h6>
            <div style={BODY}>
                <div className="row" style={{ padding: 40 }}>
                    <div className="col-md-8" style={{ fontSize: "25px" }}>ສ້າງແຈ້ງເຕືອນ</div>
                </div>
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <p style={{ fontWeight: "bold" }}>ເລືອກໂປຣໂມຊັນ</p>
                        <div className="input-group">
                            <input type="text" className="form-control" aria-label="Text input with segmented dropdown button" />
                            <div className="input-group-append">
                                <button type="button" className="btn btn-outline-secondary" onClick={() => _handleShowAddConfirmModalShow()}>ເລືອກ</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <p style={{ fontWeight: "bold" }}>ຫົວຂໍ້</p>
                        <input type="text" placeholder="ປ້ອນ ຫົວຂໍ້ ຫຼື ID…" className="form-control" />
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                    <div className="col-md-2">
                    </div>
                    <div className="col-md-8">
                        <p style={{ fontWeight: "bold" }}>ລາຍລະອຽດ</p>
                        <input type="text" placeholder="ປ້ອນ ຫົວຂໍ້ ຫຼື ID…" className="form-control" />
                    </div>
                    <div className="col-md-2">
                    </div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8">
                        <div style={{ fontWeight: "bold" }}>ລາຍລະອຽດ</div>
                        <div style={{ height: 10 }}></div>
                        <input type="radio" value="NOW" name="gender" onClick={(id) => _onNow(id)} /> ດຽວນີ້
						<div style={{ height: 10 }}></div>
                        <input type="radio" value="NOT_NOW" name="gender" onClick={(id) => _onNow(id)} /> ໃນອານາຄົດ
					</div>
                    <div className="col-md-2"></div>
                </div>
                <div style={{ height: 10 }}></div>
                <div className="row">
                    <div className="col-md-2"></div>
                    <div className="col-md-8 row">
                        <div className="col-md-4">
                            <p style={{ fontWeight: "bold", display: checkBox }}>ເລືອກວັນທີ</p>
                            <input type="date" className="form-control col-12" style={{ display: checkBox }} />
                        </div>
                        <div className="col-md-4">
                            <p style={{ fontWeight: "bold", display: checkBox }}>ເລືອກເວລາ</p>
                            <input type="date" className="form-control col-12" style={{ display: checkBox }} />
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
                <div style={{ height: 30 }}></div>
                <div className="row center">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} ><i className="fas fa-pencil-alt"></i> ຍົກເລີກ</button>
                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ຢືນຢັນ</button>
                    </div>
                </div>
            </div>
            <NotificationAddModal
                showAddConfirmModal={showAddConfirmModal}
                _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
            />
        </div>
    )
}
