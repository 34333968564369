// External libraries
import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import moment from "moment";

// React Bootstrap components
import Image from "react-bootstrap/Image";
import { Modal, Button } from "react-bootstrap";

// Local constants and helper functions
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
  LIMIT,
} from "../../../consts";
import { PROMOTION_LIST, PROMOTION_EDIT } from "../../../consts/router";
import { PROMOTION_CREATE, URL_PHOTO_AW3 } from "../../../consts/api";
import { getLocalData } from "../../../consts/tokken";
import { formatDate } from "../../../common/super";
import { successDelete, errorAdd } from "../../../helper/sweetalert";

// Local CSS and images
import "../utils/index.css";
import profileImage from "../../../image/profile.png";

export default function PromotionDetail() {
  const { history, location, match } = useReactRouter();

  // State variables
  const [getDataToken, setGetDataToken] = useState();
  const [newData, setNewData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);

  // Handlers for showing and hiding modal
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const getData = async (token) => {
      setIsLoading(true);
      try {
        const response = await fetch(
          PROMOTION_CREATE + "/" + match?.params?.id,
          {
            method: "GET",
            headers: token,
          }
        );
        const json = await response.json();
        setNewData(json);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchData = async () => {
      const _localData = await getLocalData();

      if (_localData) {
        await getData(_localData);
        setGetDataToken(_localData);
      }
    };
    fetchData();
  }, [match.params.id]);

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await fetch(PROMOTION_CREATE + "/" + match?.params?.id, {
        method: "DELETE",
        headers: getDataToken,
      }).then(() => {
        successDelete();
        setTimeout(() => {
          history.push(`${PROMOTION_LIST}/limit/${LIMIT}/page/1/`);
        }, 1800);
      });
    } catch (error) {
      errorAdd("Something went wrong, please try again !");
    } finally {
      setIsLoading(false);
    }
  };

  // Component for displaying status badge
  const StatusBadge = ({ status, color }) => (
    <div
      style={{
        border: `solid 2px ${color}`,
        borderRadius: 50,
        width: 80,
        textAlign: "center",
        color,
      }}
    >
      {status}
    </div>
  );

  // Function for determining promotion status
  const statusPromotion = (status, start, end) => {
    const now = moment().unix();
    const startDate = moment(start).subtract(1, "days").unix();
    const endDate = moment(end).unix();
    const isOpen = now > startDate && now < endDate && status === "OPEN";

    return (
      <StatusBadge
        status={isOpen ? "OPEN" : "CLOSE"}
        color={isOpen ? "#0660D4" : "red"}
      />
    );
  };

  // Navigation functions
  const navigateToPromotionList = () => {
    history.push(`${PROMOTION_LIST}/limit/${LIMIT}/page/1/`);
  };
  const navigateToPromotionEdit = () => {
    history.push(`${PROMOTION_EDIT}${match?.params?.id}`);
  };

  return (
    <div style={TITLE_HEADER}>
      <div style={HEADER}>
        <button className="link-button" onClick={navigateToPromotionList}>
          Promotion »
        </button>{" "}
        <span>{newData?.title}</span>
      </div>
      <div style={{ height: 20 }}></div>

      <div style={BODY_DISCOUNT_SHOPDETAIL}>
        <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>
          Promotion
        </div>
        <div
          className="col-md-12"
          style={{ textAlign: "right", paddingRight: 50 }}
        >
          <button
            type="button"
            className="btn btn-secondary col-md-1"
            style={{
              backgroundColor: "#ffff",
              border: "solid 1px #816AAE",
              color: "#816AAE",
            }}
            onClick={handleShow}
          >
            Delete
          </button>
          <button
            type="button"
            className="btn btn-secondary col-md-1"
            style={{
              backgroundColor: "#ffff",
              border: "solid 1px",
              color: "#606060",
            }}
            onClick={navigateToPromotionEdit}
          >
            <i className="fas fa-pencil-alt"></i> Edit
          </button>
        </div>
        <div style={{ height: "20px" }}></div>
        <div className="row" style={{ paddingLeft: 50, paddingRight: 50 }}>
          <div className="col-md-4">
            {newData?.image ? (
              <center>
                <Image
                  src={URL_PHOTO_AW3 + newData?.image}
                  alt="AeonIcon"
                  width="150"
                  height="150"
                  style={{
                    height: 200,
                    width: 300,
                    objectFit: "cover",
                    borderRadius: 8,
                    border: "solid 2px #707070",
                    padding: 1,
                  }}
                />
              </center>
            ) : (
              <center>
                <Image
                  src={profileImage}
                  alt="AeonIcon"
                  width="150"
                  height="150"
                  style={{
                    height: 200,
                    width: 300,
                    objectFit: "cover",
                    borderRadius: 8,
                    border: "solid 2px #707070",
                    padding: 1,
                  }}
                />
              </center>
            )}
            <div style={{ height: 30 }}></div>
            <h5 style={{ color: "#0660D4", textAlign: "center" }}>
              {" "}
              {newData?.title}
            </h5>
            <div>Create</div>
            <div style={{ padding: 10 }}>{formatDate(newData?.createdAt)}</div>
            <div>Update</div>
            <div style={{ padding: 10 }}>{formatDate(newData?.updatedAt)}</div>
          </div>
          <div className="col-md-8">
            <div
              style={{
                backgroundColor: "#F1F1F1",
                padding: 10,
                fontWeight: "bold",
              }}
            >
              General Information
            </div>
            <div className="row">
              <div className="col-3">
                <p>Subject</p>
              </div>
              <div className="col-9">
                <p>{newData?.title ? newData?.title : " - "}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p>detail</p>
              </div>
              <div className="col-9">
                <p>{newData?.title ? newData?.title : " - "}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p>Description</p>
              </div>
              <div
                className="col-9"
                dangerouslySetInnerHTML={{ __html: newData?.detail }}
              ></div>
            </div>
            <div className="row">
              <div className="col-3">
                <p>Time promotion</p>
              </div>
              <div className="col-9">
                <p>
                  {formatDate(newData?.startDate)} -{" "}
                  {formatDate(newData?.endDate)}
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p>Status</p>
              </div>
              <div className="col-3">
                <div>
                  {statusPromotion(
                    newData?.status,
                    newData?.startDate,
                    newData?.endDate
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-3">
                <p>Remark</p>
              </div>
              <div className="col-3">
                <div>{newData?.note ? newData?.note : " - "}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <div className="row center" style={{ fontWeight: "bold" }}>
            <div style={{ textAlign: "center" }}>Do you want delete </div>
            <div style={{ color: "red", marginLeft: 10 }}>{newData?.title}</div>
            <div style={{ marginLeft: 10 }}> ?</div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
