import React from "react";
import useReactRouter from "use-react-router";
import ReactPaginate from "react-paginate";

const PaginationHelper = () => {
  const { history, match } = useReactRouter();
  const _limit = parseInt(match?.params?.limit);
  const _page = parseInt(match?.params?.page);

  const Pagination_helper = (total, rout, filtter) => {
    const handlePageClick = async (envent) => {
      let currentPage = envent.selected + 1;
      history.push(rout + "/limit/" + _limit + "/page/" + currentPage, filtter);
    };
    
    return (
      <ReactPaginate
        previousLabel="back"
        onPageChange={handlePageClick}
        breakLabel="..."
        pageRangeDisplayed={5}
        pageCount={Math.ceil(total / _limit)}
        // pageRangeDisplayed={7}
        marginPagesDisplayed={3}
        renderOnZeroPageCount={null}
        containerClassName={"pagination justify-content-center"}
        pageClassName={"page-item"}
        pageLinkClassName={"page-link"}
        previousClassName={"page-item"}
        previousLinkClassName={"page-link"}
        nextClassName={"page-item"}
        nextLinkClassName={"page-link"}
        breakClassName={"page-item"}
        breakLinkClassName={"page-link"}
        activeClassName={"active"}
        forcePage={_page - 1}
        nextLabel="next"
      />
    );
  };
  return {
    _limit,
    _page,
    Pagination_helper,
  };
};
export default PaginationHelper;
