import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    ISSUE_REPORT_DETAIL,
    ISSUE_REPORT_LIST
} from '../../../consts/router'
import { GET_ISSU_REPORT } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'
import { formatDate } from '../../../common/super'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faFileCsv } from '@fortawesome/free-solid-svg-icons'
import { __Type } from "graphql";
export default function IssueReportList() {
    const { history, location, match } = useReactRouter()
    const _limit = match.params.limit;
    const _page = match.params.page;
    const parsed = queryString.parse(location.search);
    const newDate = new Date();
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    const [Status, setStatus] = useState(parsed?.status ? parsed?.status : '')
    const [typeFillter, settypeFillter] = useState(parsed?.type ? parsed?.type : '')
    const [fillterName, setfillterName] = useState(parsed?.phoneNumber ? parsed?.phoneNumber : '')
    const _issueReportDetail = (id) => {
        history.push(`${ISSUE_REPORT_DETAIL}${id}`)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [getIssueReport, setgetIssueReport] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [endDate && startDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [Status])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [typeFillter])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [fillterName])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(GET_ISSU_REPORT + `/limit/${LIMIT}/page/${_page}/?${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}${Status ? `&&status=${Status}&&` : ''}${typeFillter ? `&&type=${typeFillter}&&` : ''}${fillterName ? `&&phoneNumber=${fillterName}&&` : ''}`, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setgetIssueReport(json));
        setIsLoading(false)
    }
    // pagination
    useEffect(() => {
        if (getIssueReport?.total > 0) {
            _getArrayPageNumber()
        }
    }, [getIssueReport?.total])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (getIssueReport?.total % parseInt(_limit) != 0) {
            _total = (parseInt(getIssueReport?.total) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(getIssueReport?.total) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _setStatus = (e) => {
        setStatus(e?.target?.value)
    }
    const _Type = (e) => {
        settypeFillter(e?.target?.value)
    }
    const _fillterName = (e) => {
        setfillterName(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${ISSUE_REPORT_LIST}/limit/${LIMIT}/page/${page}/?${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}&&` : ''}${Status ? `&&status=${Status}&&` : ''}${typeFillter ? `&&type=${typeFillter}&&` : ''}${fillterName ? `&&phoneNumber=${fillterName}&&` : ''}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <p>feedback » </p>
            </h6>
            <div style={BODY}>
                <div className="row" style={{ padding: 30 }}>
                    <div className="col-md-5" style={{ fontSize: "20px" }}>Feedback ({getIssueReport?.total})</div>
                    <div className="col-md-7">
                        <div style={{ fontWeight: "bold", paddingBottom: 10 }}>Search</div>
                        <input type="text" placeholder="Search By Phone..." className="form-control" value={fillterName} onChange={(e) => _fillterName(e)} />
                        <div style={{ height: 20 }}></div>
                    </div>
                </div>
                <div className="row col-12">
                    <div className="col-3">
                        <p style={{ fontWeight: "bold" }}>Type of comment</p>
                        <select name="cars" id="cars" className="btn-block form-control" value={typeFillter} onChange={(e) => _Type(e)}>
                            <option value="">ALL</option>
                            <option value="ແອັບຊ້າ">ແອັບຊ້າ</option>
                            <option value="ແອັບມີບັນຫາ">ແອັບມີບັນຫາ</option>
                            <option value="ເຊື່ອມຕໍ່ອິນເຕີເນັດບໍ່ໄດ້">ເຊື່ອມຕໍ່ອິນເຕີເນັດບໍ່ໄດ້</option>
                            <option value="ລ໊ອກອິນບໍ່ໄດ້">ລ໊ອກອິນບໍ່ໄດ້</option>
                            <option value="ລົງທະບຽນບໍ່ໄດ້">ລົງທະບຽນບໍ່ໄດ້</option>
                            <option value="ປ່ຽນລະຫັດຜ່ານບໍ່ໄດ້">ປ່ຽນລະຫັດຜ່ານບໍ່ໄດ້</option>
                            <option value="ຂໍ້ມູນສິນເຊື່ອສະແດງບໍ່ຖືກຕ້ອງ">ຂໍ້ມູນສິນເຊື່ອສະແດງບໍ່ຖືກຕ້ອງ</option>
                            <option value="ຢາກສະເໜີໃຫ້ປ່ຽນສີຂອງແອັບ">ຢາກສະເໜີໃຫ້ປ່ຽນສີຂອງແອັບ</option>
                            <option value="ອື່ນໆ">ອື່ນໆ</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: "bold" }}>Status</p>
                        <select name="cars" id="cars" className="btn-block form-control" value={Status} onChange={(e) => _setStatus(e)}>
                            <option value="">All</option>
                            <option value="NEW">NEW</option>
                            <option value="OPENED">OPENED</option>
                            <option value="REJECT">REJECT</option>
                            <option value="CLOSE">CLOSE</option>
                        </select>
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: "bold" }}>Date from</p>
                        <input type="date" className="form-control" placeholder="First name" value={startDate} onChange={(e) => _startDate(e)} />
                    </div>
                    <div className="col-3">
                        <p style={{ fontWeight: "bold" }}>Date to</p>
                        <input type="date" className="form-control" placeholder="Last name" value={endDate} onChange={(e) => _endDate(e)} />
                    </div>
                </div>
                <div style={{ height: 20 }}></div>
                <div className="row" style={{ padding: 30 }}>
                    <div className="col-md-9" style={{ fontSize: "20px" }}></div>
                    <div className="col-md-3">
                        <ReactHTMLTableToExcel
                            id="test-table-xls-button"
                            className="download-table-xls-button btn btn-app"
                            table="table-to-xls"
                            filename="tablexls"
                            sheet="tablexls"
                            buttonText="Export as CSV"
                            // className="col-md-12 btn btn-block btn-app"
                        />
                    </div>
                </div>
                <div className="row" style={{ paddingLeft: 30 }}>
                    <p style={{ color: "#5C5C5C" }}>{_page}-{getIssueReport?.total}</p>
                </div>
                <div>
                    {isLoading ? <AnimationLoading /> : <div>

                        <div className="col-sm-12">
                            <table className="table table-hover" id="table-to-xls">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Phone number</th>
                                        <th scope="col">Type of comment</th>
                                        <th scope="col">Detail</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getIssueReport?.results?.map((item, index) => {
                                        return (
                                            <tr onClick={() => _issueReportDetail(item?._id)}>
                                                <th scope="row">{index + 1 + parseInt(match?.params?.limit) * parseInt(match?.params?.page - 1)}</th>
                                                <td>{item?.phoneNumber}</td>
                                                <td>{item?.type}</td>
                                                <td>{item?.detail}</td>
                                                <td
                                                    style={{
                                                        color: item?.status === "NEW" ? "green" : item?.status === "CLOSE" ? "red" : item?.status === "OPENED" ? "#816aae" : item?.status === "REJECT" ? "red" : ""
                                                    }}
                                                >
                                                    {item?.status}
                                                </td>
                                                <td>{formatDate(item?.updatedAt)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                            <div className="row center">
                                {totalPage?.map((item, index) => {
                                    return (
                                        <button style={{
                                            width: 35,
                                            height: 35,
                                            border: "solid 1px #816aae",
                                            marginLeft: 2,
                                            backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                                            color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                        }} onClick={() => _nextPage(item)}
                                            key={item}
                                        >{item}</button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}