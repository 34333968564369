import React, { useState, useEffect } from "react"
import { Modal, Button } from "react-bootstrap"
import useReactRouter from "use-react-router"
import Lottie from 'react-lottie';
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST,
} from "../../../consts"
import { CATEGORY_STORE, DEALER_FOR_SHOP_PORTTAL, DEALER_SHOP, EDIT_SHOP_PORTAL, GET_MANY_SHOP_PORTAL } from '../../../consts/api'
import { ADMIN_PORTAL } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'
import AnimationLoading from "../../../helper/loading"
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import SelectSearch from 'react-select-search';
import "./style.css";

export default function AdminPortalEdit() {
	const { history, match } = useReactRouter()
	const adminPortalId = match.params.id;
	const [statusData, setStatusData] = useState(true);
	const [isLoading, setIsLoading] = useState(false)
	const [authToken, setAuthToken] = useState();
	const [dealerShops, setDealerShops] = useState([]);
	const [storeCategories, setStoreCategories] = useState([]);

	const [selectedDealerShop, setSelectedDealerShop] = useState("");
	const [selectedDealerShopName, setSelectedDealerShopName] = useState("");

	const [selectedCategory, setSelectedCategory] = useState([]);
	const [selectedCategoryName, setSelectedCategoryName] = useState([]);

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
	const [note, setNote] = useState("");

	const [isPasswordNotMatch, setIsPasswordNotMatch] = useState(false);

	const [isPopupShow, setIsPopupShow] = useState(false);
	const [isPopupSuccess, setIsPopupSuccess] = useState(false);

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				fetchAdminShopPortalData(_localData);
				getDealerShopData(_localData);
				setAuthToken(_localData);
			}
		}
		fetchData();
	}, [])

	const getDealerShopData = async (tokken) => {
		try {
			setIsLoading(true)
			await fetch(`${DEALER_FOR_SHOP_PORTTAL}/`, {
				method: "GET",
				headers: tokken
			}).then(response => response.json()).then(json => setDealerShops(json ?? []));
			await fetch(CATEGORY_STORE, {
				method: "GET",
				headers: tokken
			}).then(response => response.json()).then(json => setStoreCategories(json ?? []));
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}

	const fetchAdminShopPortalData = async (token) => {
		try {
			setIsLoading(true)
			await fetch(`${GET_MANY_SHOP_PORTAL}/${adminPortalId}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				setStatusData(json?.status ?? false)
				setSelectedDealerShop(json?.shop?._id ?? "")
				setSelectedDealerShopName(json?.shop?.name ?? "")
				if (json?.shopCategories?.length > 0) {
					let _shopCategoryId = []
					let _shopCategoryName = []
					for (var i = 0; i < json?.shopCategories.length; i++) {
						_shopCategoryId.push(json?.shopCategories[i]._id)
						_shopCategoryName.push(json?.shopCategories[i].name)
					}
					setSelectedCategory(_shopCategoryId)
					setSelectedCategoryName(_shopCategoryName)
				}
				setUsername(json?.userauth?.username ?? "")
				setNote(json?.note ?? "")
			});
			setIsLoading(false)
		} catch (err) {
			console.log("err: ", err)
			setIsLoading(false)
		}
	}

	const handleChange = async (_id) => {
		const _getSelectId = dealerShops.filter(item => item._id == _id)
		if (_getSelectId.length > 0) {
			setSelectedDealerShop(_getSelectId[0]._id)
			setSelectedDealerShopName(_getSelectId[0].name)
		} else {
			setSelectedDealerShop("")
			setSelectedDealerShopName("")
		}
	}
	const handleChangeCategory = async (e) => {
		const _getSelectId = storeCategories.filter(item => item.name == e?.target?.value)
		const _checkSelectId = selectedCategoryName.filter(item => item == e?.target?.value)
		if (_getSelectId.length > 0 && _checkSelectId.length === 0) {
			let _selectedCategory = [...selectedCategory, _getSelectId[0]._id]
			setSelectedCategory(_selectedCategory)
			let _selectedCategoryName = [...selectedCategoryName, _getSelectId[0].name]
			setSelectedCategoryName(_selectedCategoryName)
		}
	}

	const onDeleteCategoryData = (index) => {
		var _selectedCategoryName = [...selectedCategoryName]
		var _selectedCategory = [...selectedCategory]
		_selectedCategoryName.splice(index, 1)
		_selectedCategory.splice(index, 1)
		setSelectedCategory(_selectedCategory)
		setSelectedCategoryName(_selectedCategoryName)
	}

	const onSaveAdminPortal = async () => {
		try {
			if ((password || confirmPassword) && password !== confirmPassword) setIsPasswordNotMatch(true)
			else {
				setIsPasswordNotMatch(false)
				setIsLoading(true)
				let _data = {
					shop: selectedDealerShop,
					shopCategories: selectedCategory,
					username: username,
					password: password,
					status: statusData,
					note: note
				}
				if (!password) delete _data.password
				const editShopPortal = await axios(`${EDIT_SHOP_PORTAL}/${adminPortalId}`, {
					method: "POST",
					headers: authToken,
					data: _data
				})
				setIsLoading(false)
				if (editShopPortal?.data?.message === "SUCCESSFULLY") {
					setIsPopupSuccess(true)
					setIsPopupShow(true)
					setTimeout(() => {
						setIsPopupShow(false)
						history.push(`${ADMIN_PORTAL}/detail/${adminPortalId}/tap/1`)
					}, 2000);
				} else {
					setIsPopupSuccess(false)
					setIsPopupShow(true)
				}
			}
		} catch (err) {
			console.log(err)
			setIsLoading(false)
		}
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p><span onClick={() => history.push(`${ADMIN_PORTAL}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>ບັນຊີຮ້ານຄ້າທັງໝົດ</span> » <span onClick={() => history.goBack()} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>ຂໍ້ມູນຮ້ານ</span> » ແກ້ໄຂ</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%" }}>
				{isLoading ? <AnimationLoading /> : <div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<p style={{ fontWeight: "bold", fontSize: 18 }}>Edit account shop portal</p>
						<div className="col-md-12" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
							<div className="col-md-9" style={{ alignItems: "flex-start", justifyContent: 'flex-start' }}>
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>ຂໍ້ມູນບັນຊີຮ້ານຄ້າ</p>
								</div>
								<div style={{ height: 16 }} />
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-2">
											<p style={{ fontWeight: "bold" }}>Select shop <span style={{ color: "red" }}>*</span></p>
										</div>
										<div className="col-md-10">
										<SelectSearch
												options={[]}
												value={selectedDealerShop}
												onChange={(e) => handleChange(e)}
												getOptions={(query) => {
													if (query) {
														return new Promise((resolve, reject) => {
															fetch(`${DEALER_FOR_SHOP_PORTTAL}/?name=${query}`)
																.then(response => response.json())
																.then((results) => {
																	resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
																})
																.catch(reject);
														});
													} else {
														return new Promise((resolve, reject) => {
															fetch(`${DEALER_FOR_SHOP_PORTTAL}/`)
																.then(response => response.json())
																.then((results) => {
																	resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
																})
																.catch(reject);
														});
													}
												}}
												search
												placeholder="Select shop..."
											/>
											{/* <select
												name="productCategory"
												className="btn-block form-control"
												onChange={(e) => handleChange(e)}
												value={selectedDealerShopName}
											>
												<option value="">Please select shop</option>
												{dealerShops.length > 0 && dealerShops.map((item, index) =>
													<option key={index} value={item?.name}>{item?.name}</option>
												)}
											</select> */}
										</div>
									</div>
								</div>
								<div style={{ height: 16 }} />
								<div className="col-md-12">
									<div className="row">
										<div className="col-md-2">
											<p style={{ fontWeight: "bold" }}>Shop category <span style={{ color: "red" }}>*</span></p>
										</div>
										<div className="col-md-10">
											<select
												name="productCategory"
												className="btn-block form-control"
												onChange={(e) => handleChangeCategory(e)}
												value=""
											>
												<option value="">Please select</option>
												{storeCategories.length > 0 && storeCategories.map((item, index) =>
													<option key={index} value={item?.name}>{item?.name}</option>
												)}
											</select>
											<div style={{ display: 'flex', flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
												{selectedCategoryName?.length > 0 && selectedCategoryName?.map((category, index) => <div key={index} style={{ backgroundColor: "#eee", borderRadius: 5, width: "auto", height: 28, paddingLeft: 8, paddingRight: 8, marginRight: 16, marginTop: 10 }}>
													<FontAwesomeIcon onClick={() => onDeleteCategoryData(index)} icon={faTimes} style={{ color: "#1b1b1b", cursor: "pointer", float: 'right', marginTop: -6, marginRight: -10 }} />
													<span>{category ?? "-"}</span>
												</div>)}
											</div>
										</div>
									</div>
								</div>
								<div style={{ height: 16 }} />
								<span style={{ fontWeight: "bold" }}>ຊື່ບັນຊີຮ້ານຄ້າ <span style={{ color: "red" }}>*</span></span>
								<input
									type="text"
									className="col-md-12 form-control"
									onChange={(e) => setUsername(e?.target?.value)}
									value={username}
									placeholder="ກະລຸນາປ້ອນຊື່ບັນຊີຮ້ານຄ້າ"
								/>
								<div style={{ height: 16 }} />
								<span style={{ fontWeight: "bold" }}>ລະຫັດຜ່ານ</span>
								<input
									type="password"
									className="col-md-12 form-control"
									onChange={(e) => setPassword(e?.target?.value)}
									value={password}
									placeholder="ກະລຸນາປ້ອນ"
								/>
								<div style={{ height: 16 }} />
								<span style={{ fontWeight: "bold" }}>ຢືນຢັນລະຫັດຜ່ານ</span>
								<input
									type="password"
									className="col-md-12 form-control"
									onChange={(e) => setConfirmPassword(e?.target?.value)}
									value={confirmPassword}
									placeholder="ກະລຸນາປ້ອນ"
								/>
								{isPasswordNotMatch && <p style={{ color: "red" }}>ລະຫັດຜ່ານບໍ່ຕົງກັນ</p>}
								<div style={{ height: 16 }} />
								<div className="row" style={{ display: "flex", alignItems: "center", paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold", marginTop: 24 }}>ສະຖານະ</p>
									<div style={{ width: 32 }} />
									<label className="switch">
										<input
											type="checkbox"
											name="status"
											defaultChecked={statusData}
											onChange={() => setStatusData(!statusData)}
											className="form-control col-9" />
										<span className="slider round"></span>
									</label>
								</div>
								<div style={{ height: 16 }} />
								<span style={{ fontWeight: "bold" }}>ໝາຍເຫດ</span>
								<input
									type="text"
									className="col-md-12 form-control"
									onChange={(e) => setNote(e?.target?.value)}
									value={note}
								/>
							</div>
						</div>
					</div>
					<div style={{ height: 70 }}></div>
					<div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "center", height: 70 }}>
						<div className="col-md-2">
							<button type="button" onClick={() => history.goBack()} className="btn col-md-12" style={{ float: "right", border: "1px solid #969696", color: "#969696" }}>Cancel</button>
						</div>
						<div style={{ width: 100 }}></div>
						<div className="col-md-2">
							<button type="button" onClick={() => onSaveAdminPortal()} className="btn btn-secondary col-md-12" style={{ float: "right", backgroundColor: "#816AAE" }}>Edit Account</button>
						</div>
					</div>
				</div>}
			</div>

			<Modal centered show={isPopupShow} onHide={() => setIsPopupShow(false)}>
				<Modal.Body>
					{isPopupSuccess ? <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Successfully</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets9.lottiefiles.com/private_files/lf30_imtpbtnl.json",
							}}
							width={300}
							height={300} />
					</div> : <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Edit Failed</div>
						<div style={{ fontSize: 18 }}>Oop!, Some thing when wrong, please try again</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets5.lottiefiles.com/packages/lf20_gzlupphk.json",
							}}
							width={300}
							height={300} />
					</div>}
				</Modal.Body>
				{!isPopupSuccess && <Modal.Footer>
					<Button style={{ backgroundColor: "#816AAE" }} onClick={() => setIsPopupShow(false)}>OK</Button>
				</Modal.Footer>}
			</Modal>
		</div>
	)
}
