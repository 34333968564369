import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import queryString from 'query-string';
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY,
} from "../../../consts"
import {
    LOCATION_LAST_LOGIN,
    LOCATION_LIST
} from '../../../consts/router'
import { HISTORY_LOGIN } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDateTime } from '../../../common/super'
import AnimationLoading from "../../../helper/loading.js"
import PaginationHelper from "../../../helper/PaginationHelper";

export default function LocationList() {
    const { history, location, match } = useReactRouter()
    const [dataLocation, setDataLocation] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const { _limit, _page, Pagination_helper } = PaginationHelper();

    const parsed = queryString.parse(location.search);
    const newDate = new Date();
    // ==== FILLTER
    const [fillterNumber, setfillterNumber] = useState(parsed?.phoneNumber)
    const [statusFillter, setstatusFillter] = useState(parsed?.status)
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
        _getArrayPageNumber()
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [fillterNumber])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [statusFillter])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [endDate && startDate])

    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(HISTORY_LOGIN + "/limit/" + _limit + "/page/" + _page + `/?${fillterNumber ? `&&phoneNumber=${fillterNumber}&&` : ''}${statusFillter ? `&&status=${statusFillter}&&` : ``}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''} `, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setDataLocation(json));
        setIsLoading(false)
    }
    const _latLogin = () => {
        history.push(`${LOCATION_LAST_LOGIN}/limit/${_limit}/page/${_page} `)
    }
    const _history = () => {
        history.push(`${LOCATION_LIST}/limit/40/page/1/`)
    }
    useEffect(() => {
        if (dataLocation?.total > 0) {
            _getArrayPageNumber()
        }
    }, [dataLocation])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (dataLocation?.total % parseInt(_limit) != 0) {
            _total = (parseInt(dataLocation?.total) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(dataLocation?.total) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };

    const _status = (data) => {
        let res = <div></div>
        switch (data) {
            case "SUCCESS":
                res = <div style={{ border: "solid 1px #0660D4", borderRadius: 50, width: 80, textAlign: "center", color: "#0660D4", fontWeight: "bold", fontSize: 15 }}>Success</div>
                break;
            case "FAILED":
                res = <div style={{ border: "solid 1px #f01818", borderRadius: 50, width: 80, textAlign: "center", color: "#f01818", fontWeight: "bold", fontSize: 15 }}>Failed</div>
                break;
        
            default:
                break;
        }
        return res;
    }
    const _statusDetail = (detail) => {
        let res = <div></div>
        switch (detail) {
            case "SIGNIN_SUCCESS":
                res = <div style={{ fontSize: 15 }}>Login Success</div>
                break;
            case "SIGNUP_WITH_PHONE_NUMBER_DO_NOT_HAVE_IN_AEON_DATABASE":
                res = <div style={{ fontSize: 15 }}>Signup with phone number don't have in AEON database</div>
                break;
            case "SIGNUP_WITH_PHONE_NUMBER_IS_ALREADY_EXIST":
                res = <div style={{ fontSize: 15 }}>Signup with phone number is already exist</div>
                break;
        
            default:
                break;
        }
        return res;
    }
    const _fillterNumber = (e) => {
        if (e.target.value) {
            setfillterNumber(e?.target?.value)
        } else {
            setfillterNumber("")
        }
    }
    const _setstatusFillter = (e) => {
        setstatusFillter(e?.target?.value)
    }
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${LOCATION_LAST_LOGIN}/limit/${_limit}/page/${page}/?${fillterNumber ? `&&phoneNumber=${fillterNumber}&&` : ''}${statusFillter ? `&&status=${statusFillter}&&` : ``}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <p>All Login records » </p>
            </h6>
            <ul className="nav nav-tabs" id="myTab" style={{ width: "95%", marginLeft: "30px", border: "solid 0px #FFFF" }}>
                <li className="nav-item">
                    <a className="nav-link" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="false" onClick={() => _history()} style={{ cursor: "pointer", color: "blue" }}>Location History</a>
                </li>
                <li className="nav-item" style={{ border: "solid 1px #FFFF" }}>
                    <a className="nav-link active" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="true" onClick={() => _latLogin()} style={{ cursor: "pointer", border: "solid 0px #FFFF" }}>Login records</a>
                </li>
            </ul>
            <div style={BODY}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="col-md-12 row">
                            <div className="col-md-3">
                                <p style={{ fontWeight: "bold" }}>Phone number</p>
                                <input type="text" className="form-control" placeholder=" 856 . . . . . . . . . " style={{ backgroundColor: "#E4E4E4" }} value={fillterNumber} onChange={(e) => _fillterNumber(e)}></input>
                            </div>
                            <div className="col-md-3">
                                <p style={{ fontWeight: "bold" }}>Type</p>
                                <select name="cars" id="cars" className="btn-block form-control"
                                    value={statusFillter}
                                    onChange={(e) => _setstatusFillter(e)}>
                                    <option value="">All</option>
                                    <option value="SUCCESS">Success</option>
                                    <option value="FAILED">Failed</option>
                                </select>
                            </div>
                            <div className="col-md-3">
                                <p style={{ fontWeight: "bold" }}>Date from</p>
                                <input type="date" className="form-control" value={startDate} onChange={(e) => _startDate(e)} />
                            </div>
                            <div className="col-md-3">
                                <p style={{ fontWeight: "bold" }}>Date to</p>
                                <input type="date" className="form-control" value={endDate} onChange={(e) => _endDate(e)} />
                            </div>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div className="col-md-12">
                            <p style={{ fontSize: 20 }}>All login records( {dataLocation?.total} )</p>
                        </div>
                        {/* <div className="col-md-12">
                            <p style={{ fontSize: 20 }}>{_page}-{dataLocation?.userLogin?.length + parseInt(_limit) * parseInt(_page - 1)}</p>
                        </div> */}
                        {isLoading ? <AnimationLoading /> : <div>
                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Date - Time</th>
                                            <th scope="col">Type</th>
                                            <th scope="col">Detail</th>
                                            <th scope="col">Phone number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataLocation?.userLogin?.map((data, index) => {
                                            return (
                                                <tr>
                                                    <th scope="row">{index + 1 + parseInt(_limit) * parseInt(_page - 1)}</th>
                                                    <td>{formatDateTime(data?.createdAt)}</td>
                                                    <td>{_status(data?.status)}</td>
                                                    <td>{_statusDetail(data?.detail)}</td>
                                                    <td>{data?.phoneNumber}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="row center">
                                {Pagination_helper(dataLocation?.total, "/locationList/locationLastlogin", `${fillterNumber ? `&&phoneNumber=${fillterNumber}&&` : ''}${statusFillter ? `&&status=${statusFillter}&&` : ``}`)}
                                    {/* {totalPage?.map((item, index) => {
                                        return (
                                            <button style={{
                                                width: 30,
                                                height: 30,
                                                border: "solid 1px #816aae",
                                                marginLeft: 2,
                                                backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                                                color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                            }} onClick={() => _nextPage(item)}
                                                key={item}
                                            >{item}</button>
                                        )
                                    })} */}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div >
            </div >
        </div >
    )
}