import { createSlice } from '@reduxjs/toolkit';

const productSlice = createSlice({
    name: 'product',
    initialState: {
        totalRequesting: 0
    },
    reducers: {
        setTotalRequesting: (state, action) => {
            console.log("action.payload: ", action.payload)
            state.totalRequesting = action.payload;
        }
    }
})

export const getTotalRequesting = (state) => state.totalRequesting;

export const {
    setTotalRequesting
} = productSlice.actions;
export default productSlice.reducer;