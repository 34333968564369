import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router"
import axios from 'axios';
import { Formik } from 'formik';
import {
    Modal,
    Form
} from 'react-bootstrap'
import {
    ISSUE_REPORT_DETAIL
} from '../../../consts/router'
import { successUpdate, errorAdd } from '../../../helper/sweetalert'
import { GET_ISSU_REPORT } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

export default function NotificationAddModal({
    showAddConfirmModal,
    _handleShowAddConfirmModalClose,
}) {
    const { history, location, match } = useReactRouter()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    const _updateStatus = (values) => {
        axios({
            method: 'PUT',
            url: GET_ISSU_REPORT + match?.params?.id,
            headers: getTokken,
            data: {
                status: values?.status,
            }
        }).then(function (response) {
            successUpdate()
            setTimeout(() => {
                history.push(`${ISSUE_REPORT_DETAIL}${match?.params?.id}`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')

        });
    }
    return (
        <>
            <Formik
                initialValues={{
                    status: '',
                }}
                validate={values => {
                    const errors = {};
                    if (!values.status) {
                        errors.status = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    _updateStatus(values)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Modal
                            show={showAddConfirmModal}
                            onHide={_handleShowAddConfirmModalClose}
                            size='lg'>
                            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
                            </Modal.Title>
                            <Modal.Body>
                                <div className="col-md-12 center">
                                    <p style={{ fontSize: 20, fontWeight: "bold" }}>Update status</p>
                                    <div className="col-sm-12 center" style={{ marginTop: 20 }}>
                                        <div className="row center" style={{ marginRight: 5 }}>
                                            <Form.Check type="radio" aria-label="radio 1" name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value="OPENED"
                                            />Open
                                        </div>
                                        <div className="row center" style={{ marginRight: 0.01 }}>
                                            <Form.Check type="radio" aria-label="radio 1" name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value="REJECT"
                                            />Reject
                                        </div>
                                        <div className="row center" style={{ marginRight: 5 }}>
                                            <Form.Check type="radio" aria-label="radio 1" name="status"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value="CLOSE"
                                            />Close
                                        </div>
                                        <div style={{ color: "red" }}>
                                            {errors.status && touched.status && errors.status}
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <button type="button" className="btn btn-secondary col-md-2 center" style={{ backgroundColor: "#FFFF", color: "#606060", border: "solid 1px #606060" }} onClick={() => _handleShowAddConfirmModalClose()}>Cancel</button>
                                        <button className="btn col-md-2 center btn-app" onClick={handleSubmit}>Save</button>
                                    </div>
                                </div>
                            </Modal.Body>
                        </Modal>
                    </form>
                )
                }
            </Formik >
        </>
    )
}
