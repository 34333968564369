import React, { useState, useEffect } from "react"
import "../utils/index.css"
import Button from "react-bootstrap/Button"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import InputGroup from "react-bootstrap/InputGroup"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Image from "react-bootstrap/Image"
import { faUser, faLock } from "@fortawesome/free-solid-svg-icons"
import useReactRouter from "use-react-router"
import Form from "react-bootstrap/Form"
import { Formik } from "formik"
import ImageLogo from "../../../image/AEON_Logo.jpg"
import { SIGIN_ADMIN } from '../../../consts/api'
import { ADMIN_KEY } from '../../../consts'
import axios from 'axios';
import {
	DASHBOARD,
	PROMOTION_LIST
} from '../../../consts/router'
export default function Login() {
	const { history, location, match } = useReactRouter()
	const [checkUser, setCheckUser] = useState(false);
	const _loginAdmin = (body) => {
		axios.post(SIGIN_ADMIN, {
			email: body.email,
			password: body.password
		}).then(async function (response) {
			await localStorage.setItem(ADMIN_KEY, JSON.stringify(response))
			history.push(`${DASHBOARD}`)
		}).catch(function (error) {
			setCheckUser(true);
		});
	}
	return (
		<div className="contain">
			<Formik
				initialValues={{
					email: '',
					password: ''
				}}
				onSubmit={(values) => {
					if (!values.email) {
						setCheckUser(true);
					} else if (!values.password) {
						setCheckUser(true);
					} else {
						let body = {
							email: values.email,
							password: values.password,
						}
						_loginAdmin(body)
					}
				}}>
				{({
					values,
					errors,
					touched,
					handleChange,
					handleBlur,
					handleSubmit,
					isSubmitting,
					/* and other goodies */
				}) => (
					<form onSubmit={handleSubmit}>
						<div style={{ marginBottom: 30 }}></div>
						<div className="form_contain">
							<Image src={ImageLogo} alt="AeonIcon" width="150" height="150" style={{
								resizeMode: "center",
								height: 120,
								width: 120,
								marginTop: -80,
							}} />
							<h3 className="form_title">Welcome Please login</h3>
							<div className="form">
								<Form noValidate>
									<Form.Group controlId="validationFormikUsername">
										<InputGroup>
											<InputGroup.Prepend>
												<InputGroup.Text id="inputGroupUsername"
													style={{ borderColor: checkUser ? "red" : "" }}
												>
													<FontAwesomeIcon icon={faUser} size={24} />
												</InputGroup.Text>
											</InputGroup.Prepend>
											<Form.Control
												type="text"
												placeholder="Email"
												aria-describedby="inputGroupUsername"
												name="email"
												value={values.email}
												onChange={handleChange}
												// isInvalid={!!errors.username}
												className="form_control"
												style={{ borderColor: checkUser ? "red" : "" }}
											/>
											<Form.Control.Feedback type="invalid">
												{/* {errors.username} */}
											</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>
									<Form.Group controlId="validationFormikPassword">
										<InputGroup>
											<InputGroup.Prepend>
												<InputGroup.Text id="inputGroupPassword"
													style={{ borderColor: checkUser ? "red" : "" }}
												>
													<FontAwesomeIcon icon={faLock} size={24} />
												</InputGroup.Text>
											</InputGroup.Prepend>
											<Form.Control
												type="password"
												placeholder="Password"
												aria-describedby="inputGroupPassword"
												name="password"
												value={values.password}
												onChange={handleChange}
												className="form_control"
												style={{ borderColor: checkUser ? "red" : "" }}
											/>
											<Form.Control.Feedback type="invalid">
											</Form.Control.Feedback>
										</InputGroup>
									</Form.Group>
								</Form>
							</div>
							<div style={{ color: "red", backgroundColor: "#FFF", padding: 5, display: checkUser ? "" : "none" }}>ຂໍອະໄພ ໄອດີ ຫຼື ລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ</div>
							<div>
								<Row style={{ paddingTop: 30, marginBottom: 100, width: 600, marginLeft: 150 }}>
									<div style={{ width: 100 }}></div>
									<Col sm={5} className="center">
										<Button
											onClick={() => handleSubmit()}
											style={{
												zPosition: 100,
												width: 200,
												color: "#ffff",
												backgroundColor: "#816AAE",
											}}
											variant="#37201"
											type="submit"
										>
											Login
											</Button>
									</Col>
								</Row>
							</div>
						</div>
					</form>
				)}
			</Formik>
		</div>
	)
}
