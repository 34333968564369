import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import { BsCheckLg } from 'react-icons/bs';
import { MdClose } from 'react-icons/md';
import Lottie from 'react-lottie';
import useReactRouter from "use-react-router";
import { BODY_DEALERLIST, HEADER, TITLE_HEADER, URL_IMG_S3 } from '../../../consts'
import { GET_PRODUCT_BY_ID, UPDATE_DEALER_REJECT_ONE, UPDATE_DEALER_STATUS_MANY } from '../../../consts/api';
import { ADMIN_PORTAL, ALL_PRODUCT_REQUESTING } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken';
import { thousand } from '../../../helper';
import AnimationLoading from '../../../helper/loading';

function ProductWaitingDetail() {
  const { history, match } = useReactRouter();
  const containerWidth = '931px'
  const productId = match?.params?.id
  const page = match?.params?.page;

  const [productData, setProductData] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false)

  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [statusProduct, setStatusProduct] = useState();
  const [isPopupShow, setIsPopupShow] = useState(false);
  const [button, setButton] = useState("");
  const [getTokken, setgetTokken] = useState();
  const [isPopupSuccess, setIsPopupSuccess] = useState(false);
  const [getReason, setGetReason] = useState("");
  const [otherReason, setOtherReason] = useState(true);
  const [showFormShowReason, setFormShowReason] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getProductDataById(_localData)
        setgetTokken(_localData);
      }
    };
    fetchData();
  }, [productId])


  const getProductDataById = async (token) => {
    try {
      setIsLoading(true);
      await fetch(`${GET_PRODUCT_BY_ID}/${productId}`,
        {
          method: "GET",
          headers: token,
        }
      ).then((response) => response.json()).then((json) => {
        setProductData(json)
        setIsLoading(false)
      })
    } catch (error) {
      console.log("getProductDataById Error: ", error)
    }
  }

  const _onSelectReason = (e) => {
    if (e?.target?.value === "OtherReason") {
      setOtherReason(false)
    }
    setGetReason(e?.target?.value)
  }

  const _handleCloseFormReason = () => {
    setFormShowReason(false)
    setGetReason("")
  }

  const updateProductStatus = async () => {
    setShow(false)
    try {
      if (button == "Approve") {
        let _data = {
          "id": productId,
          "status": statusProduct,
          "type": "ONE"
        }
        const _approvedSelect = await axios(
          UPDATE_DEALER_STATUS_MANY,
          {
            method: 'post',
            headers: getTokken,
            data: _data
          })

        if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
          setIsPopupShow(true)
          setIsPopupSuccess(true)
          setTimeout(() => {
            setIsPopupShow(false)
            history.push(`${ALL_PRODUCT_REQUESTING}/limit/40/page/1`)
          }, 3000);
        } else {
          setIsPopupSuccess(false)
          setIsPopupShow(true)
        }
      } else {
        setFormShowReason(true)
      }
    } catch (error) {
      console.log("error: ", error)
    }
  }

  const rejectedProduct = async () => {
    try {
      setFormShowReason(false)
      let _data = {
        "note": getReason
      }
      const _approvedSelect = await axios(
        UPDATE_DEALER_REJECT_ONE + productId,
        {
          method: 'post',
          headers: getTokken,
          data: _data
        })
      if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
        setIsPopupShow(true)
        setIsPopupSuccess(true)
        _handleCloseFormReason()
        setTimeout(() => {
          setIsPopupShow(false)
          history.push(`${ALL_PRODUCT_REQUESTING}/limit/40/page/1`)
        }, 3000);
      }
    } catch (error) {
      console.log("rejectedProject: ",error)
    }
  }


  return (
    <div style={TITLE_HEADER}>
      <h6 style={HEADER}>
        {page == 1 ? <p>
          <span onClick={() => history.push(`${ADMIN_PORTAL}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>Admin Portal
          </span>
          <span onClick={() => history.goBack()} style={{ color: "blue", cursor: "pointer" }}>» Detail</span>
          <span> » Waiting</span>
        </p> : <p>
          <span onClick={() => history.push(`${ALL_PRODUCT_REQUESTING}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>All Product  Pending Approval
          </span>
          <span onClick={() => history.goBack()}> » Detail</span>
        </p>}
      </h6>
      {isLoading ? <AnimationLoading /> : <div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%", paddingTop: 16, paddingBottom: 40 }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={{ fontSize: '24px', fontWeight: '400' }}>Product pending approve detail</h3>
        </div>

        <Container style={{ width: containerWidth, marginTop: '40px' }}>
          <h3 style={{ fontSize: '18px', fontWeight: "bold", backgroundColor: '#F1F1F1', padding: '10px' }}>Product Information</h3>
          <Row style={{ padding: '0 20px' }}>
            <Col xs={3}>
              <p>Category:</p>
            </Col>
            <Col xs={1}>-</Col>
            <Col xs={4}>{productData?.productCategory?.name ?? ''}</Col>
          </Row>
          <Row style={{ padding: '0 20px' }}>
            <Col xs={3}>
              <p>Brand:</p>
            </Col>
            <Col xs={1}>-</Col>
            <Col xs={4}>{productData?.brand?.name ?? ''}</Col>
          </Row>
          <Row style={{ padding: '0 20px' }}>
            <Col xs={3}>
              <p>Model:</p>
            </Col>
            <Col xs={1}>-</Col>
            <Col xs={4}>{productData?.model?.name}</Col>
          </Row>
        </Container>

        <Container style={{ width: containerWidth }}>
          <h3 style={{ fontSize: '18px', fontWeight: "bold", backgroundColor: '#F1F1F1', padding: '10px' }}>ຄຳອະທິບາຍ</h3>
          <Row>
            <Col xs={4}>
              <img style={{ width: '264px', height: '264px', objectFit: 'contain' }}
                src={URL_IMG_S3 + productData?.image ?? ''}
              />
            </Col>
            <Col xs={8}>
              <Row style={{ padding: '0 20px' }}>
                <Col xs={3}>
                  <p>Description:</p>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={8}>{productData?.description ?? ''}</Col>
              </Row>
              <Row style={{ padding: '0 20px' }}>
                <Col xs={3}>
                  <p>Price:</p>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={5}>{thousand(productData?.price ?? '0')}</Col>
              </Row>
              <Row style={{ padding: '0 20px' }}>
                <Col xs={3}>
                  <p>Currency:</p>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={5}>{productData?.currency ?? ''}</Col>
              </Row>
              {/* <div style={{ margin: '20px 0' }}> */}
              <Row style={{ padding: '0 20px' }}>
                <Col xs={3}>
                  <p>Added Date:</p>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={5}>{moment(productData?.createdAt).format('DD-MM-YYYY')}</Col>
              </Row>
              <Row style={{ padding: '0 20px' }}>
                <Col xs={3}>
                  <p>Status:</p>
                </Col>
                <Col xs={1}>-</Col>
                <Col xs={5} style={{ color: "#F89C22" }}>{productData?.status ?? ''}</Col>
              </Row>
              {page == 1 ? <div /> : <Row style={{ padding: '0 20px', marginTop: 20 }}>
                <Col xs={3}>
                  <div style={{ width: 120 }}>
                    <button className="product-waiting-reject-button"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTitle("Approved This Product")
                        setText("Do you want to approve this product?")
                        setButton("Approve")
                        setStatusProduct("APPROVED")
                        setShow(true);

                      }} > <BsCheckLg size="18px" /> Approve</button>
                  </div>
                </Col>
                <Col xs={1}></Col>
                <Col xs={5}>
                  <div style={{ width: 100 }}>
                    <button className="product-waiting-approve-button"
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setTitle("Reject This Product")
                        setText("Do you want to reject this product?")
                        setButton("Reject")
                        setStatusProduct("REJECTED")
                        setShow(true)
                      }}> <MdClose size="18px" fontWeight="blod" />Reject</button>
                  </div>
                </Col>
              </Row>}
            </Col>
          </Row>

        </Container>
      </div>}

      <Modal
        show={show}
        onHide={() => setShow(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title style={{ fontSize: 24, color: "#5C5C5C", fontWeight: "bold" }}>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}>
            {text}
          </div>
          <div style={{ height: 30 }} />
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}>
            <div className='btn text-danger' onClick={() => setShow(false)}>
              Cancel
            </div>
            <p style={{ padding: 10 }}></p>
            <Button style={{ backgroundColor: "#816AAE", border: "none" }}
              onClick={() => updateProductStatus()}
            >{button}</Button>
          </div>

        </Modal.Body>
      </Modal>

      <Modal centered show={isPopupShow} onHide={() => setIsPopupShow(false)}>
        <Modal.Body>
          {isPopupSuccess ? <div className="admin-portal-all-flex">
            <div style={{ fontWeight: "bold", fontSize: 24 }}>Successfully</div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                path: "https://assets9.lottiefiles.com/private_files/lf30_imtpbtnl.json",
              }}
              width={300}
              height={300} />
          </div> : <div className="admin-portal-all-flex">
            <div style={{ fontWeight: "bold", fontSize: 24 }}>Faile</div>
            <div style={{ fontSize: 18 }}>Oop!, Some thing when wrong, please try again</div>
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                path: "https://assets5.lottiefiles.com/packages/lf20_gzlupphk.json",
              }}
              width={300}
              height={300} />
          </div>}
        </Modal.Body>
        {!isPopupSuccess && <Modal.Footer>
          <Button style={{ backgroundColor: "#816AAE" }} onClick={() => setIsPopupShow(false)}>OK</Button>
        </Modal.Footer>}
      </Modal>

      <Modal
        show={showFormShowReason}
        onHide={_handleCloseFormReason}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header style={{ borderBottom: "none" }}>
          <Modal.Title style={{ fontSize: 24, color: "#5C5C5C", fontWeight: "bold" }}>Reject Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>

          <Form.Control as="select" onChange={(e) => _onSelectReason(e)} hidden={otherReason ? false : true}>
            <option value=""> Choose type of reject reason....</option>
            <option value="ຮູບພາບບໍ່ແຈ້ງ, ຮູບບໍ່ກົງກັບສິນຄ້າ, ບໍ່ມີຮູບພາບ">ຮູບພາບບໍ່ແຈ້ງ, ຮູບບໍ່ກົງກັບສິນຄ້າ, ບໍ່ມີຮູບພາບ</option>
            <option value="ຂໍ້ມູນໝວດສິນຄ້າບໍ່ຖືກຕ້ອງ">ຂໍ້ມູນໝວດສິນຄ້າບໍ່ຖືກຕ້ອງ</option>
            <option value="ຂໍ້ມູນຍີ່ຫໍ້ສິນຄ້າບໍ່ຖືກຕ້ອງ">ຂໍ້ມູນຍີ່ຫໍ້ສິນຄ້າບໍ່ຖືກຕ້ອງ</option>
            <option value="ຂໍ້ມູນລຸ້ນສິນຄ້າບໍ່ຖືກຕ້ອງ">ຂໍ້ມູນລຸ້ນສິນຄ້າບໍ່ຖືກຕ້ອງ</option>
            <option value="ຂໍ້ມູນລາຍລະອຽດສິນຄ້າບໍ່ຖືກຕ້ອງ">ຂໍ້ມູນລາຍລະອຽດສິນຄ້າບໍ່ຖືກຕ້ອງ</option>
            <option value="OtherReason">ອື່ນ:........................</option>
          </Form.Control>

          <Form.Control type="text" onChange={(e) => setGetReason(e?.target?.value)} hidden={otherReason} placeholder="ກະລຸນາປ້ອນເຫດຜົນ...." />

          <div style={{ height: 30 }} />
          <div style={{ display: 'flex', justifyContent: 'end', alignItems: "center" }}>
            <div className='btn text-danger' onClick={_handleCloseFormReason}>
              Cancel
            </div>
            <p style={{ padding: 10 }}></p>
            <Button style={{ backgroundColor: "#816AAE", border: "none", width: 120 }}
              disabled={getReason === "" ? true : false}
              onClick={() => rejectedProduct()}>Yes</Button>
          </div>

        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ProductWaitingDetail
