import React from 'react'
import useReactRouter from "use-react-router"
import {
    Modal,
} from 'react-bootstrap'
import Consts from '../../../../consts'
import {
    NOTIFICATION_LIST,
    PROMOTION_DETAIL
}from '../../../../consts/router'
export default function NotificationAddModal({
    showAddConfirmModal,
    _handleShowAddConfirmModalClose,
}) {
    const { history, location, match } = useReactRouter()
    const _notificationList = () => {
        history.push(`${NOTIFICATION_LIST}`)
    }
    const _promotionDetail = () => {
        history.push(`${PROMOTION_DETAIL}`)
    }
    return (
        <Modal
            show={showAddConfirmModal}
            onHide={_handleShowAddConfirmModalClose}
            size='lg'>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
            </Modal.Title>
            <Modal.Body
                style={{
                    padding: 10,
                    paddingTop: 10
                }}>
                <div className="col-md-12 center">
                    <p style={{ fontSize: 20, fontWeight: "bold" }}>ເລືອກໂປໂມຊັ້ນ</p>
                    <div className="col-sm-12" style={{ marginTop: 20 }}>
                        <table className="table table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">ຮູບພາບ</th>
                                    <th scope="col">ID</th>
                                    <th scope="col">ຫົວຂໍ້</th>
                                    <th scope="col">ໄລຍະເວລາໂຊ ວ/ດ/ປ</th>
                                    <th scope="col">ສະຖານະ</th>
                                    <th scope="col">ເວລາສ້າງ</th>
                                    <th scope="col">ຕົວເລືອກ</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr onClick={() => _promotionDetail()}>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                    <td>
                                        <div style={{ border: "solid 1px #0660D4", backgroundColor: "#ffff", color: "#0660D4", borderRadius: 8 }}>ເຜີຍແຜ່</div>
                                    </td>
                                    <td>@mdo</td>
                                    <td>
                                        <button type="button" className="btn btn-block btn-secondary" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ເລືອກ</button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                    <td>
                                        <div style={{ border: "solid 1px #0660D4", backgroundColor: "#ffff", color: "#0660D4", borderRadius: 8 }}>ເຜີຍແຜ່</div>
                                    </td>
                                    <td>@mdo</td>
                                    <td>
                                        <button type="button" className="btn btn-block btn-secondary " style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ເລືອກ</button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                    <td>
                                        <div style={{ border: "solid 1px #0660D4", backgroundColor: "#ffff", color: "#0660D4", borderRadius: 8 }}>ເຜີຍແຜ່</div>
                                    </td>
                                    <td>@mdo</td>
                                    <td>
                                        <button type="button" className="btn btn-block btn-secondary " style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ເລືອກ</button>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row">1</th>
                                    <td>Mark</td>
                                    <td>Otto</td>
                                    <td>@mdo</td>
                                    <td>@mdo</td>
                                    <td>
                                        <div style={{ border: "solid 1px #0660D4", backgroundColor: "#ffff", color: "#0660D4", borderRadius: 8 }}>ເຜີຍແຜ່</div>
                                    </td>
                                    <td>@mdo</td>
                                    <td>
                                        <button type="button" className="btn btn-block btn-secondary " style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ເລືອກ</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button type="button" className="btn btn-secondary col-md-2 center" style={{ backgroundColor: "#FFFF", color: "#606060", border: "solid 1px #606060" }} onClick={() => _notificationList()}>ອອກ</button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

    )
}
