import React, { useEffect, useState } from "react"
import SideNav, {
	Toggle,
	NavItem,
	NavIcon,
	NavText,
} from "@trendmicro/react-sidenav"
import "@trendmicro/react-sidenav/dist/react-sidenav.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faChartLine,
	faFolderOpen,
	faHouseUser,
	faMapMarkedAlt,
	faUsers,
	faWarehouse,
	faShoppingBasket
} from "@fortawesome/free-solid-svg-icons"
import "./sidenav.css"
import {
	DASHBOARD,
	PROMOTION_LIST,
	CUSTOMMER_LIST,
	STORE,
	SETTING_LIST,
	NOTIFICATION_LIST,
	ISSUE_REPORT_LIST,
	STAFF_LIST,
	ADMIN_PORTAL,
	ALL_PRODUCT_REQUESTING
} from '../consts/router'
import { getTotalRequesting, setTotalRequesting } from "../redux/productSlice";
import { useSelector, useDispatch } from 'react-redux'
import { ENPOINTE_API } from "../consts/api"
import axios from "axios";

const selectedTabBackgroundColor = "#606060"
const UN_SELECTED_TAB_TEXT = "#816AAE"



export default function Sidenav({ location, history }) {
	const {totalRequesting} = useSelector(getTotalRequesting);
	const [selectStatus, setSelectStatus] = useState(
		location.pathname.split("/")[1].split("-")[0]
	);
	const [expandedStatus, setExpandedStatus] = useState(false);
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchProductRequestingAndStore = async () => {
			try {
				const data = await axios.get(`${ENPOINTE_API}/dealer-product/v2/unread-product-requesting`)
				const total = data?.data?.unReadNewRequestingTotal;
				dispatch(setTotalRequesting(total));
			} catch (error) {
				console.error("Error fetching chat rooms:", error);
			}
		};
		fetchProductRequestingAndStore()
	}, [])


	return (
		<SideNav
			style={{
				// backgroundColor: "green",
				height: "100vh",
				display: "block",
				position: "fixed",
				// position: "fixed",
				backgroundColor: "#FFFF",
				zIndex: 10001,
			}}
			onSelect={(selected) => {
				setSelectStatus(selected.split("/")[0].split("-")[0]);
				console.log("selected:::", selected)
				if (selected == PROMOTION_LIST) {
					selected = selected
				} else if (selected == CUSTOMMER_LIST) {
					selected = selected
				} else if (selected == STORE) {
					selected = selected
				} else if (selected == NOTIFICATION_LIST) {
					selected = selected
				} else if (selected == ISSUE_REPORT_LIST) {
					selected = selected
				} else if (selected == SETTING_LIST) {
					selected = selected
				} else if (selected == DASHBOARD) {
					selected = selected
				} else if (selected == STAFF_LIST) {
					selected = selected
				} else if (selected == ADMIN_PORTAL) {
					selected = selected
				}
				else if (selected == ALL_PRODUCT_REQUESTING) {
					selected = selected
				}
				const to = "/" + selected
				if (location.pathname !== to) {
					history.push(to)
				}
			}}
			onToggle={(expanded) => {
				setExpandedStatus(expanded);
			}}
		>
			<Toggle />
			<SideNav.Nav defaultSelected={location.pathname.split("/")[1]}>
				<NavItem eventKey="dashboard">
					<NavIcon>
						<FontAwesomeIcon icon={faChartLine} style={{
							fontSize: "18px",
							color: selectStatus === "dashboard" ? "red" : selectedTabBackgroundColor
						}} />
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "dashboard" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Dashboard
					</NavText>
				</NavItem>
				<NavItem eventKey="promotionList/limit/40/page/1">
					<NavIcon>
						<i
							className="fa fa-bullhorn fa-2x"
							style={{
								fontSize: "18px",
								color:
									selectStatus === "promotionList"
										? "red"
										: selectedTabBackgroundColor,
							}}
						></i>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "promotionList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Promotion and Notification
					</NavText>
				</NavItem >
				<NavItem eventKey="customerList/limit/40/page/1/?">
					<NavIcon>
						<FontAwesomeIcon
							icon={faUsers}
							style={{
								fontSize: "18px",
								color:
									selectStatus === "customerList" ? "red" : selectedTabBackgroundColor,
							}}
						/>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "customerList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Customer
					</NavText>
				</NavItem>
				<NavItem eventKey="store/limit/40/page/1/">
					<NavIcon>
						<i
							className="fa fa-home"
							style={{
								fontSize: "22px",
								color:
									selectStatus === "store" ? "red" : selectedTabBackgroundColor,
							}}
						></i>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "store" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Shop
					</NavText>
				</NavItem>
				<NavItem eventKey="admin-portal/limit/40/page/1/">
					<NavIcon>
						<FontAwesomeIcon icon={faHouseUser} style={{
							fontSize: "18px",
							color: selectStatus === "admin" ? "red" : selectedTabBackgroundColor,
						}} />
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "admin" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Admin portal
					</NavText>
				</NavItem>
				<NavItem eventKey="model/limit/40/page/1/">
					<NavIcon>
						<FontAwesomeIcon icon={faFolderOpen} style={{
							fontSize: "18px",
							color: selectStatus === "model" ? "red" : selectedTabBackgroundColor,
						}} />
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "model" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Product Description
					</NavText>
				</NavItem>


				<NavItem eventKey="product/limit/40/page/1/">
					<NavIcon>
						<FontAwesomeIcon icon={faWarehouse} style={{
							fontSize: "18px",
							color: selectStatus === "product" ? "red" : selectedTabBackgroundColor,
						}} />
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "product" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Product
					</NavText>
				</NavItem>

				<NavItem eventKey="requesting/limit/40/page/1/">
					<NavIcon style={{ position: 'relative' }}>
						<FontAwesomeIcon icon={faShoppingBasket} style={{
							fontSize: "18px",
							color: selectStatus === "requesting" ? "red" : selectedTabBackgroundColor,
						}} />
						<span style={{
							position: 'absolute',
							top: 0,
							right: 0,
							fontSize: '12px', // Adjust the font size as needed
							color: 'white', // Choose a color that stands out
							backgroundColor: 'red', // Background for the number
							borderRadius: '50%', // Makes it circular
							width: '20px', // Adjust size as needed
							height: '20px', // Adjust size as needed
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}>
							{totalRequesting}
						</span>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "requesting" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						All Product  Pending Approval
					</NavText>
				</NavItem>

				<NavItem eventKey="issueReportList/limit/40/page/1/">
					<NavIcon>
						<i
							className="fa fa-bookmark"
							style={{
								fontSize: "18px",
								color:
									selectStatus === "issueReportList"
										? "red"
										: selectedTabBackgroundColor,
							}}
						></i>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "issueReportList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Issue report
					</NavText>
				</NavItem>
				<NavItem eventKey="staffList/limit/40/page/1/">
					<NavIcon>
						<i
							className="fa fa-user"
							style={{
								fontSize: "18px",
								color:
									selectStatus === "staffList" ? "red" : selectedTabBackgroundColor,
							}}
						></i>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "staffList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Staff
					</NavText>
				</NavItem>
				<NavItem eventKey="locationList/limit/40/page/1/?">
					<NavIcon>
						<FontAwesomeIcon icon={faMapMarkedAlt} style={{
							fontSize: "18px",
							color: selectStatus === "locationList" ? "red" : selectedTabBackgroundColor
						}} />
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "locationList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Location
					</NavText>
				</NavItem>
				<NavItem eventKey="settingList">
					<NavIcon>
						<i
							className="fa fa-cogs"
							style={{
								fontSize: "18px",
								color:
									selectStatus === "settingList"
										? "red"
										: selectedTabBackgroundColor,
							}}
						></i>
					</NavIcon>
					<NavText
						style={{
							color: selectStatus === "settingList" ? "red" : UN_SELECTED_TAB_TEXT,
						}}
					>
						Setting
					</NavText>
				</NavItem>
			</SideNav.Nav >
		</SideNav >
	)
}
