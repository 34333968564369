import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import ImageLogo from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import profileImage from "../../../image/profile.png"
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    SETTING_LIST,
    CACULARTOR_LIST,
    CACULARTOR_EDIT
} from '../../../consts/router'
import AnimationLoading from "../../../helper/loading.js"
import { formatDateTime } from '../../../common/super'
import { SETTING_STORE_CATEGORY, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { successDelete, errorAdd } from '../../../helper/sweetalert'
export default function SettingCategoryDetail() {
    const { history, location, match } = useReactRouter()
    const _caculartor = () => {
        history.push(`${SETTING_LIST}`)
    }
    const _configCaculartor = () => {
        history.push(`${CACULARTOR_LIST}/limit/${LIMIT}/page/1`)
    }
    const _settingCategoryEdit = () => {
        history.push(`${CACULARTOR_EDIT}${match?.params?.id}`, match?.params?.id)
    }
    const [isLoading, setIsLoading] = useState(false)
    const [detail, setDetail] = useState()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(SETTING_STORE_CATEGORY + "/" + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setDetail(json));
        setIsLoading(false)
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const _confirmDelete = () => {
        fetch(SETTING_STORE_CATEGORY + "/" + match?.params?.id, {
            method: "DELETE",
            headers: getTokken
        }).then(function (response) {
            successDelete()
            setTimeout(() => {
                history.push(`${CACULARTOR_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading />
                : <div>
                    <div style={HEADER}>
                        <a href="#" onClick={() => _caculartor()}>Setting »</a><a href="" onClick={() => _configCaculartor()}>Set up a Shopfront »</a> {detail?.name}
                    </div>
                        <div style={{ height: 20 }}></div>
                    <div style={BODY_DISCOUNT_SHOPDETAIL}>
                            <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Shop information</div>
                            <div className="col-md-12" style={{ textAlign: 'right', paddingRight: 50 }}>
                                <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px #816AAE", color: "#816AAE" }} onClick={handleShow}><FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 10 }} />Delete</button>
                                <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _settingCategoryEdit()}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10 }} /> Edit</button>
                            </div>
                            <div style={{ height: "20px" }}></div>
                            <div className="row" style={{ paddingLeft: 50, paddingRight: 50 }}>
                                <div className="col-md-4">
                                    <center>
                                        {detail?.imageBackground ?
                                            <Image src={URL_PHOTO_AW3 + detail?.imageBackground} alt="AeonIcon" width="150" height="150" style={{
                                                height: 200,
                                                width: 300,
                                                borderRadius: 8,
                                            }} /> : <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                height: 200,
                                                width: 300,
                                                borderRadius: 8,
                                            }} />}
                                    </center>
                                    <div style={{ height: 30 }}></div>
                                    <h5 style={{ color: "#0660D4", textAlign: "center" }}>Cover photo</h5>
                                    <center>
                                        {detail?.image ?
                                            <Image src={URL_PHOTO_AW3 + detail?.image} alt="AeonIcon" width="150" height="150" style={{
                                                height: 200,
                                                width: 300,
                                                borderRadius: 8,
                                            }} /> : <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                height: 200,
                                                width: 300,
                                                borderRadius: 8,
                                            }} />}
                                    </center>
                                    <div style={{ height: 30 }}></div>
                                    <h5 style={{ color: "#0660D4", textAlign: "center" }}>Photo</h5>
                                    <div>Create</div>
                                    <div style={{ padding: 10 }}>{formatDateTime(detail?.createdAt)}</div>
                                    <div>Update</div>
                                    <div style={{ padding: 10 }}>{formatDateTime(detail?.updatedAt)}</div>
                                </div>
                                <div className="col-md-8">
                                    <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                    <div style={{ height: 10 }}></div>
                                    <div className="row">
                                        <div className="col-3">
                                            <p style={{ color: "#909090" }}>Survey Name: </p>
                                        </div>
                                        <div className="col-9">
                                            <p>{detail?.name}</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <p style={{ color: "#909090" }}>Member percentage: </p>
                                        </div>
                                        <div className="col-9">
                                            <p>{detail?.member_interest ? detail?.member_interest : "0"} %</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-5">
                                            <p style={{ color: "#909090" }}>None member percentage: </p>
                                        </div>
                                        <div className="col-7">
                                            <p>{detail?.non_member_interest ? detail?.non_member_interest : "0"} %</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p style={{ color: "#909090" }}>Terms and conditions of the loan application :</p>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-2">
                                            <p style={{ color: "#909090" }}></p>
                                        </div>
                                        <div className="col-10"
                                            dangerouslySetInnerHTML={{ __html: detail?.detail }}
                                        >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </div>
            }
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Body >
                    <div className="row center" style={{ fontWeight: "bold" }}>
                        <div style={{ textAlign: "center" }}>Do you want Delete </div>
                        <div style={{ color: "red", marginLeft: 10 }}>{detail?.name}</div>
                        <div style={{ marginLeft: 10 }}> ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => _confirmDelete()}>
                        Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
