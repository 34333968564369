import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ADMIN_PORTAL } from "../../../consts/router";
import AnimationLoading from "../../../helper/loading";
import { getLocalData } from "../../../consts/tokken";
import { GET_MANY_SHOP_PORTAL } from "../../../consts/api";

export default function Detail({ id }) {
  const { history, location, match } = useReactRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [adminShopPortalData, setAdminShopPortalData] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        fetchAdminShopPortalData(_localData);
      }
    };
    fetchData();
  }, []);

  const fetchAdminShopPortalData = async (token) => {
    try {
      setIsLoading(true);
      await fetch(`${GET_MANY_SHOP_PORTAL}/${id}`, {
        method: "GET",
        headers: token,
      })
        .then((response) => response.json())
        .then((json) => {
          // console.log("json: ", json)
          setAdminShopPortalData(json);
        });
      setIsLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div
        className="tab-pane fade show active"
        id="home"
        role="tabpanel"
        aria-labelledby="home-tab"
      >
        <div
          className="row"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 16,
          }}
        >
          <p style={{ fontWeight: "bold", fontSize: 18 }}>​ລາຍ​ລະ​ອຽດ</p>
          <button
            onClick={() => history.push(`${ADMIN_PORTAL}/edit/${id}`)}
            type="button"
            className="btn btn-outline-secondary"
            style={{ float: "right", width: 100 }}
          >
            <FontAwesomeIcon icon={faPen} color="#bebebe" /> Edit
          </button>
        </div>
        {isLoading ? (
          <AnimationLoading />
        ) : (
          <div
            className="row"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div className="col-md-9" style={{ paddingLeft: 24 }}>
              <div
                style={{
                  height: 40,
                  backgroundColor: "#eee",
                  width: "100%",
                  paddingTop: 8,
                  paddingLeft: 16,
                }}
              >
                <p style={{ fontWeight: "bold" }}>ຂໍ້ມູນທົ່ວໄປ</p>
              </div>
              <div style={{ height: 16 }} />
              <div className="row">
                <div className="col-md-3">
                  <p>ຊື່ຮ້ານຄ້າ</p>
                </div>
                <div>
                  <p>{adminShopPortalData?.shop?.name ?? "-"}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ປະ​ເພດ​ຮ້ານ</p>
                </div>
                <div>
                  <p
                    style={{
                      backgroundColor: "#eee",
                      paddingLeft: 16,
                      paddingRight: 16,
                      borderRadius: 8,
                    }}
                  >
                    {adminShopPortalData?.shopCategories?.map((item, index) => (
                      <span key={index}>
                        {item?.name}
                        {index + 1 < adminShopPortalData?.shopCategories?.length
                          ? ", "
                          : ""}
                      </span>
                    ))}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ເບີ​ໂທ​ລະ​ສັບ</p>
                </div>
                <div>
                  <p>{adminShopPortalData?.shop?.telno ?? "-"}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ຊື່ບັນຊີຮ້ານຄ້າ</p>
                </div>
                <div>
                  <p>{adminShopPortalData?.userauth?.username ?? "-"}</p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ສະຖານະ</p>
                </div>
                <div>
                  <p
                    style={{
                      border:
                        "1px solid " +
                        (adminShopPortalData?.status ? "blue" : "red"),
                      borderRadius: 32,
                      paddingLeft: 16,
                      paddingRight: 16,
                      color: adminShopPortalData?.status ? "blue" : "red",
                    }}
                  >
                    {adminShopPortalData?.status ? "Active" : "Inactive"}
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p>ໝາຍເຫດ</p>
                </div>
                <div>
                  <p>{adminShopPortalData?.note ?? "-"}</p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
