import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import { Modal, Button } from 'react-bootstrap'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    DISCOUNT_SHOP_EDIT,
    STORE_DISCOUNT_LIST,
} from '../../../consts/router'
import { successDelete, errorAdd } from '../../../helper/sweetalert'
import { STOREDEALER, DELETE_DEALER_SHOP, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import profileImage from "../../../image/profile.png"
import MapContainer from "../../../consts/locationMap"
import AnimationLoading from "../../../helper/loading.js"

export default function DiscountShopDetail() {
    const { history, location, match } = useReactRouter()
    // history
    const _discountList = () => {
        history.push(`${STORE_DISCOUNT_LIST}/limit/${LIMIT}/page/1`)
    }
    const _discountShopEdit = () => {
        history.push(`${DISCOUNT_SHOP_EDIT}${match?.params?.id}`, match?.params?.id)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    // get data 
    const [getDiscountShop, setGetDiscountShop] = useState('');
    const [isLoading, setIsLoading] = useState(false)
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(STOREDEALER + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setGetDiscountShop(json));
        setIsLoading(false)
    }
    const _confirmDelete = async () => {
        await fetch(DELETE_DEALER_SHOP + match?.params?.id, {
            method: "DELETE",
            headers: getTokken
        }).then(function (response) {
            successDelete()
            setTimeout(() => {
                history.push(`${STORE_DISCOUNT_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    // =========== google_map =========>>>>.
    const [latData, setLatData] = useState()
    const [lngData, setLngData] = useState()
    useEffect(() => {
        if (getDiscountShop) {
            setLatData(getDiscountShop?.lat)
            setLngData(getDiscountShop?.lng)
        }
    }, [getDiscountShop])
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _discountList()}>All Shops » </a> {getDiscountShop?.name}
            </h6>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ padding: 20 }}>
                    <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Shop information (Discount Shop)</div>
                    <div className="col-md-12" style={{ textAlign: 'right' }}>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px #816AAE", color: "#816AAE" }} onClick={() => handleShow()}> <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 10 }} />Delete</button>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _discountShopEdit()}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10 }} />Edit</button>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-4">
                            {getDiscountShop?.image ? (
                                <center>
                                    <Image src={URL_PHOTO_AW3 + getDiscountShop?.image} alt="AeonIcon" width="150" height="150" style={{
                                        height: 200,
                                        width: 300,
                                        objectFit: "cover",
                                        borderRadius: 8,
                                        border: "solid 2px #707070",
                                        padding: 1

                                    }} />
                                </center>
                            ) : (
                                <center>
                                    <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                        height: 200,
                                        width: 300,
                                        objectFit: "cover",
                                        borderRadius: 8,
                                        border: "solid 2px #707070",
                                        padding: 1

                                    }} />
                                </center>
                            )}
                            <div style={{ height: 30 }}></div>
                            <h5 style={{ color: "#0660D4", textAlign: "center" }}>{getDiscountShop?.name}</h5>
                        </div>
                        <div className="col-md-8">
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Shop name</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.name ? getDiscountShop?.name : "-"}</p>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-3">
                                    <p>Category</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.storecategory?.name ? getDiscountShop?.storecategory?.name : "-"}</p>
                                </div>
                            </div> */}
                            <div className="row">
                                <div className="col-3">
                                    <p>Description</p>
                                </div>
                                <div className="col-9" dangerouslySetInnerHTML={{ __html: getDiscountShop?.detail }}>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Discounts</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.discount ? getDiscountShop?.discount : "-"}%</p>
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Phone number</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.phoneNumber ? getDiscountShop?.phoneNumber : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>facebook link</p>
                                </div>
                                <div className="col-md-9 wrap-text">
                                    <p style={{ cursor: "pointer" }}>{getDiscountShop?.linkFacebook ? getDiscountShop?.linkFacebook : "-"}</p>
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Location</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Location</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.address ? getDiscountShop?.address : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Province</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.province ? getDiscountShop?.province : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>District</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.district ? getDiscountShop?.district : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>village</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.village ? getDiscountShop?.village : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Latitude</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.lat ? getDiscountShop?.lat : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Longitude</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.lng ? getDiscountShop?.lng : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-3">
                                    <p>Create_By</p>
                                </div>
                                <div className="col-9">
                                    <p>{formatDate(getDiscountShop.createdAt)}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Updated_By</p>
                                </div>
                                <div className="col-9">
                                    <p>{formatDate(getDiscountShop.updatedAt)}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Other</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Remark</p>
                                </div>
                                <div className="col-9">
                                    <p>{getDiscountShop?.note ? getDiscountShop?.note : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: 30 }}>
                    <MapContainer
                        lat={latData}
                        lng={lngData}
                        width="100%"
                        height="100vh"
                    />
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Body >
                    <div className="row center" style={{ fontWeight: "bold" }}>
                        <div style={{ textAlign: "center" }}>Do you want Delete </div>
                        <div style={{ color: "red", marginLeft: 10 }}>{getDiscountShop?.name}</div>
                        <div style={{ marginLeft: 10 }}> ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => _confirmDelete()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
