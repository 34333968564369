// External libraries
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Formik } from "formik";
import useReactRouter from "use-react-router";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Image from "react-bootstrap/Image";
import moment from "moment";

// Internal modules
import { successUpdate, errorAdd } from "../../../helper/sweetalert";
import {
  TITLE_HEADER,
  HEADER,
  BODY_DISCOUNT_SHOPDETAIL,
  LIMIT,
} from "../../../consts";
import { NOTIFY_DETAIL, NOTIFY_LIST } from "../../../consts/router";
import {
  NOTIFY_CREATE,
  NOTIFY_UPDATE,
  PRESIGNED_URL,
  URL_PHOTO_AW3,
} from "../../../consts/api";
import { getLocalData } from "../../../consts/tokken";
import { formatDateDash, formatDateDashH } from "../../../common/super";

// Styles
import "../utils/index.css";

const notifyList = [];

export default function PromotionEdit() {
  const { history, match } = useReactRouter();

  const [getToken, setGetToken] = useState();
  const [ckEditer, setCkEditer] = useState();
  const [namePhoto, setNamePhoto] = useState("");
  const [file, setFile] = useState();
  const [imageLoading, setImageLoading] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [newData, setNewData] = useState(false);
  const [statusData, setStatusData] = useState("");
  const [setStatus, _setStatus] = useState();
  const [blob, setBlob] = useState();

  useEffect(() => {
    const getData = async (token) => {
      setIsLoading(true);
      try {
        const response = await fetch(NOTIFY_UPDATE + "/" + match?.params?.id, {
          method: "GET",
          headers: token,
        });
        const json = await response.json();
        console.log("json: ", json)
        setNewData(json);
        setCkEditer(json?.detail);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        await getData(_localData);
        setGetToken(_localData);
      }
    };
    fetchData();
  }, [match.params.id]);

  useEffect(() => {
    if (newData?.status) {
      setStatusData(newData?.status === "OPEN" ? true : false);
    }
  }, [newData]);

  const handleUpload = async (event) => {
    const file = event.target.files[0];
    setImageLoading("");
    setFile(file);
    setBlob(URL.createObjectURL(file));

    try {
      const responseUrl = await axios.post(PRESIGNED_URL, {
        headers: getToken,
        data: {
          type: file.type,
        },
      });

      setNamePhoto(responseUrl.data);

      await axios.put(responseUrl.data.url, file, {
        headers: {
          "Content-Type": " file/*; image/*",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
          "Access-Control-Allow-Headers":
            "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setImageLoading(percentCompleted);
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreate = async (values) => {
    let newStatusData = "";
    if (statusData === true) {
      newStatusData = "OPEN";
    } else {
      newStatusData = "CLOSED";
    }
    console.log("ckEditer: ", ckEditer)
    await axios({
      method: "PUT",
      url: NOTIFY_UPDATE + "/" + match?.params?.id,
      headers: getToken,
      data: {
        title: values?.title,
        detail: ckEditer ? ckEditer : newData?.detail,
        startDate: values?.startDate,
        endDate: values?.endDate,
        status: newStatusData,
        image: namePhoto?.filename ? namePhoto?.filename : newData?.image,
        note: values?.note,
      },
    })
      .then(function (response) {
        successUpdate();
        setTimeout(() => {
          history.push(`${NOTIFY_DETAIL}${match?.params?.id}`);
        }, 1800);
      })
      .catch(function (error) {
        errorAdd("Something went wrong, please try again !");
      });
  };

  const ImageThumb = ({ image }) => {
    return (
      <img
        src={blob}
        alt={image.name}
        style={{
          width: "100%",
          height: "auto",
          objectFit: "cover",
        }}
      />
    );
  };

  // Navigation functions
  const navigateToNotifyList = () => {
    history.push(`${NOTIFY_LIST}/limit/${LIMIT}/page/1/`);
  };

  const navigateToNotifyDetail = () => {
    history.push(`${NOTIFY_DETAIL}${match?.params?.id}`);
  };

  return (
    <div style={TITLE_HEADER}>
      <div style={HEADER}>
        <button
          className="link-button link-button-active"
          onClick={navigateToNotifyList}
        >
          Notify »
        </button>{" "}
        <button className="link-button" onClick={navigateToNotifyDetail}>
          {newData?.title} »
        </button>{" "}
        <span>Edit</span>
      </div>
      <div style={{ height: 20 }}></div>

      {newData && (
        <Formik
          initialValues={{
            title: newData?.title,
            status: newData?.status,
            detail: ckEditer,
            startDate: formatDateDashH(newData?.startDate),
            endDate: formatDateDashH(newData?.endDate),
            note: newData?.note,
          }}
          validate={(values) => {
            const errors = {};
            console.log("values: ", values)
            if (
              !values.title ||
              values.title.trim() === "" ||
              values.title.startsWith(" ")
            ) {
              errors.title = "Required";
            }
            console.log("ckEditer: ", ckEditer)
            if (!ckEditer) {
              errors.detail = "Required";
            }

            // if (!values.startDate) {
            //   errors.startDate = "Required";
            // }

            // if (!values.endDate) {
            //   errors.endDate = "Required";
            // }

            // if (
            //   values.startDate &&
            //   values.endDate &&
            //   values.startDate > values.endDate
            // ) {
            //   errors.startDate = "Start date must be before end date";
            //   errors.endDate = "End date must be after start date";
            // }

            return errors;
          }}
          onSubmit={(values, { setSubmitting }) => {
            handleCreate(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ height: 100 }}></div>
                <div className="row">
                  <div className="col-md-4 center">
                    <input
                      type="file"
                      id="file-upload"
                      onChange={handleUpload}
                      hidden
                    />
                    <label htmlFor="file-upload">
                      <div
                        style={{
                          backgroundColor: "#E4E4E4E4",
                          height: 200,
                          width: 300,
                          borderRadius: "5%",
                          cursor: "pointer",
                          overflow: "hidden",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        {blob ? (
                          <ImageThumb image={file} />
                        ) : (
                          <Image
                            src={URL_PHOTO_AW3 + newData?.image}
                            alt="AeonIcon"
                            style={{
                              height: 200,
                              width: 300,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "5%",
                              cursor: "pointer",
                              objectFit: "cover",
                            }}
                          />
                        )}
                      </div>
                    </label>
                    {/* progass */}
                    {imageLoading ? (
                      <div className="progress" style={{ height: 20 }}>
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${imageLoading}%`,
                            backgroundColor: "#816aae",
                          }}
                          aria-valuenow={imageLoading}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {imageLoading}%
                        </div>
                      </div>
                    ) : (
                      <div style={{ height: 20 }} />
                    )}
                    <h2 style={{ color: "#0660D4" }}>{newData.name}</h2>
                  </div>
                  <div style={{ height: 50 }}></div>
                  <div className="col-md-8">
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div style={{ fontWeight: "bold" }}>Subject </div>
                      <div style={{ color: "red", fontWeight: "bold" }}> *</div>
                    </div>
                    <input
                      type="text"
                      name="title"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.title}
                      placeholder="ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ..."
                      className="form-control col-9"
                    />
                    <div style={{ color: "red" }}>
                      {errors.title && touched.title && errors.title}
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <div style={{ fontWeight: "bold" }}>
                        Promotion details{" "}
                      </div>
                      <div style={{ color: "red", fontWeight: "bold" }}> *</div>
                    </div>
                    <div
                      className="col-9"
                      style={{
                        padding: "0",
                      }}
                    >
                      <CKEditor
                        editor={ClassicEditor}
                        data={newData?.detail}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          setCkEditer(data);
                        }}
                        config={{
                          placeholder: "ກະລຸນາປ້ອນ...",
                        }}
                      />
                    </div>
                    <div style={{ color: "red" }}>
                      {errors.detail && touched.detail && errors.detail}
                    </div>
                    <div style={{ height: 20 }}></div>
                    {/* <div className="row">
                      <div className="col-md-4">
                        <div style={{ flexDirection: "row", display: "flex" }}>
                          <div style={{ fontWeight: "bold" }}>Start time</div>
                          <div style={{ color: "red", fontWeight: "bold" }}>
                            {" "}
                            *
                          </div>
                        </div>
                        <input
                          type="datetime-local"
                          name="startDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.startDate}
                          placeholder="ກະລຸນາປ້ອນ..."
                          className="form-control col-10"
                        />
                        <div style={{ color: "red" }}>
                          {errors.startDate &&
                            touched.startDate &&
                            errors.startDate}
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div style={{ height: 24 }}></div>
                        <input
                          type="datetime-local"
                          name="endDate"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.endDate}
                          placeholder="ກະລຸນາປ້ອນ..."
                          className="form-control col-10"
                        />
                        <div style={{ color: "red" }}>
                          {errors.endDate && touched.endDate && errors.endDate}
                        </div>
                      </div>
                    </div> */}
                    <div style={{ height: 20 }}></div>
                    <div>
                      <div>
                        <p style={{ margin: 0, fontWeight: "bold" }}>
                          Publishing status
                        </p>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="status"
                            checked={statusData}
                            onChange={() => setStatusData(!statusData)}
                            onBlur={handleBlur}
                            className="form-control col-9"
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div>
                      <p style={{ margin: 0, fontWeight: "bold" }}>Remark</p>
                      <textarea
                        id="note"
                        name="note"
                        rows="4"
                        value={values.note}
                        onChange={handleChange}
                        cols="50"
                        className="form-control col-9"
                      ></textarea>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <div
                      className="center col-9"
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                      }}
                    >
                      <button
                        type="button"
                        className="btn btn-secondary col-4"
                        style={{
                          border: "solid 1px #606060",
                          color: "#606060",
                          backgroundColor: "#ffff",
                        }}
                        onClick={navigateToNotifyList}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-secondary col-4"
                        style={{ backgroundColor: "#816AAE" }}
                      >
                        Confirm
                      </button>
                    </div>
                    <div style={{ height: 20 }}></div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
