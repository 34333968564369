import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import NavDropdown from "react-bootstrap/NavDropdown";
import { ADMIN_KEY } from "../consts";
import useReactRouter from "use-react-router";
import ImageProfile from "../image/profile.png";
import { URL_PHOTO_AW3 } from "../consts/api";

export default function NavBar() {
  const { history, location, match } = useReactRouter();
  const [userData, setUserData] = useState({});
  useEffect(() => {
    const ADMIN = localStorage.getItem(ADMIN_KEY);
    const _localJson = JSON.parse(ADMIN);
    setUserData(_localJson.data.display_image);
    if (!ADMIN) {
      history.push(`/`);
    }
  }, []);
  const _onLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    history.push(`/`);
  };

  const _onDetailProfile = () => {
    history.push(`/pagenumber/${1}/profile/${userData.id}`);
  };

  return (
    <div>
      <Navbar
        style={{
          backgroundColor: "#fff",
          boxShadow: "3px 0px 3px rgba(0, 0, 0, 0.16)",
          color: "#CC0000",
          width: "100%",
          height: 64,
          position: "fixed",
          zIndex: 1,
          marginLeft: 60,
          paddingRight: 80,
          zIndex: 1001,
        }}
        variant="dark"
      >
        <Navbar.Brand style={{ color: "#909090" }} href="#">
          {/*	<Image src={ImageLogo} height={40} width={150} />*/}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto" />
          <Form inline>
            <p style={{ marginTop: 10, marginRight: 20 }}>v1.0.13</p>
            <Dropdown>
              <Dropdown.Toggle
                style={{ color: "#909090" }}
                variant=""
                id="dropdown-basic"
              >
                {userData
                  ? (userData.firstName ? userData.firstName : "") +
                    " " +
                    (userData.lastName ? userData.lastName : "")
                  : ""}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {/* <Dropdown.Item
                  style={{ color: "#909090" }}
                  onClick={() => _onDetailProfile()}
                >
                  Profile
                </Dropdown.Item>
                <NavDropdown.Divider /> */}
                <Dropdown.Item
                  style={{ color: "#909090" }}
                  onClick={() => _onLogout()}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <Image
              src={
                userData.image
                  ? `${URL_PHOTO_AW3}${userData.image}`
                  : ImageProfile
              }
              width={50}
              height={50}
              roundedCircle
            />
          </Form>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}
