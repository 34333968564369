import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';
import Lottie from 'react-lottie';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faSyncAlt,
	faTimes
} from "@fortawesome/free-solid-svg-icons"
import { Modal, Button, Image } from 'react-bootstrap'
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST2,
	BODY_DEALERLIST,
	LIMIT
} from "../../../consts"
import { URL_PHOTO_AW3, PRESIGNED_URL, PRODUCT_API, PRODUCT_MODEL, PRODUCT_BRAND, CATEGORY_STORE } from '../../../consts/api'
import { PRODUCT } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import SelectSearch from 'react-select-search';
import "./style.css";

export default function ProductEdit() {
	const { history, location, match } = useReactRouter()
	const productId = match.params.id;
	const [namePhoto, setNamePhoto] = useState('')
	const [file, setFile] = useState()
	const [imageLoading, setImageLoading] = useState()
	const [isLoading, setIsLoading] = useState(false)
	const [isAddDescriptionShow, setIsAddDescriptionShow] = useState(false)

	const [product, setProduct] = useState({})

	const [descriptionName, setDescriptionName] = useState('')
	const [descriptionValue, setDescriptionValue] = useState('')
	const [descriptionData, setDescriptionData] = useState([])

	const [isEmptyCategory, setIsEmptyCategory] = useState(false)
	const [categoryId, setCategoryId] = useState('')
	const [categoryName, setCategoryName] = useState('')
	const [storeCategories, setStoreCategories] = useState([])

	const [isEmptyBrand, setIsEmptyBrand] = useState(false);
	const [brandId, setBrandId] = useState('')
	const [brandName, setBrandName] = useState('')
	const [brands, setBrands] = useState([])

	const [isEmptyModel, setIsEmptyModel] = useState(false);
	const [modelId, setModelId] = useState('')
	const [modelName, setModelName] = useState('')
	const [models, setModels] = useState([])

	const [isPopupShow, setIsPopupShow] = useState(false);
	const [isPopupSuccess, setIsPopupSuccess] = useState(false);

	const [getTokken, setgetTokken] = useState("")
	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductDatas(_localData);
			}
		}
		fetchData();
	}, [])

	const getProductDatas = async (token) => {
		try {
			setIsLoading(true)
			// get product data
			await fetch(`${PRODUCT_API}/${productId}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				console.log("DETAIL: ", json)
				if (json?.description) {
					let _description = JSON.parse(json?.description)
					setDescriptionData(_description ?? [])
				}
				if (json?.image) setNamePhoto(json?.image)
				if (json?.productCategory) {
					setCategoryId(json?.productCategory?._id)
					setCategoryName(json?.productCategory?.name)
					getModel(json?.productCategory?._id, token)
					getBrand(json?.productCategory?._id, token)
				}
				if (json?.brand) {
					setBrandId(json?.brand?._id)
					setBrandName(json?.brand?.name)
				}
				if (json?.model) {
					setModelId(json?.model?._id)
					setModelName(json?.model?.name)
				}
				setProduct(json)
			});
			// get category data
			await fetch(CATEGORY_STORE, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => setStoreCategories(json ?? []));
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}
	const handleUpload = async (event) => {
		setImageLoading("")
		try {
			setFile(event.target.files[0]);
			let fileData = event.target.files[0]
			const responseUrl = await axios({
				method: 'post',
				url: PRESIGNED_URL,
				headers: getTokken,
				data: {
					type: event.target.files[0].type
				}
			})
			console.log("responseUrl.data: ", responseUrl.data)
			setNamePhoto(responseUrl.data.filename)
			let afterUpload = await axios({
				method: "put",
				url: responseUrl.data.url,
				data: fileData,
				headers: {
					"Content-Type": " file/*; image/*",
					"Access-Control-Allow-Origin": "*",
					"Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
					"Access-Control-Allow-Headers":
						"Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
				},
				onUploadProgress: function (progressEvent) {
					var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
					setImageLoading(percentCompleted)
				}
			})
			console.log("afterUpload: ", afterUpload)
		} catch (error) {
			console.log("error: ", error)
		}
	}
	const ImageThumb = ({ image }) => {
		return <img src={URL.createObjectURL(image)} alt={image.name} style={{
			borderRadius: "5",
			height: 230,
			width: 230,
		}} />;
	};
	const getBrand = async (category, token) => {
		try {
			await fetch(`${PRODUCT_BRAND}/?${category ? `category=${category}` : ''}`, {
				method: "GET",
				headers: token ? token : getTokken
			}).then(response => response.json()).then(json => {
				// console.log("json: ", json)
				setBrands(json ?? [])
			});
		} catch (error) {
			console.log("error: ", error)
		}
	}
	const getModel = async (category, token) => {
		try {
			console.log("getTokken: ", getTokken)
			await fetch(`${PRODUCT_MODEL}/?${category ? `category=${category}` : ''}`, {
				method: "GET",
				headers: token ? token : getTokken
			}).then(response => response.json()).then(json => {
				// console.log("json: ", json)
				setModels(json ?? [])
			});
		} catch (error) {
			console.log("error: ", error)
		}
	}
	const handleChangeCategory = async (e) => {
		setBrands([])
		setModels([])
		setBrandId("")
		setBrandName("")
		setModelId("")
		setModelName("")
		const _getSelectId = storeCategories.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setCategoryId(_getSelectId[0]._id)
			setCategoryName(_getSelectId[0].name)
			getBrand(_getSelectId[0]._id, getTokken)
			getModel(_getSelectId[0]._id, getTokken)
		} else {
			setCategoryId("")
			setCategoryName("")
			getBrand(null)
			getModel(null)
		}
	}
	const handleChangeBrand = async (e) => {
		const _getSelectId = brands.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setBrandId(_getSelectId[0]._id)
			setBrandName(_getSelectId[0].name)
		} else {
			setBrandId("")
			setBrandName("")
		}
	}
	const handleChangeModel = async (e) => {
		const _getSelectId = models.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setModelId(_getSelectId[0]._id)
			setModelName(_getSelectId[0].name)
		} else {
			setModelId("")
			setModelName("")
		}
	}
	const onSaveProduct = async () => {
		try {
			const _isEmpty = checkValidate();
			if (!_isEmpty) {
				setIsLoading(true)
				let _data = {
					image: namePhoto ?? "",
					productCategory: categoryId,
					brand: brandId,
					model: modelId,
					description: JSON.stringify(descriptionData)
				}
				if (!namePhoto) delete _data.image
				const createProduct = await axios(`${PRODUCT_API}/edit/${productId}`, {
					method: "POST",
					headers: getTokken,
					data: _data
				})
				setIsLoading(false)
				if (createProduct?.data?.message == "SUCCESSFULLY") {
					setIsPopupSuccess(true)
					setIsPopupShow(true)
					setTimeout(() => {
						setIsPopupShow(false)
						history.push(`${PRODUCT}/limit/40/page/1`)
					}, 2000);
				} else {
					setIsPopupSuccess(false)
					setIsPopupShow(true)
				}
			}
		} catch (error) {
			setIsLoading(false)
			setIsPopupSuccess(false)
			setIsPopupShow(true)
			console.log("error:", error)
		}
	}
	const checkValidate = () => {
		let _status = false
		if (!categoryId || !brandId || !modelId) {
			if (!categoryId) setIsEmptyCategory(true)
			else setIsEmptyCategory(false)
			if (!brandId) setIsEmptyBrand(true)
			else setIsEmptyBrand(false)
			if (!modelId) setIsEmptyModel(true)
			else setIsEmptyModel(false)
			_status = true
		}
		return _status
	}
	const onAddDescriptionData = () => {
		if (descriptionName) {
			var _descriptionData = [...descriptionData, { name: descriptionName, values: [] }]
			setDescriptionData(_descriptionData)
			setDescriptionName("")
			setDescriptionValue("")
			setIsAddDescriptionShow(false)
		}
	}
	const onAddDescriptionValueData = (index) => {
		if (descriptionValue) {
			var _descriptionData = [...descriptionData]
			_descriptionData[index].values = [..._descriptionData[index].values, descriptionValue];
			setDescriptionData(_descriptionData)
			setDescriptionName("")
			setDescriptionValue("")
		}
	}
	const onDeleteDescriptionValueData = (index, valueName) => {
		var _descriptionData = [...descriptionData]
		var sortValue = _descriptionData[index]?.values?.filter(item => item != valueName);
		_descriptionData[index].values = sortValue
		setDescriptionData(_descriptionData)
		setDescriptionName("")
		setDescriptionValue("")
	}
	const onDeletedDescription = (index) => {
		var _descriptionData = [...descriptionData];
		_descriptionData.splice(index, 1);
		setDescriptionData(_descriptionData);
		setDescriptionName("");
		setDescriptionValue("");
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p><span onClick={() => history.push(`${PRODUCT}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>Product Template Management</span> » <span onClick={() => history.push(`${PRODUCT}/detail/${productId}`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>ຂໍ້ມູນຂອງສິນຄ້າ</span> » ແກ້ໄຂສິນຄ້າ</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%" }}>
				<div className="tab-content" id="myTabContent">
					{isLoading ? <AnimationLoading /> : <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row">
							<div className="col-md-3">
								<input type="file" id="file-upload" onChange={handleUpload} hidden />
								<label for="file-upload">
									<div style={{
										backgroundColor: "#E4E4E4E4",
										height: 230,
										width: 230,
										borderRadius: "5%",
										cursor: "pointer",
										display: "flex"
									}}>
										{file ?
											<ImageThumb image={file} />
											:
											(namePhoto ? <Image src={namePhoto ? `${URL_PHOTO_AW3}${namePhoto}` : "https://themesfinity.com/wp-content/uploads/2018/02/default-placeholder.png"} alt="AeonIcon" style={{
												height: 230,
												width: 230,
												borderRadius: "5%",
												cursor: "pointer",
												display: "flex",
												objectFit: "cover",
												border: "solid 2px #eee"
											}} />
												:
												<div style={{ borderRadius: "5", display: "flex", height: 230, width: 230, justifyContent: "center", alignItems: "center" }}>
													<p style={{ color: "#fff", fontSize: 80, fontWeight: "bold" }}>+</p>
												</div>)
										}
									</div>
								</label>
								{/* progass */}
								{imageLoading ? <div className="progress" style={{ height: 20 }}>
									<div className="progress-bar" role="progressbar" style={{ width: `${imageLoading}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoading} aria-valuemin="0" aria-valuemax="100">{imageLoading}%</div>
								</div> : <div style={{ height: 20 }} />}
							</div>
							<div className="col-md-9">
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>Product Detail</p>
								</div>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Product Category <span style={{ color: "red" }}>*</span></p>
								<select
									name="productCategory"
									className="btn-block form-control"
									style={{ border: isEmptyCategory ? '1px solid red' : '' }}
									onChange={(e) => handleChangeCategory(e)}
									value={categoryName}
								>
									<option value="">Please select</option>
									{storeCategories.length > 0 && storeCategories.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Brand <span style={{ color: "red" }}>*</span></p>
								<select
									name="brand"
									className="btn-block form-control"
									style={{ border: isEmptyBrand ? '1px solid red' : '' }}
									onChange={(e) => handleChangeBrand(e)}
									value={brandName}
								>
									<option value="">Please select</option>
									{brands.length > 0 && brands.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
								{/* <SelectSearch
									options={[]}
									value={brandId}
									onChange={(e) => handleChangeBrand(e)}
									getOptions={(query) => {
										if (categoryId) {
											if (query) {
												return new Promise((resolve, reject) => {
													fetch(`${PRODUCT_BRAND}/?category=${categoryId}&&name=${query}`).then(response => response.json()).then((results) => {
														resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
													}).catch(reject);
												});
											} else {
												return new Promise((resolve, reject) => {
													fetch(`${PRODUCT_BRAND}/?category=${categoryId}`).then(response => response.json()).then((results) => {
														console.log("results: ", results)
														resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
													}).catch(reject);
												});
											}
										}
									}}
									search
									placeholder="Select brand..."
								/> */}
								<div style={{ height: 16 }} />
								<p style={{ fontWeight: "bold" }}>Product Description <span style={{ color: "red" }}>*</span></p>
								<select
									name="model"
									className="btn-block form-control"
									style={{ border: isEmptyModel ? '1px solid red' : '' }}
									onChange={(e) => handleChangeModel(e)}
									value={modelName}
								>
									<option value="">Please select</option>
									{models.length > 0 && models.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
								{/* <SelectSearch
									options={[]}
									value={modelId}
									onChange={(e) => handleChangeModel(e)}
									getOptions={(query) => {
										if (categoryId) {
											if (query) {
												return new Promise((resolve, reject) => {
													fetch(`${PRODUCT_MODEL}/?category=${categoryId}&&name=${query}`).then(response => response.json()).then((results) => {
														resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
													}).catch(reject);
												});
											} else {
												return new Promise((resolve, reject) => {
													fetch(`${PRODUCT_MODEL}/?category=${categoryId}`).then(response => response.json()).then((results) => {
														console.log("results: ", results)
														resolve(results.map(({ _id, name }) => ({ value: _id, name: name })))
													}).catch(reject);
												});
											}
										}
									}}
									search
									placeholder="Select description..."
								/> */}
								<div style={{ height: 16 }} />
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>Model</p>
								</div>
								<button type="button" disabled={descriptionData.length >= 5} onClick={() => setIsAddDescriptionShow(true)} className="btn btn-secondary col-md-3" style={{ backgroundColor: descriptionData.length >= 5 ? "#bebebe" : "#816AAE" }}>Add Description</button>
								<div style={{ height: 8 }} />
								{descriptionData.map((item, index) => <div key={index} style={{ marginBottom: 10, border: "1px solid #b6b6b6", borderRadius: 8, padding: 10 }}>
									<FontAwesomeIcon onClick={() => onDeletedDescription(index)} icon={faTimes} style={{ color: "red", fontSize: 24, cursor: "pointer", float: 'right', marginTop: -20, marginRight: -16 }} />
									<span style={{ fontWeight: "bold" }}>{item?.name ?? "-"}</span>
									<div style={{ width: "100%", display: 'flex', flexDirection: 'row', alignItems: "center" }}>
										<input type="text" onChange={(e) => setDescriptionValue(e?.target?.value)} className="col-md-9 form-control" placeholder="ກະລຸນາປ້ອນ..." />
										<button type="button" onClick={() => onAddDescriptionValueData(index)} className="btn btn-secondary col-md-2" style={{ backgroundColor: "#816AAE" }}>Add</button>
									</div>
									<div style={{ display: 'flex', flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
										{item?.values?.map((valuesItem, valuesIndex) => <div key={valuesIndex} style={{ backgroundColor: "#eee", borderRadius: 5, width: "auto", height: 28, paddingLeft: 8, paddingRight: 8, marginRight: 16, marginTop: 10 }}>
											<FontAwesomeIcon onClick={() => onDeleteDescriptionValueData(index, valuesItem)} icon={faTimes} style={{ color: "#1b1b1b", cursor: "pointer", float: 'right', marginTop: -6, marginRight: -10 }} />
											<span>{valuesItem ?? "-"}</span>
										</div>)}
									</div>
								</div>)}
								<div style={{ marginBottom: 10 }}>
									<span style={{ fontWeight: "bold" }}>ລາຍລະອຽດ</span>
									<div style={{ width: "100%", justifyContent: 'flex-start', alignItems: 'flex-start', border: '1px solid #bebebe', borderRadius: 8, backgroundColor: '#eee', minHeight: 70, padding: 8 }}>
										{descriptionData.map((item, index) => {
											return <span key={index}><span style={{ fontWeight: "bold" }}>{item?.name}</span>: {item?.values?.map((item2, index2) => item2 + (item?.values.length != index2 + 1 ? ", " : ""))}{(descriptionData.length != index + 1 ? ";  " : "")}</span>
										})}
									</div>
								</div>
							</div>
						</div>
						<div style={{ height: 70 }}></div>
						<div style={{ display: 'flex', flexDirection: "row", alignItems: "center", width: "100%", justifyContent: "center", height: 70 }}>
							<div className="col-md-2">
								<button type="button" onClick={() => history.goBack()} className="btn col-md-12" style={{ float: "right", border: "1px solid #969696", color: "#969696" }}>Cancel</button>
							</div>
							<div style={{ width: 100 }}></div>
							<div className="col-md-2">
								<button type="button" onClick={() => onSaveProduct()} className="btn btn-secondary col-md-12" style={{ float: "right", backgroundColor: "#816AAE" }}>Edit Product</button>
							</div>
						</div>
					</div>}
				</div>
			</div>

			<Modal centered show={isAddDescriptionShow} onHide={() => setIsAddDescriptionShow(false)}>
				<Modal.Body >
					<div style={{ padding: 10 }}>
						<div style={{ fontWeight: "bold" }}>Add Description</div>
						<div style={{ height: 24 }}></div>
						<input type="text" className="col-md-12 form-control" onChange={(e) => setDescriptionName(e?.target?.value)} placeholder="ກະລຸນາປ້ອນ... ຕົວຢ່າງ: RAM" />
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={() => setIsAddDescriptionShow(false)}>
						Cancel
					</Button>
					<Button style={{ backgroundColor: "#816AAE" }} onClick={() => onAddDescriptionData()}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Modal centered show={isPopupShow} onHide={() => setIsPopupShow(false)}>
				<Modal.Body>
					{isPopupSuccess ? <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Successfully</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets9.lottiefiles.com/private_files/lf30_imtpbtnl.json",
							}}
							width={300}
							height={300} />
					</div> : <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Create Failed</div>
						<div style={{ fontSize: 18 }}>Oop!, Some thing when wrong, please try again</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets5.lottiefiles.com/packages/lf20_gzlupphk.json",
							}}
							width={300}
							height={300} />
					</div>}
				</Modal.Body>
				{!isPopupSuccess && <Modal.Footer>
					<Button style={{ backgroundColor: "#816AAE" }} onClick={() => setIsPopupShow(false)}>OK</Button>
				</Modal.Footer>}
			</Modal>

		</div>
	)
}
