import React, { useState, useEffect } from "react"
import "../utils/index.css"
import useReactRouter from "use-react-router"
import ImageLogo from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import axios from 'axios';
import { successUpdate, errorAdd } from '../../../helper/sweetalert'

import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL
} from "../../../consts"
import {
    PROMOTION_LIST,
    PROMOTION_POPUP_LIST
} from '../../../consts/router'
import { PRESIGNED_URL, GET_POP_UP, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

export default function PromotionPopupEdit() {
    const { history, location, match } = useReactRouter()
    const _promotionList_page = () => {
        history.push(`${PROMOTION_LIST}/popUp`);
    }
    const _popUp = () => {
        history.push(`${PROMOTION_POPUP_LIST}`);
    }
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    // upload pic
    const [namePhoto, setNamePhoto] = useState('')
    const [file, setFile] = useState()
    const [imageLoading, setImageLoading] = useState()
    const [statusData, setStatusData] = useState();
    const [typeData, _setType] = useState();
    const handleUpload = async (event) => {
        setImageLoading("")
        try {
            setFile(event.target.files[0]);
            let formData = new FormData();
            let fileData = event.target.files[0]
            const responseUrl = await axios({
                method: 'post',
                url: PRESIGNED_URL,
                headers: getTokken,
                data: {
                    type: event.target.files[0].type
                }
            })
            setNamePhoto(responseUrl.data)
            let afterUpload = await axios({
                method: "put",
                url: responseUrl.data.url,
                data: fileData,
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setImageLoading(percentCompleted)
                }
            })
        } catch (error) {
        }
    }
    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} alt={image.name} style={{
            borderRadius: "5",
            height: 500,
            width: 400,
        }} />;
    };
    const [newDataPopUp, setPopUp] = useState()
    const _createPopUp = () => {
        axios({
            method: 'PUT',
            url: GET_POP_UP + "/" + newDataPopUp?._id,
            headers: getTokken,
            data: {
                image: namePhoto?.filename,
            }
        }).then(function (response) {
            successUpdate()
            setTimeout(() => {
                history.push(`${PROMOTION_POPUP_LIST}`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    const [isLoading, setIsLoading] = useState(false)
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(GET_POP_UP, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setPopUp(json));
        setIsLoading(false)
    }
    return (
        <div style={TITLE_HEADER}>
            <div style={HEADER}>
                <a href="#" style={{ paddingBottom: 20, color: "#0E60D4" }} onClick={() => _promotionList_page()}> Pop-up »</a> Edite-pop-up
                <div style={{ height: 20 }}></div>
            </div>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ height: 100 }}></div>
                <div className="col-md-12 center">
                    <input type="file" id="file-upload" onChange={handleUpload} hidden />
                    <label for="file-upload">
                        <div style={{
                            backgroundColor: "#E4E4E4E4",
                            height: 500,
                            width: 400,
                            borderRadius: "5%",
                            cursor: "pointer",
                            display: "flex"
                        }}>
                            {file ? <ImageThumb image={file} /> : <Image
                                src={URL_PHOTO_AW3 + newDataPopUp?.image}
                                alt="AeonIcon"
                                width="100"
                                height="100"
                                style={{
                                    height: 500,
                                    width: 400,
                                    borderRadius: "5%",
                                    cursor: "pointer",
                                    display: "flex"
                                }}
                            />}
                        </div>
                    </label>
                    {/* progass */}
                    {imageLoading ? <div className="progress" style={{ height: 20 }}>
                        <div className="progress-bar" role="progressbar" style={{ width: `${imageLoading}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoading} aria-valuemin="0" aria-valuemax="100">{imageLoading}%</div>
                    </div> : <div style={{ height: 20 }} />}
                    <div style={{ height: 20 }}></div>
                    <div className="col-md-12">
                        <div style={{ height: "50px" }}></div>
                        <button type="button" className="btn btn-secondary col-4 center" style={{ border: "solid 1px #606060", color: "#606060", backgroundColor: "#ffff" }} onClick={() => _popUp()}>ຍົກເລີກ</button>
                        <button type="button" className="btn btn-secondary col-4 center" style={{ backgroundColor: "#816AAE" }} onClick={() => _createPopUp()}>ຢືນຢັນ</button>
                        <div style={{ height: 20 }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

