import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY_DEALERLIST2,
    BODY_DEALERLIST,
    LIMIT
} from "../../../consts"
import { URL_PHOTO_AW3 } from '../../../consts/api'
import {
    DISCOUNT_SHOP_DETAIL,
    DISCOUNT_SHOP_ADD,
    STORE_DISCOUNT_LIST,
    STORE
} from '../../../consts/router'
import profileImage from "../../../image/profile.png"
import { DISCOUNT_SHOP, CATEGORY_STORE } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
export default function DiscountList() {
    const { history, location, match } = useReactRouter()
    const _limit = match.params.limit;
    const _page = match.params.page;
    const parsed = queryString.parse(location.search);
    const newDate = new Date();

    const [isLoading, setIsLoading] = useState(false)
    const _dealerShop = () => {
        history.push(`${STORE}/limit/40/page/1`)
    }
    const _discountShop = () => {
        history.push(`${STORE_DISCOUNT_LIST}`)
    }
    const _discountShopDetail = (id) => {
        history.push(`${DISCOUNT_SHOP_DETAIL}${id}`, id)
    }
    const _discountShopAdd = () => {
        history.push(`${DISCOUNT_SHOP_ADD}`)
    }
    // get discountShop
    const [getDiscountShops, setGetDiscountShops] = useState();
    const [categoryStore, setCategoryStore] = useState()
    // ==== fillter ===
    const [fillterName, setfillterName] = useState(parsed?.name ? parsed?.name : '')
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    // =====
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [fillterName])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [endDate && startDate])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(DISCOUNT_SHOP + `/limit/${_limit}/page/${_page}/?storeType=DISCOUNTSHOP&&${fillterName ? `&&name=${fillterName}&&` : ""}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setGetDiscountShops(json));
        await fetch(CATEGORY_STORE, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setCategoryStore(json));
        setIsLoading(false)
    }
    useEffect(() => {
        if (getDiscountShops?.total > 0) {
            _getArrayPageNumber()
        }
    }, [getDiscountShops])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (getDiscountShops?.total % parseInt(_limit) != 0) {
            _total = (parseInt(getDiscountShops?.total) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(getDiscountShops?.total) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _fillterName = (e) => {
        setfillterName(e?.target?.value)
    }
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${STORE_DISCOUNT_LIST}/limit/${_limit}/page/${page}?${fillterName ? `&&name=${fillterName}&&` : ""}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}` : ''}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <p>All Shops » </p>
            </h6>
            <ul className="nav nav-tabs col-sm-11" id="myTab" style={{ marginLeft: 30, border: "solid 0px" }}>
                <li className="nav-item">
                    <a className="nav-link" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" onClick={() => _dealerShop()} style={{ cursor: "pointer", color: "blue" }}>Dealer Shop</a>
                </li>
                <li className="nav-item">
                    <a style={{ border: "solid 0px" }} className="nav-link active" id="contact-tab" data-toggle="tab" role="tab" aria-controls="contact" aria-selected="false" onClick={() => _discountShop()}>Discount Shop</a>
                </li>
            </ul>
            <div className="col-sm-12" style={BODY_DEALERLIST}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div>
                            <p style={{ fontWeight: "bold" }}>Search</p>
                            <input type="text" className="col-md-12 form-control" value={fillterName} onChange={(e) => _fillterName(e)} />
                        </div>
                        <div style={{ height: 10 }}></div>
                        <div className="row">
                            <div className="col-md-4">
                                <p style={{ fontWeight: "bold" }}>Registered from</p>
                                <input type="date" className="form-control col-12" value={startDate} onChange={(e) => _startDate(e)} />
                            </div>
                            <div className="col-md-4">
                                <p style={{ fontWeight: "bold" }}>Registered to</p>
                                <input type="date" className="form-control col-12" value={endDate} onChange={(e) => _endDate(e)} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-sm-12" style={BODY_DEALERLIST2}>
                <div style={{ padding: 20 }}>
                    <div className="col-md-4">All Shops ({getDiscountShops?.total})</div>
                    <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-4">
                            <div style={{ height: 10 }}></div>
                            <button type="button" className="btn btn-secondary col-md-6" style={{ float: "right", backgroundColor: "#816AAE" }} onClick={() => _discountShopAdd()}>Add shop</button>
                        </div>
                    </div>
                    {isLoading ? <AnimationLoading /> : <div>
                        <div className="col-sm-12">
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Pictures</th>
                                        <th scope="col">Shop name</th>
                                        {/* <th scope="col">Category</th> */}
                                        <th scope="col">Discount</th>
                                        <th scope="col">Phone number</th>
                                        <th scope="col">Registration date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {getDiscountShops?.results?.map((discount, index) => {
                                        return (
                                            <tr onClick={() => _discountShopDetail(discount?._id)}>
                                                <th scope="row">{index + 1 + parseInt(_limit) * parseInt(_page - 1)}</th>
                                                <td>
                                                    {discount?.image ? (
                                                        <Image src={URL_PHOTO_AW3 + discount?.image} alt="AeonIcon" width="150" height="150" style={{
                                                            height: 50,
                                                            width: 50,
                                                            borderRadius: 5,
                                                        }} />
                                                    ) : (
                                                        <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                            height: 50,
                                                            width: 50,
                                                            borderRadius: 5,
                                                        }} />
                                                    )}
                                                </td>
                                                <td>{discount?.name}</td>
                                                {/* <td>{discount?.storecategory?.name}</td> */}
                                                <td>{discount?.discount} %</td>
                                                <td>{discount?.phoneNumber}</td>
                                                <td>{formatDate(discount?.createdAt)}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                            <div className="row center">
                                {totalPage?.map((item, index) => {
                                    return (
                                        <button style={{
                                            width: 30,
                                            height: 30,
                                            border: "solid 1px #816aae",
                                            marginLeft: 2,
                                            backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                                            color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                        }} onClick={() => _nextPage(item)}
                                            key={item}
                                        >{item}</button>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}
