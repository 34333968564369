import React, { useState, useEffect } from "react"
import "../utils/index.css"
import useReactRouter from "use-react-router"
import profileImage from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import { Modal, Button } from 'react-bootstrap'
import { successDelete, errorAdd } from '../../../helper/sweetalert'

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    PROMOTION_POPUP_LIST,
    PROMOTION_POPUP_ADD,
    PROMOTION_POPUP_EDIT,
    PROMOTION_LIST,
    NOTIFY_LIST
} from '../../../consts/router'
import { URL_PHOTO_AW3, GET_POP_UP } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

export default function PromotionList() {
    const { history, location, match } = useReactRouter()
    const [newDataPopUp, setPopUp] = useState()
    const _promotionPopupAdd = () => {
        history.push(`${PROMOTION_POPUP_ADD}`)
    }
    const _promotionPopupEdit = () => {
        history.push(`${PROMOTION_POPUP_EDIT}`)
    }
    const _popUpList = () => {
        history.push(`${PROMOTION_POPUP_LIST}`)
    }
    const _promotionList = () => {
        history.push(`${PROMOTION_LIST}/limit/${LIMIT}/page/1/`)
    }
    const _noticeList = () => {
		history.push(`${NOTIFY_LIST}/limit/${LIMIT}/page/1/`)
	}
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const _delete = async () => {
        setIsLoading(true)
        await fetch(GET_POP_UP + "/" + newDataPopUp?._id, {
            method: "DELETE",
            headers: getTokken
        }).then(function (response) {
            successDelete()
            setTimeout(() => {
                history.push(`${PROMOTION_POPUP_LIST}`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    const [isLoading, setIsLoading] = useState(false)
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(GET_POP_UP, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setPopUp(json));
        setIsLoading(false)
    }
    return (
        <div style={TITLE_HEADER}>
            <div style={HEADER}>
                <div style={{ paddingBottom: 10 }}> Popups </div>
            </div>
            <ul className="nav nav-tabs" id="myTab" style={{ width: "95%", marginLeft: "30px" }}>
                <li className="nav-item">
                    <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => _promotionList()}>Promotion</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link active" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false" onClick={() => _popUpList()}>Pop up</a>
                </li>
                <li className="nav-item">
					<a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false" onClick={() => _noticeList()}>Notify</a>
				</li>
            </ul>
            <div style={BODY}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Manage pop Up</div>
                        <div className="col-md-12" style={{ textAlign: 'right' }}>
                            {newDataPopUp &&
                                <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px #816AAE", color: "#816AAE" }} onClick={handleShow}>Delete</button>
                            }
                            {newDataPopUp &&
                                <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _promotionPopupEdit()}><i className="fas fa-pencil-alt"></i> Edit</button>
                            }
                            {!newDataPopUp &&
                                <button type="button" className="btn btn-secondary col-md-2" style={{ backgroundColor: "#816AAE", color: "#FFFF" }} onClick={() => _promotionPopupAdd()}>Add pop up</button>
                            }
                        </div>
                        <div style={{ height: "20px" }}></div>
                        <div>
                            <div className="col-md-12">
                                {newDataPopUp?.image ? (
                                    <center>
                                        <Image src={URL_PHOTO_AW3 + newDataPopUp?.image} alt="AeonIcon" width="150" height="150" style={{
                                            height: 500,
                                            width: 400,
                                            objectFit: "cover",
                                            borderRadius: 8,
                                            border: "solid 2px #707070",
                                            padding: 1

                                        }} />
                                    </center>
                                ) : (
                                    <center>
                                        <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                            height: 500,
                                            width: 400,
                                            objectFit: "cover",
                                            borderRadius: 8,
                                            border: "solid 2px #707070",
                                            padding: 1

                                        }} />
                                    </center>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body >
                    <div className="row center" style={{ fontWeight: "bold" }}>
                        <div style={{ textAlign: "center" }}>Do you want delete </div>
                        <div style={{ marginLeft: 10 }}> ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => _delete()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div >
    )
}
