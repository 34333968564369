import React, { useState, useEffect } from 'react'
import useReactRouter from "use-react-router"
import queryString from 'query-string';
import MapContainer from "../../../consts/locationMap"
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    LOCATION_LIST,
    CUSTOMMER_DETAIL,
    LOCATION_LAST_LONG,
} from '../../../consts/router'
import { HISTORY_LOGIN_LOCATION, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDateTime } from '../../../common/super'
import AnimationLoading from "../../../helper/loading.js"

export default function HistoryLastLogin() {
    const { history, location, match } = useReactRouter()
    const _limit = match.params.limit;
    const _page = match.params.page;
    const parsed = queryString.parse(location.search);
    var newDate = new Date();

    const _locationList = () => {
        history.push(`${LOCATION_LIST}/limit/${LIMIT}/page/1`);
    }
    const _customerDetail = (id) => {
        history.push(`${CUSTOMMER_DETAIL}${id}`, id);
    }
    // =========== google_map =========>>>>.
    const [historyLocation, setHistoryLocation] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [endDate && startDate])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(HISTORY_LOGIN_LOCATION + "/" + match?.params?.id + "/limit/" + match?.params?.limit + "/page/" + match?.params?.page + `${startDate && endDate ? `/?startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setHistoryLocation(json));
        setIsLoading(false)
    }
    const [latData, setLatData] = useState()
    const [lngData, setLngData] = useState()
    const [dateTime, setDateTime] = useState()
    useEffect(() => {
        if (historyLocation?.userLogIn[0]) {
            setLatData(historyLocation?.userLogIn[0]?.lat)
            setLngData(historyLocation?.userLogIn[0]?.lng)
            setDateTime(historyLocation?.userLogIn[0]?.createdAt)
        }
        _getArrayPageNumber()
    }, [historyLocation])
    const _onchangLocation = (newLat, newLng, date) => {
        setLatData(newLat)
        setLngData(newLng)
        setDateTime(date)
    }
    // =============++++++ pagination +++++++===========+>>>>
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (historyLocation?.total % parseInt(match?.params?.limit) != 0) {
            _total = (parseInt(historyLocation?.total) / parseInt(match?.params?.limit)) + 1;
        } else {
            _total = parseInt(historyLocation?.total) / parseInt(match?.params?.limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${LOCATION_LAST_LONG}${match?.params?.id}/limit/${match?.params?.limit}/page/${page}/?${startDate && endDate ? `startDate=${startDate}&&endDate=${endDate}` : ''}`)
    }
    return (
        <div style={TITLE_HEADER}>

            <div style={HEADER}>
                <a href="" style={{ paddingBottom: 10 }} onClick={() => _locationList()}> All Login records »</a> {historyLocation?.user?.localfirstname}
            </div>
            <div style={{ height: 10 }}></div>
            <div style={BODY}>
                <div className="row">
                    <div className="col-md-6" >
                        <div style={{ paddingBottom: 30, fontSize: "20px", color: "#707070", fontWeight: "bold" }}>History Location</div>
                        <div className="row">
                            <div className="col-md-5">
                                <p>Date To</p>
                                <input type="date" className="col-md-12 form-control" value={startDate} onChange={(e) => _startDate(e)} />
                            </div>
                            <div className="col-md-1"></div>
                            <div className="col-md-5">
                                <p>Date</p>
                                <input type="date" className="col-md-12 form-control" value={endDate} onChange={(e) => _endDate(e)} />
                            </div>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <p className="left" style={{ color: "#5C5C5C" }}>{_page} - {historyLocation?.total}</p>
                        {isLoading ? <AnimationLoading /> :
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Time</th>
                                        <th scope="col">Latitude & Longitude</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {historyLocation?.userLogIn?.map((item, index) => {
                                        return (
                                            <tr onClick={() => _onchangLocation(item?.lat, item?.lng, item?.createdAt)}>
                                                <th scope="row">{index + 1 + parseInt(match?.params?.limit) * parseInt(match?.params?.page - 1)}</th>
                                                <td>{formatDateTime(item?.createdAt)}</td>
                                                <td>({item?.lat}),({item?.lng})</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        }
                        <div className="row center">
                            {totalPage?.map((item, index) => {
                                return (
                                    <button style={{
                                        width: 30,
                                        height: 30,
                                        border: "solid 1px #816aae",
                                        marginLeft: 2,
                                        backgroundColor: parseInt(match?.params?.page) == index + 1 ? "#9999ff" : "#fff",
                                        color: parseInt(match?.params?.page) == index + 1 ? "#fff" : "#000",
                                    }} onClick={() => _nextPage(item)}
                                        key={item}
                                    >{item}</button>
                                )
                            })}
                        </div>
                    </div>
                    <div style={{ width: 10, backgroundColor: "#E4E4E4" }}></div>
                    <div className="col-md-5">
                        <div style={{ paddingBottom: 30, fontSize: "20px", color: "#707070", fontWeight: "bold" }}>Google Map</div>
                        <div className="row">
                            <div className="col-5">
                                <p style={{ color: "#909090" }}>Date-Time</p>
                            </div>
                            <div className="col-7">
                                <p>{formatDateTime(dateTime)}</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <p style={{ color: "#909090" }}>Latitude & Longitude</p>
                            </div>
                            <div className="col-7">
                                <p>({latData}),({lngData})</p>
                            </div>
                        </div>
                        <div className="col-12">
                            <MapContainer
                                lat={latData}
                                lng={lngData}
                                width="120%"
                                height="100vh"
                            />
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div style={{ textAlign: "center" }}>
                            <button className="btn btn-app col-sm-4" onClick={() => _customerDetail(historyLocation?.user?._id)}>View customer data</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}