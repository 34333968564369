import React, { useState, useEffect } from 'react'
import { Card, CardDeck } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import useReactRouter from "use-react-router"
import { faDotCircle, faStore, faTimesCircle, faUsers } from '@fortawesome/free-solid-svg-icons'
import "../utils/index.css"
import {
    TITLE_HEADER,
    HEADER,
    BODY,
} from "../../../consts"
import { Pie } from "react-chartjs-2";
import { USERS, HISTORY_LOGIN, DEALER_SHOP, GET_ISSU_REPORT, CATEGORY_STORE } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'
import { formatDate } from '../../../common/super'

import {
    STORE,
    CUSTOMMER_LIST,
    ISSUE_REPORT_LIST,
    ISSUE_REPORT_DETAIL,
    LOCATION_LAST_LOGIN
} from '../../../consts/router'
const chartColors = ["#336699", "#99CCFF"];

export default function Dashboard() {
    const { history, location, match } = useReactRouter()
    const [dataCustomer, setdataCustomer] = useState()
    const [dataCustomerFailed, setdataCustomerFailed] = useState()
    const [dataDealer, setdataDealer] = useState()
    const [dataDiscount, setdataDiscount] = useState()
    const [ActiveCustomer, setActiveCustomer] = useState()
    const [NoneactiveCustomer, setNoneactiveCustomer] = useState()
    const [isureport, setisureport] = useState()
    const [storeCategories, setStoreCategories] = useState([])
    const _historyPushStore = () => {
        history.push(`${STORE}/limit/40/page/1`)
    }
    const _historyPushCustomer = () => {
        history.push(`${CUSTOMMER_LIST}/limit/40/page/1`)
    }
    const _historyIsureport = () => {
        history.push(`${ISSUE_REPORT_LIST}/limit/40/page/1/`)
    }
    const _issueReportDetail = (id) => {
        history.push(`${ISSUE_REPORT_DETAIL}${id}`)
    }
    const _failsHistory = (id) => {
        history.push(`${LOCATION_LAST_LOGIN}/limit/40/page/1/?status=FAILED`)
    }
    const _shopList = () => {
        history.push(`${STORE}/limit/40/page/1`)
    }
    const _customer = () => {
        history.push(`${CUSTOMMER_LIST}/limit/40/page/1`)
    }
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    const getData = async (token) => {
        try {
            await fetch(USERS + `limit/10/page/1`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setdataCustomer(json));
            await fetch(USERS + `limit/1/page/1?expresscardstatus=active`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setActiveCustomer(json));
            await fetch(USERS + `limit/1/page/1?expresscardstatus=inactive`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setNoneactiveCustomer(json));
            await fetch(HISTORY_LOGIN + `/limit/1/page/1?status=FAILED`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setdataCustomerFailed(json));
            await fetch(DEALER_SHOP + `/limit/6/page/1/?storeType=DEALERSHOP`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setdataDealer(json));
            await fetch(DEALER_SHOP + `/limit/1/page/1/?storeType=DISCOUNTSHOP`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setdataDiscount(json));
            await fetch(GET_ISSU_REPORT + `limit/10/page/1/?`, {
                method: "GET",
                headers: token
            }).then(response => response.json())
                .then(json => setisureport(json?.results));
            await fetch(CATEGORY_STORE, {
                method: "GET",
                headers: token
            }).then(response => response.json()).then(json => setStoreCategories(json ?? []));
        } catch (error) {
            console.log("error: ", error);
        }
    }
    const data2 = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["Dealer Shop", "Discount Shop"],
        datasets: [
            {
                data: [dataDealer?.total, dataDiscount?.total],
                backgroundColor: ["#FF8373", "#FFDA83"],
                hoverBackgroundColor: chartColors
            }
        ]
    };
    const data = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["Active", "Inactive"],
        datasets: [
            {
                data: [ActiveCustomer?.total, NoneactiveCustomer?.total],
                backgroundColor: ["#00DD3A", "#D3D1D1"],
                hoverBackgroundColor: chartColors
            }
        ]
    };
    const onlineApplicationData = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["Success", "Fail"],
        datasets: [
            {
                data: [400, 50],
                backgroundColor: ["#00DD3A", "#FF8373"],
                hoverBackgroundColor: chartColors
            }
        ]
    };
    const paymentOnlineData = {
        maintainAspectRatio: false,
        responsive: false,
        labels: ["Success", "Not success"],
        datasets: [
            {
                data: [250, 150],
                backgroundColor: ["#00DD3A", "#FF8373"],
                hoverBackgroundColor: chartColors
            }
        ]
    };
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <p>Dashboard » </p>
            </h6>
            <div style={BODY}>
                <div style={{}}>
                    <CardDeck>
                        <Card style={{ border: 'solid 0px' }}>
                            <Card.Body>
                                <div className="col-md-12 shadowCSS row" style={{ height: 120, cursor: "pointer", borderLeft: "solid 3px #816AAE", borderRadius: 8 }} onClick={() => _customer()}>
                                    <div className="col-md-9 align-self-center">
                                        <div className="card-text" style={{ color: "#7A65A5", fontWeight: "bold" }}>The total number of customers</div>
                                        <div className="card-text" style={{ color: "#606060", fontWeight: "bold" }}>{dataCustomer?.total} People</div>
                                    </div>
                                    <div className="col-md-3 align-self-center" style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faUsers} style={{ fontSize: "50px", color: "#7A65A5", opacity: 0.5 }} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card style={{ border: 'solid 0px' }}>
                            <Card.Body>
                                <div className="col-md-12 shadowCSS row" style={{ height: 120, cursor: "pointer", borderLeft: "solid 3px #128AD9", borderRadius: 8 }} onClick={() => _shopList()}>
                                    <div className="col-md-9 align-self-center">
                                        <div className="card-text" style={{ color: "#128AD9", fontWeight: "bold" }}>The total number of shops</div>
                                        <div className="card-text" style={{ color: "#606060", fontWeight: "bold" }}>{dataDealer?.total + dataDiscount?.total} Shops</div>
                                    </div>
                                    <div className="col-md-3 align-self-center" style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faStore} style={{ fontSize: "50px", color: "#128AD9", opacity: 0.5 }} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <Card style={{ border: 'solid 0px' }}>
                            <Card.Body>
                                <div className="col-md-12 shadowCSS row" style={{ height: 120, cursor: "pointer", borderLeft: "solid 3px #FF2B2B", borderRadius: 8 }} onClick={() => _failsHistory()}>
                                    <div className="col-md-9 align-self-center">
                                        <div className="card-text" style={{ color: "#FF2B2B", fontWeight: "bold" }}>Login fails</div>
                                        <div className="card-text" style={{ color: "#606060", fontWeight: "bold" }}>{dataCustomerFailed?.total} time (s)</div>
                                    </div>
                                    <div className="col-md-3 align-self-center" style={{ textAlign: "center" }}>
                                        <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "50px", color: "#FF2B2B", opacity: 0.5 }} />
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </CardDeck>
                </div>
                <div style={{}}>
                    <div style={{ flexDirection: "row", display: "flex", }}>
                        <div className="shadowCSS col" style={{ padding: 10, borderRadius: 5, marginRight: 20 }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-6">Shop</div>
                                <a href="#" className="col-6 text-right" style={{ float: "right", color: "blue" }} onClick={() => _historyPushStore()}>See All</a>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Pie data={data2} />
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#FF8373" }} />  Dealer shop   {dataDealer?.total}</div>
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#FFDA83" }} />  Discount shop  {dataDiscount?.total} </div>
                            </div>
                        </div>
                        <div className="shadowCSS col" style={{ padding: 10, borderRadius: 5, marginRight: 20, marginleft: 20 }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-6">Customer Member</div>
                                <a href="#" className="col-6 text-right" style={{ float: "right", color: "blue" }} onClick={() => _historyPushCustomer()}>See All</a>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Pie data={data} />
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#35E462" }} />  Member  {ActiveCustomer?.total}</div>
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#D3D1D1" }} />  Inactive  {NoneactiveCustomer?.total}</div>
                            </div>
                        </div>
                        <div className="shadowCSS col" style={{ padding: 10, borderRadius: 5, marginleft: 10 }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-9">Online Application submission status</div>
                                <a href="#" className="col-3 text-right" style={{ float: "right", color: "blue" }}>See All</a>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Pie data={onlineApplicationData} />
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#35E462" }} />  Success  {"400"}</div>
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#FF8373" }} />  Fail  {"50"} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: 40 }}></div>
                <div style={{}}>
                    <div className="shadowCSS" style={{ padding: 30 }}>
                        <div className="row" style={{ paddingLeft: 30, paddingRight: 40 }}>
                            <div className="col-6"></div>
                            <a href='#' className="col-6 text-right" style={{ float: "left" }} onClick={() => _historyIsureport()}>See All</a>
                        </div>
                        <div className="col-sm-12" style={{ marginTop: 20 }}>
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Phone number</th>
                                        <th scope="col">type</th>
                                        <th scope="col">Detail</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {isureport?.map((item, index) => {
                                        return (
                                            <tr key={index} onClick={() => _issueReportDetail(item?._id)}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item?.phoneNumber}</td>
                                                <td>{item?.type}</td>
                                                <td>{item?.detail}</td>
                                                <td
                                                    style={{
                                                        color: item?.status === "NEW" ? "green" : item?.status === "CLOSE" ? "red" : item?.status === "OPENED" ? "#816aae" : item?.status === "REJECT" ? "red" : ""
                                                    }}
                                                >
                                                    {item?.status}
                                                </td>
                                                <td>{formatDate(item?.updatedAt)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <div style={{ height: 40 }}></div> */}
                {/* <div style={{ paddingLeft: 40, paddingRight: 60 }}>
                    <div style={{ flexDirection: "row", display: "flex", }}>
                        <div className="shadowCSS col" style={{ padding: 10, borderRadius: 5 }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-9">Online Application submission status</div>
                                <a href="#" className="col-3 text-right" style={{ float: "right", color: "blue" }}>See All</a>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Pie data={onlineApplicationData} />
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#35E462" }} />  Success  {"400"}</div>
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#FF8373" }} />  Fail  {"50"} </div>
                            </div>
                        </div>
                        <div className="col-1">
                        </div>
                        <div className="shadowCSS col" style={{ padding: 10, borderRadius: 5 }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-9">The total payment online</div>
                                <a href="#" className="col-3 text-right" style={{ float: "right", color: "blue" }}>See All</a>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Pie data={paymentOnlineData} />
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#35E462" }} />  Success  {"250"}</div>
                                <div><FontAwesomeIcon icon={faDotCircle} style={{ fontSize: "15px", color: "#FF8373" }} />  Not success  {"150"}</div>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{ height: 40 }}></div>
                <div style={{ paddingLeft: 16 }}>
                    <div className="row" style={{ width: "100%" }}>
                        <div className="shadowCSS" style={{ padding: 30, paddingRight: 0, width: "48%" }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30, marginTop: -10}}>
                                <div className="col-9">List of dealers</div>
                            </div>
                            <div style={{ marginTop: 16, marginBottom: 20 }}>
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Types of dealer</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataDealer && dataDealer?.results?.length > 0 && dataDealer?.results?.map((shop, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{shop?.name ?? "-"}</td>
                                                    <td>{shop?.storecategory?.name ?? "-"}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                <a href="#" className="col-3" style={{ color: "blue" }} onClick={() => _shopList()}>See All</a>
                            </div>
                        </div>
                        <div style={{ width: "4%" }} />
                        <div className="shadowCSS" style={{ padding: 15, paddingLeft: 0, width: "48%" }}>
                            <div className="row" style={{ paddingLeft: 30, paddingRight: 30 }}>
                                <div className="col-9">Apply Loan Online Application</div>
                            </div>
                            <div style={{ marginTop: 20, marginBottom: 20 }}>
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">Type of customer</th>
                                            <th scope="col">Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Existing Customer (Member)</td>
                                            <td>2020/6/28 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>New Customer</td>
                                            <td>2020/6/28 17:00</td>
                                        </tr>
                                        <tr>
                                            <td>Existing Customer (Member)</td>
                                            <td>2020/6/28 17:00</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            {/* <div style={{ display: "flex", flexDirection: "column", width: "100%", textAlign: "center", justifyContent: "center", alignItems: "center" }}>
                                <a href="#" className="col-3" style={{ color: "blue" }} onClick={() => _historyPushCustomer()}>See All</a>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div style={{ height: 40 }}></div>
                <div style={{ }}>
                    <div className="shadowCSS" style={{ padding: 30 }}>
                        <div className="col-sm-12" style={{ marginTop: 20 }}>
                            <table className="table table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">No.</th>
                                        <th scope="col">Category</th>
                                        <th scope="col">Application (s)</th>
                                        <th scope="col">Update</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {storeCategories?.map((category, index) => {
                                        return (
                                            <tr key={index} onClick={() => _issueReportDetail(category?._id)}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{category?.name}</td>
                                                <td>0</td>
                                                <td>{formatDate(category?.updatedAt)}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}