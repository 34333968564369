import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Formik } from 'formik';
import axios from 'axios';
import { ADD_STAFFS, PRESIGNED_URL } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import Form from 'react-bootstrap/Form'
import { successAdd, errorAdd } from '../../../helper/sweetalert'
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    STAFF_LIST
} from '../../../consts/router'
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

export default function StaffAdd() {
    const { history, location, match } = useReactRouter()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    const classes = useStyles();
    const [state, setState] = React.useState({
        gilad: true,
        jason: false,
        antoine: false,
    });
    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.checked });
    };
    const { gilad, jason, antoine } = state;
    // history
    const _staffList = () => {
        history.push(`${STAFF_LIST}/limit/${LIMIT}/page/1`)
    }
    // upload pic
    const [namePhoto, setNamePhoto] = useState('')
    const [file, setFile] = useState()
    const [imageLoading, setImageLoading] = useState()
    const [genderData, setGenderData] = useState();
    const [statusData, setStatusData] = useState();
    const handleUpload = async (event) => {
        setImageLoading("")
        try {
            setFile(event.target.files[0]);
            let formData = new FormData();
            let fileData = event.target.files[0]
            const responseUrl = await axios({
                method: 'post',
                url: PRESIGNED_URL,
                headers: getTokken,
                data: {
                    type: event.target.files[0].type
                }
            })
            setNamePhoto(responseUrl.data)
            let afterUpload = await axios({
                method: "put",
                url: responseUrl.data.url,
                data: fileData,
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setImageLoading(percentCompleted)
                }
            })
        } catch (error) {
        }
    }
    // lung jak upload leo pic ja ma so u nee
    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} alt={image.name} style={{
            borderRadius: "50%",
            height: 300,
            width: 300,
        }} />;
    };
    // year
    let newYear = [];
    let year = new Date();
    for (let i = 1980; i < year.getFullYear() + 1; i++) {
        newYear.push(i)
    }
    // month
    let newMonth = [];
    for (let i = 1; i < 13; i++) {
        newMonth.push(i)
    }
    // date
    let newDate = [];
    for (let i = 1; i < 32; i++) {
        newDate.push(i)
    }
    const _addUser = async (values) => {
        const resData = await axios({
            method: 'POST',
            url: ADD_STAFFS,
            headers: getTokken,
            data: {
                email: values.email,
                password: values.password,
                firstName: values.firstName,
                lastName: values.lastName,
                gender: genderData,
                dateOffBirth: values.year + "-" + values.month + "-" + values.dayy,
                status: statusData,
                relegion: values.relegion,
                village: values.village,
                district: values.district,
                province: values.province,
                other: values.other,
                phoneNumber: values.phoneNumber,
                note: values.note,
                image: namePhoto.filename,
                role: values.role
            },
        }).then(function (response) {
            successAdd()
            setTimeout(() => {
                history.push(`${STAFF_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            if (error?.response?.data?.message === 'EMAIL_ALREADY_REGISTERED') {
                errorAdd('Email Already Registered')
            } else {
                errorAdd('Something went wrong, please try again !')
            }
        })
    }
    const handleChangeGender = async (gender) => {
        setGenderData(gender)
    }
    const handleChangeStatus = async (Status) => {
        setStatusData(Status)
    }

    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _staffList()}>All employees » </a> Add
            </h6>
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                    firstName: "",
                    lastName: "",
                    year: "",
                    month: "",
                    dayy: "",
                    status: "",
                    relegion: "",
                    village: "",
                    district: "",
                    province: "",
                    phoneNumber: "",
                    other: "",
                    note: "",
                }}
                validate={values => {
                }}
                onSubmit={(values, { }) => {
                    _addUser(values)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={BODY_DISCOUNT_SHOPDETAIL}>
                            <div style={{ padding: 20 }}>
                                <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Add staff</div>
                                <div className="row" style={{ padding: 20 }}>
                                    <div className="col-md-4 center">
                                        <input type="file" id="file-upload" onChange={handleUpload} hidden />
                                        <label for="file-upload">
                                            <div style={{
                                                backgroundColor: "#E4E4E4E4",
                                                height: 300,
                                                width: 300,
                                                borderRadius: "50%",
                                                cursor: "pointer",
                                                display: "flex"
                                            }}>
                                                {file ? <ImageThumb image={file} /> : <div style={{
                                                    display: "flex", height: 300,
                                                    width: 300, justifyContent: "center", alignItems: "center"
                                                }}>
                                                    <p style={{ color: "#fff", fontSize: 80, fontWeight: "bold" }}>+</p></div>}
                                            </div>
                                        </label>
                                        {/* progass */}
                                        {imageLoading ? <div className="progress" style={{ height: 20 }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: `${imageLoading}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoading} aria-valuemin="0" aria-valuemax="100">{imageLoading}%</div>
                                        </div> : <div style={{ height: 20 }} />}
                                    </div>
                                    <div className="col-md-8">
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div className="col-6">
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div style={{ fontWeight: "bold" }}>Name</div>
                                                    <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                </div>
                                                <input className="form-control btn-block"
                                                    name="firstName"
                                                    onChange={handleChange}
                                                    value={values.firstName}

                                                />
                                            </div>
                                            <div className="col-6">
                                                <div style={{ flexDirection: "row", display: "flex" }}>
                                                    <div style={{ fontWeight: "bold" }}>Last Name</div>
                                                    <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                </div>
                                                <input className="form-control btn-block"
                                                    name="lastName"
                                                    onChange={handleChange}
                                                    value={values.lastName}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ paddingTop: 20 }}></div>
                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                            <div style={{ fontWeight: "bold" }}>Year of birth</div>
                                            <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-4">
                                                <Form.Control as="select"
                                                    name="year"
                                                    onChange={handleChange}
                                                    value={values.year}
                                                    className="col-12">
                                                    <option>Years</option>
                                                    {newYear?.map((data, index) => {
                                                        return (
                                                            <option value={data}>{data}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </div>
                                            <div className="col-4">
                                                <Form.Control as="select"
                                                    name="month"
                                                    onChange={handleChange}
                                                    value={values.month}
                                                    className="col-12">
                                                    <option>Months</option>
                                                    {newMonth?.map((data, index) => {
                                                        return (
                                                            <option value={data}>{data}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </div>
                                            <div className="col-4">
                                                <Form.Control as="select"
                                                    name="dayy"
                                                    onChange={handleChange}
                                                    value={values.dayy}
                                                    className="col-12">
                                                    <option>Date</option>
                                                    {newDate?.map((data, index) => {
                                                        return (
                                                            <option value={data}>{data}</option>
                                                        )
                                                    })}
                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Gender</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-md-8" style={{ bottom: 10 }}>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline1" name="gender" className="custom-control-input"
                                                        onChange={() => handleChangeGender("MALE")}
                                                    />
                                                    <label className="custom-control-label" for="customRadioInline1">Male</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline2" name="gender" className="custom-control-input" onChange={() => handleChangeGender("FEMALE")} />
                                                    <label className="custom-control-label" for="customRadioInline2">female</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline3" name="gender" className="custom-control-input" onChange={() => handleChangeGender("OTHER")} />
                                                    <label className="custom-control-label" for="customRadioInline3">Other</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Status</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-md-8" style={{ bottom: 10 }}>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline4" name="status" className="custom-control-input"
                                                        onChange={() => handleChangeStatus("single")}
                                                    />
                                                    <label className="custom-control-label" for="customRadioInline4">Single</label>
                                                </div>
                                                <div className="custom-control custom-radio custom-control-inline">
                                                    <input type="radio" id="customRadioInline5" name="status" className="custom-control-input" onChange={() => handleChangeStatus("married")} />
                                                    <label className="custom-control-label" for="customRadioInline5">Married</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Religion</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="text"
                                                    name="relegion"
                                                    onChange={handleChange}
                                                    value={values.relegion}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>System information</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Email</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    value={values.email}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Password</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div style={{ height: 10 }}></div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    value={values.password}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Role</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div style={{ height: 10 }}></div>
                                            <div className="col-12">
                                                <Form.Control as="select"
                                                    name="role"
                                                    onChange={handleChange}
                                                    value={values.role}
                                                    className="col-12">
                                                    <option selected={true} disabled={true}>Select System Rights</option>
                                                    <option value="ADMIN">ADMIN</option>
                                                    <option value="EDITOR">EDITOR</option>
                                                </Form.Control>
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Address</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Province</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="text"
                                                    name="province"
                                                    onChange={handleChange}
                                                    value={values.province}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>District</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="text"
                                                    name="district"
                                                    onChange={handleChange}
                                                    value={values.district}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>village</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="text"
                                                    name="village"
                                                    onChange={handleChange}
                                                    value={values.village}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Other</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="text"
                                                    name="other"
                                                    onChange={handleChange}
                                                    value={values.other}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Contact information</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Phone number</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="number"
                                                    name="phoneNumber"
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Other</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Note</div>
                                            </div>
                                            <div className="col-12">
                                                <textarea id="w3review" rows="4" cols="50" className="form-control col-12"
                                                    name="note"
                                                    onChange={handleChange}
                                                    value={values.note}
                                                >
                                                </textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row center">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _staffList()}><i className="fas fa-pencil-alt"></i> Cancel</button>
                                        <button type="submit" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>Confirm</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
