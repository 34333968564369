import React, { useState, useEffect } from "react";
import queryString from "query-string";
import "../utils/index.css";
import useReactRouter from "use-react-router";
import profileImage from "../../../image/profile.png";
import Image from "react-bootstrap/Image";
import AnimationLoading from "../../../helper/loading.js";
import { TITLE_HEADER, HEADER, BODY, LIMIT } from "../../../consts";
import {
  PROMOTION_ADD,
  PROMOTION_LIST,
  PROMOTION_DETAIL,
  PROMOTION_POPUP_LIST,
  NOTIFY_LIST,
} from "../../../consts/router";
import { URL_PHOTO_AW3, PROMOTION_DATA_LIST, NO } from "../../../consts/api";
import { getLocalData } from "../../../consts/tokken";
import { formatDate, formatDateDash } from "../../../common/super";
import moment from "moment";
export default function PromotionList() {
  // ==== rout
  const { history, location, match } = useReactRouter();
  const _limit = match.params.limit;
  const _page = match.params.page;
  const parsed = queryString.parse(location.search);
  // ==== state
  const [isLoading, setIsLoading] = useState(false);
  const [promotionList, setPromotionList] = useState();
  const [FiltterData, setFiltterData] = useState(parsed?.title);

  const path = require("path");

  const promotionAdd_page = () => {
    history.push(`${PROMOTION_ADD}`);
  };
  const _promotionDetail = (id) => {
    history.push(`${PROMOTION_DETAIL}${id}`);
  };
  const _popUpList = () => {
    history.push(`${PROMOTION_POPUP_LIST}`);
  };
  const _noticeList = () => {
    history.push(`${NOTIFY_LIST}/limit/${LIMIT}/page/1/`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, [FiltterData]);

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, []);

  const getData = async (tokken) => {
    setIsLoading(true);
    await fetch(
      PROMOTION_DATA_LIST +
        `limit/${_limit}/page/${_page}/${
          FiltterData ? `?title=${FiltterData}` : ""
        }`,
      {
        method: "GET",
        headers: tokken,
      }
    )
      .then((response) => response.json())
      .then((json) => setPromotionList(json));
    setIsLoading(false);
  };

  const _statusPromotion = (status, start, end) => {
    var now = moment(new Date()).unix(); //todays date
    var startDate = moment(start).add(-1, "days").unix(); //start date
    var endDate = moment(end).unix(); // another date
    if (now > startDate && now < endDate && status === "OPEN") {
      return (
        <div
          style={{
            border: "solid 2px #0660D4",
            borderRadius: 50,
            width: 80,
            textAlign: "center",
            color: "#0660D4",
          }}
        >
          OPEN
        </div>
      );
    } else {
      return (
        <div
          style={{
            border: "solid 2px red",
            borderRadius: 50,
            width: 80,
            textAlign: "center",
            color: "red",
          }}
        >
          CLOSE
        </div>
      );
    }
  };
  const _filtter = (e) => {
    setFiltterData(e.target.value.trim());
  };
  // pagination
  useEffect(() => {
    if (promotionList?.total > 0) {
      _getArrayPageNumber();
    }
  }, [promotionList?.total]);
  const [totalPage, setTotalPage] = useState([]);

  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (promotionList?.total % parseInt(_limit) !== 0) {
      _total = parseInt(promotionList?.total) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(promotionList?.total) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };

  const _nextPage = (page) => {
    history.push(
      `${PROMOTION_LIST}/limit/${_limit}/page/${page}/?${
        FiltterData ? `title=${FiltterData}` : ""
      }`
    );
  };
  // console.log("promotionList?.result", promotionList?.result);
  return (
    <div style={TITLE_HEADER}>
      <div style={HEADER}>
        <div style={{ paddingBottom: 10 }}> Promotion </div>
      </div>
      <ul
        className="nav nav-tabs"
        id="myTab"
        style={{ width: "95%", marginLeft: "30px" }}
      >
        <li className="nav-item">
          <a
            className="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Promotion
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            onClick={() => _popUpList()}
          >
            Pop up
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            onClick={() => _noticeList()}
          >
            Notify
          </a>
        </li>
      </ul>
      <div style={BODY}>
        <div className="tab-content" id="myTabContent">
          {/* ໂປຣໂມຊັນ */}
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="row">
              <div className="col-md-8">
                <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>
                  {" "}
                  All Promotion ({promotionList?.total})
                </div>
              </div>
              <div className="col-md-4">
                <div style={{ fontWeight: "bold", paddingBottom: 10 }}>
                  Search
                </div>
                <input
                  type="text"
                  placeholder="Search subject..."
                  value={FiltterData}
                  onChange={(e) => _filtter(e)}
                  className="form-control"
                />
              </div>
            </div>
            <div style={{ height: 10 }}></div>
            <div className="right">
              <button
                type="button"
                className="btn btn-secondary"
                style={{ backgroundColor: "#816AAE" }}
                onClick={() => promotionAdd_page()}
              >
                Add promotion
              </button>
            </div>
            <div style={{ height: 50 }}></div>
            {isLoading ? (
              <AnimationLoading />
            ) : (
              <div>
                <div className="col-sm-12">
                  <table className="table table-hover">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Photo</th>
                        <th scope="col">Subject</th>
                        <th scope="col">Duration</th>
                        <th scope="col">Status</th>
                        <th scope="col">create</th>
                      </tr>
                    </thead>
                    {promotionList?.result?.length > 0 ? (
                      <tbody>
                        {promotionList?.result?.map((item, index) => {
                          return (
                            <tr
                              key={index}
                              onClick={() => _promotionDetail(item?._id)}
                            >
                              <th scope="row">
                                {index +
                                  1 +
                                  parseInt(match?.params?.limit) *
                                    parseInt(match?.params?.page - 1)}
                              </th>
                              <td>
                                {item?.image ? (
                                  <Image
                                    src={URL_PHOTO_AW3 + item?.image}
                                    alt="AeonIcon"
                                    width="150"
                                    height="150"
                                    style={{
                                      height: 50,
                                      width: 50,
                                      borderRadius: 5,
                                    }}
                                  />
                                ) : (
                                  <Image
                                    src={profileImage}
                                    alt="AeonIcon"
                                    width="150"
                                    height="150"
                                    style={{
                                      height: 50,
                                      width: 50,
                                      borderRadius: 5,
                                    }}
                                  />
                                )}
                              </td>
                              <td>{item?.title}</td>
                              <td>
                                {formatDate(item?.startDate)} ~{" "}
                                {formatDate(item?.endDate)}{" "}
                              </td>
                              <td>
                                {_statusPromotion(
                                  item?.status,
                                  item?.startDate,
                                  item?.endDate
                                )}
                              </td>
                              <td>{formatDate(item?.createdAt)}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <tbody
                        style={{
                          position: "relative",
                          height: "280px",
                          backgroundColor: "#FFFFFF",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            backgroundColor: "#FFFFFF",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              marginTop: "36px",
                            }}
                          >
                            <img
                              src={path.join(__dirname, "no_data.svg")}
                              alt="No Data"
                              style={{
                                width: "160px",
                              }}
                              draggable={false}
                            />
                            <p
                              style={{
                                fontSize: "18px",
                                fontWeight: 500,
                                opacity: 0.8,
                                marginTop: "16px",
                              }}
                            >
                              ບໍ່ມີຂໍ້ມູນ
                            </p>
                          </div>
                        </div>
                      </tbody>
                    )}
                  </table>
                  {promotionList?.result?.length > 0 && (
                    <div className="row center">
                      {totalPage?.map((item, index) => {
                        return (
                          <button
                            style={{
                              width: 30,
                              height: 30,
                              border: "solid 1px #816aae",
                              marginLeft: 2,
                              backgroundColor:
                                parseInt(_page) === index + 1
                                  ? "#9999ff"
                                  : "#fff",
                              color:
                                parseInt(_page) === index + 1 ? "#fff" : "#000",
                            }}
                            onClick={() => _nextPage(item)}
                            key={item}
                          >
                            {item}
                          </button>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
