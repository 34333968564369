import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import moment from 'moment';
import { Button, Form, Modal } from "react-bootstrap";
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST2,
	BODY_DEALERLIST,
	// LIMIT,
	COLOR_PRIMARY
} from "../../../consts"
import { GET_ALL_DEALER_PRODUCT, PRODUCT_BRAND, PRODUCT_MODEL, SETTING_STORE_CATEGORY } from '../../../consts/api'
import { PRODUCT } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import "./style.css";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { downloadExcel } from "../../../helper/exportToExcel.js";
import ReactPaginate from "react-paginate";
import axios from "axios";


export default function ProductList() {
	const { history, match } = useReactRouter()
	const _limit = match.params.limit;
	const _page = match.params.page;
	// const newDate = new Date();
	const [togglePage, setTogglePage] = useState('');
	const [isLoading, setIsLoading] = useState(false)

	// ====state_fillter ===
	const [categoryId, setCategoryId] = useState("")

	const [brandId, setBrandId] = useState('')
	const [brands, setBrands] = useState([])

	const [modelId, setModelId] = useState('')
	// const [modelName, setModelName] = useState('')
	const [models, setModels] = useState([])

	// const [startDate, setstartDate] = useState(moment(moment().subtract(90, 'days')).format("YYYY-MM-DD"))
	// const [endDate, setendDate] = useState(moment(moment(newDate)).format("YYYY-MM-DD"))
	const [startDate, setstartDate] = useState();
	const [endDate, setendDate] = useState();

	const [isDuplicateShow, setisDuplicateShow] = useState(false)
	const [currentProductId, setCurrentProductId] = useState(null)

	const [products, setProducts] = useState([]);
	const [exportExcelData, setExportExcelData] = useState();
	const [totalPage, setTotalPage] = useState([]);
	const [totalItems, setTotalItems] = useState(0)
	const [category, setCategory] = useState([])

	const [getTokken, setgetTokken] = useState("")
	const [pageNumber, setPageNumber] = useState(_page ?? 1);
	// const [shopExcelData, setShopExcelData] = useState()
	const [checkFilter, setCheckFilter] = useState(false)

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductDatas(_localData);
				fetchExcelExportData(_localData);
				fetchCategory(_localData);
			}
		}
		fetchData();
	}, [])

	useEffect(() => {
		getProductByFilter()
		fetchExcelExportData()
	}, [categoryId, brandId, modelId, startDate, endDate])

	useEffect(() => {
		getProductByFilter()
	}, [pageNumber, checkFilter]);



	const getProductByFilter = async () => {

		try {
			setIsLoading(true)
			let url;
			if (categoryId && !brandId && !modelId) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&productCategory=${categoryId}`
			} else if (brandId && categoryId && !modelId) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&brand=${brandId}&productCategory=${categoryId}`
			} else if (modelId && categoryId && !brandId) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&model=${modelId}&productCategory=${categoryId}`
			}
			else if (categoryId && brandId && modelId) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&productCategory=${categoryId}&brand=${brandId}&model=${modelId}`
			} else if (!categoryId && !brandId && startDate || endDate) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&startDate=${moment(startDate).format("YYYY/MM/DD")}&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY/MM/DD")}`
			} else if (!categoryId && !modelId && startDate || endDate) {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED&startDate=${moment(startDate).format("YYYY/MM/DD")}&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY/MM/DD")}`
			} else {
				url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED`
			}
			if (checkFilter) url += `&checkFilter=${checkFilter}`
			const data = await axios.get(url);
			if (data?.status >= 300) return { error: true, msg: "NOT_FOUND" }
			if (data?.data) {
				setTotalItems(data?.data?.total)
				setProducts(data?.data?.result)
				setTotalPage(parseInt(data?.data?.total / _limit) + 1);
			}
			setIsLoading(false)
		} catch (error) {
			console.log(error)
			setIsLoading(false)
		}
	}

	const fetchCategory = async (token) => {
		try {
			setIsLoading(true);
			const _categories = await axios(
				SETTING_STORE_CATEGORY,
				{
					method: 'GET',
					headers: token,
				})

			await setCategory(_categories.data)
			setIsLoading(false);
		} catch (err) {
			console.log("err: ", err);
			setIsLoading(false);
		}
	};

	const fetchExcelExportData = async () => {
		try {
			setIsLoading(true)
			var data
			if (categoryId && !brandId && !modelId) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&productCategory=${categoryId}
            `)
			} else if (brandId && categoryId && !modelId) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&brand=${brandId}&productCategory=${categoryId}
            `)
			} else if (modelId && categoryId && !brandId) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&model=${modelId}&productCategory=${categoryId}
            `)
			}
			else if (categoryId && brandId && modelId) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&productCategory=${categoryId}&brand=${brandId}&model=${modelId}
            `)
			} else if (!categoryId && !brandId && startDate || endDate) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&startDate=${moment(startDate).format("YYYY/MM/DD")}&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY/MM/DD")}
            `)
			} else if (!categoryId && !modelId && startDate || endDate) {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED&startDate=${moment(startDate).format("YYYY/MM/DD")}&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY/MM/DD")}
				`)
			} else {
				data = await axios.get(`
				${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${pageNumber}?status=APPROVED
				`)
			}
			if (data?.status >= 300) return { error: true, msg: "NOT_FOUND" }
			if (data?.data) {
				setExportExcelData(data?.data?.result)
				// const filteredData = filterShops(data?.data?.result);
				// setShopExcelData(filteredData)
			}
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
		}
	}

	// const filterShops = (data) => {
	// 	const uniqueShops = new Map();
	// 	data.forEach(item => {
	// 		const shopId = item.shopPortal?._id;
	// 		if (shopId && !uniqueShops.has(shopId)) {
	// 			uniqueShops.set(shopId, item);
	// 		}
	// 	});
	// 	return Array.from(uniqueShops.values());
	// };


	const getProductDatas = async (token) => {
		try {
			try {
				setIsLoading(true)
				const data = await axios.get(`
			 ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=APPROVED`)
			 console.log("data: ",data?.data)
				if (data?.status >= 300) return { error: true, msg: "NOT_FOUND" }
				if (data?.data) {
					setTotalItems(data?.data?.total)
					setProducts(data?.data?.result)
					setTotalPage(parseInt(data?.data?.total / _limit) + 1);
				}
				setIsLoading(false)
			} catch (error) {
				setIsLoading(false)
				console.log(error)
			}
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}


	const exportToExcel = async () => {
		if (exportExcelData) {
			const rows = await exportExcelData?.map((item, index) => ({
				index: index + 1,
				"productCategory": item?.productCategory?.name ?? "",
				"brand": item?.brand?.name ?? "",
				"description": item?.model?.name ?? "",
				"createdAt": moment(item?.createdAt).format('DD/MM/YYYY') ?? "",
				"shopName": item?.shopPortal?.shop?.name ?? "",
				"storecd": item?.shopPortal?.shop?.storecd ?? "",
				"telno": item?.shopPortal?.shop?.telno ?? "",
			}));

			const titles = ["NO.",
				"Product Category",
				"Brand",
				"Product Description",
				"Created Date",
				"ShopName",
				"Storecd",
				"Telno"
			]
			const fileName = `Product-${moment().format("DDMMYYYYHHmmss")}.xlsx`
			console.log("come here der")
			await downloadExcel(titles, rows, fileName)
		}

	}

	// const exportShopDataToExcel = async () => {
	// 	if (shopExcelData) {
	// 		const rows = await shopExcelData?.map((item, index) => ({
	// 			index: index + 1,
	// 			"name": item?.shopPortal?.shop?.name ?? "",
	// 			"storecd": item?.shopPortal?.shop?.storecd ?? "",
	// 			"telno": item?.shopPortal?.shop?.telno ?? "",
	// 		}));

	// 		const titles = ["NO.",
	// 			"ShopName",
	// 			"Storecd",
	// 			"Telno"
	// 		]
	// 		const fileName = `Shop-${moment().format("DDMMYYYYHHmmss")}.xlsx`
	// 		await downloadExcel(titles, rows, fileName)
	// 	}

	// }

	const onChoosePage = (envent) => {
		setPageNumber(parseInt(envent.selected + 1));
	};

	const handleChangeCategory = async (id) => {
		if (id) {
			getBrand(id);
			getModel(id);
		}
		setCategoryId(id);
		setModelId("")
		setModels("")
		setBrands("")
		setBrandId("");
	}
	const getBrand = async (category) => {
		try {
			await fetch(`${PRODUCT_BRAND}/?${category ? `category=${category}` : ''}`, {
				method: "GET",
				headers: getTokken
			}).then(response => response.json()).then(json => {
				setBrands(json ?? [])
			});
		} catch (error) {
			console.log("error: ", error)
		}
	}
	const getModel = async (category) => {
		try {
			await fetch(`${PRODUCT_MODEL}/?${category ? `category=${category}` : ''}`, {
				method: "GET",
				headers: getTokken
			}).then(response => response.json()).then(json => {
				setModels(json ?? [])
			});
		} catch (error) {
			console.log("error: ", error)
		}
	}
	const _startDate = (e) => {
		setstartDate(e?.target?.value)
	}
	const _endDate = (e) => {
		setendDate(e?.target?.value)
	}
	const goToDetail = (id) => {
		history.push(`${PRODUCT}/detail/${id}`)
	}
	const duplicate = () => {
		history.push(`${PRODUCT}/duplicate/${currentProductId}`)
	}
	// const showDuplicateModal = (e, id) => {
	// 	e.stopPropagation()
	// 	setCurrentProductId(id)
	// 	setisDuplicateShow(true)
	// }


	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p>Product Template Management</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: 200, paddingTop: 24 }}>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row">
							<div className="col-md-4">
								<span style={{ fontWeight: "bold" }}>Product Category</span>
								<select
									name="productCategory"
									className="btn-block form-control"
									onChange={(e) => handleChangeCategory(e.target.value)}
									value={categoryId}
								>
									{/* <option value="">Please select</option> */}
									<option value="">all</option>
									{category?.length && category?.map((item, index) =>
										<option key={index} value={item?._id}>{item?.name}</option>
									)}
								</select>
							</div>
							<div className="col-md-4">
								<span style={{ fontWeight: "bold" }}>Brand</span>
								<select
									name="brand"
									className="btn-block form-control"
									onChange={(e) => setBrandId(e.target.value)}
									value={brandId}
								>
									<option value="">all</option>
									{brands?.length > 0 && brands.map((item, index) =>
										<option key={index} value={item?._id}>{item?.name}</option>
									)}
								</select>
							</div>
							<div className="col-md-4">
								<span style={{ fontWeight: "bold" }}>Product Description</span>
								<select
									name="model"
									className="btn-block form-control"
									onChange={(e) => setModelId(e.target.value)}
									value={modelId}
								>
									<option value="">all</option>
									{models?.length > 0 && models?.map((item, index) =>
										<option key={index} value={item?._id}>{item?.name}</option>
									)}
								</select>
							</div>
							{/* <div className="col-md-3"></div> */}
						</div>
						<div className="row" style={{ paddingTop: 24 }}>
							<div className="col-md-4">
								<span style={{ fontWeight: "bold" }}>Created date from</span>
								<input type="date" className="form-control col-12" value={startDate} onChange={(e) => _startDate(e)} />
							</div>
							<div className="col-md-4">
								<span style={{ fontWeight: "bold" }}>Created date to</span>
								<input type="date" className="form-control col-12" value={endDate} onChange={(e) => _endDate(e)} />
							</div>
							<div className="col-md-4">
								<Form.Check
									type="checkbox"
									label="Filter new product"
									id="checkbox-id"
									value={checkFilter}
									onChange={(e) => setCheckFilter(!checkFilter)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="flex" style={{ ...BODY_DEALERLIST2, paddingTop: 24 }}>
				<div style={{ display: togglePage, padding: 20, paddingTop: 0 }}>
					<div className="flex" style={{ marginBottom: 16 }}>
						<div style={{ display: "flex", justifyContent: 'space-between' }}>
							<div>All Products ({totalItems ?? 0})</div>
							<div style={{ display: "flex" }}>
								{/* <button type="button" onClick={() => goToAddProduct()} className="product-list-button-add"><HiPlusCircle size="33" style={{ paddingRight: 10 }} />  Add Product</button> */}

								{/* <ExcelButton name="Products" /> */}
								<div style={{ marginLeft: 10, display: "flex" }}>
									{/* <button
										className="btn"
										style={{
											border: `1px solid ${COLOR_PRIMARY}`,
											backgroundColor: "white",
											color: COLOR_PRIMARY
										}}
										onClick={() => exportShopDataToExcel()}
									>
										<FontAwesomeIcon icon={faFileExcel} color={COLOR_PRIMARY} />
										<span style={{ marginLeft: 10 }}>Download Shop</span>
									</button>
									<div style={{ width: 20 }} /> */}
									<button
										className="btn"
										style={{
											border: `1px solid ${COLOR_PRIMARY}`,
											backgroundColor: "white",
											color: COLOR_PRIMARY
										}}
										onClick={() => exportToExcel()}
									>
										<FontAwesomeIcon icon={faFileExcel} color={COLOR_PRIMARY} />
										<span style={{ marginLeft: 10 }}>Download Excel</span>
									</button>
								</div>
							</div>


						</div>
					</div>
					{isLoading ? <AnimationLoading /> : <div>
						<div className="col-sm-12">
							<table className="table table-hover" id="table-to-xls">
								<thead className="thead-light">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Product Category</th>
										<th scope="col">Brand</th>
										<th scope="col">Product Description</th>
										<th scope="col">Model</th>
										<th scope="col">Created Date</th>
										{/* <th scope="col">Action</th> */}
									</tr>
								</thead>
								<tbody>
									{products && products?.map((item, index) => {
										// let _description = JSON.parse(item?.description)
										return <tr key={index} onClick={() => goToDetail(item?._id)}>
											<td>{index + 1 + parseInt(_limit) * parseInt(pageNumber - 1)}</td>
											<td>{item?.productCategory?.name ?? "-"}</td>
											<td>{item?.brand?.name ?? "-"}</td>
											<td style={{ width: 250 }}>{item?.description ?? "-"}</td>
											<td>{item?.model?.name ?? "-"}</td>
											{/* <td>{_description.map((item0, index0) => {
												return <span key={index0}><span style={{ fontWeight: "bold" }}>{item0?.name}</span>: {item0?.values?.map((item2, index2) => item2 + (item0?.values.length != index2 + 1 ? ", " : ""))}{(_description.length != index0 + 1 ? ";  " : "")}</span>
											})}</td> */}
											<td>{formatDate(item?.createdAt)}</td>
											{/* <td>
												<button onClick={(e) => showDuplicateModal(e, item._id)} type="button" className="btn btn-secondary" style={{ backgroundColor: "#816AAE", height: 30, padding: 0, paddingLeft: 16, paddingRight: 16 }}>Duplicate</button>
											</td> */}
										</tr>
									})}
								</tbody>
							</table>
							<div className="row center">
								<ReactPaginate
									previousLabel="back"
									onPageChange={onChoosePage}
									breakLabel="..."
									pageRangeDisplayed={5}
									pageCount={Math.ceil(totalPage)}
									// pageRangeDisplayed={7}
									marginPagesDisplayed={3}
									renderOnZeroPageCount={null}
									containerClassName={"pagination justify-content-center"}
									pageClassName={"page-item"}
									pageLinkClassName={"page-link"}
									previousClassName={"page-item"}
									previousLinkClassName={"page-link"}
									nextClassName={"page-item"}
									nextLinkClassName={"page-link"}
									breakClassName={"page-item"}
									breakLinkClassName={"page-link"}
									activeClassName={"active"}
									forcePage={pageNumber - 1}
									nextLabel="next"
								/>

							</div>
						</div>
					</div>
					}
				</div>
			</div>
			<Modal centered show={isDuplicateShow} onHide={() => setisDuplicateShow(false)}>
				<Modal.Body >
					<div style={{ padding: 10 }}>
						<div style={{ fontWeight: "bold" }}>Does duplicate confirm?</div>
						<hr />
						<p>Do you really want to duplicate this template?</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={() => setisDuplicateShow(false)}>
						Cancel
					</Button>
					<Button style={{ backgroundColor: "#816AAE", borderColor: "#816AAE" }} onClick={() => duplicate()}>Confirm</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
