import moment from "moment";
import React from "react"
export const gender = (MF) => {
    if (MF === 'MALE') {
        return "ຊາຍ";
    } else if (MF === 'FEMALE') {
        return "ຍິງ";
    } else if (MF === 'OTHER') {
        return "ອື່ນໆ";
    }
}
export const stutus = (MF) => {
    if (MF === 'married') {
        return "ແຕ່ງງານແລ້ວ";
    } else if (MF === 'single') {
        return "ໂສດ";
    }
}

export const thousand = num => num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0";

// ກຳນົດ ວັນທີປັດຈຸບັນ(ພາສາລາວ)
export const formatDate = (dateTime) => {
    moment.locale("lo");
    let resp = moment(dateTime).format("DD MMMM YYYY");
    if (dateTime) return resp;
    else return ""
  };