import React, { useEffect, useState } from 'react'
import useReactRouter from "use-react-router"
import MapContainer from "../../../consts/locationMap"
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import { CUSTOMMER_LIST, CUSTOMMER_DETAIL, CUSTOMMER_HISTORY } from '../../../consts/router'
import { USERS, HISTORY_LOGIN_LOCATION } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'
import { formatDateTime } from '../../../common/super'

export default function CustomerHistory() {
    const { history, location, match } = useReactRouter()
    const parsed = queryString.parse(location.search);
    const _limit = match.params.limit;
    const _page = match.params.page;
    const _customerList = () => {
        history.push(`${CUSTOMMER_LIST}/limit/${LIMIT}/page/1`)
    }
    const _customerDetail = () => {
        history.push(`${CUSTOMMER_DETAIL}${match?.params?.id}`, match?.params?.id);
    }
    const newDate = new Date();
    const [dataCustomer, setDataCustomer] = useState()
    const [getDataTokken, setgetDataTokken] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    const [historyLocation, setHistoryLocation] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetDataTokken(_localData)
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                const fetchData = async () => {
                    const _localData = await getLocalData()
                    if (_localData) {
                        getData(_localData)
                        setgetDataTokken(_localData)
                    }
                }
                fetchData();
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                const fetchData = async () => {
                    const _localData = await getLocalData()
                    if (_localData) {
                        getData(_localData)
                        setgetDataTokken(_localData)
                    }
                }
                fetchData();
            }
        }
        fetchData();
    }, [endDate && startDate])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(USERS + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setDataCustomer(json));
        setIsLoading(false)
    }
    // ======== google_map =======>
    useEffect(() => {
        if (dataCustomer?.userauth?._id) {
            fetch(HISTORY_LOGIN_LOCATION + "/" + dataCustomer?.userauth?._id + "/limit/" + match?.params?.limit + "/page/" + match?.params?.page + `${startDate && endDate ? `/?startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
                method: "GET",
                headers: getDataTokken
            }).then(response => response.json())
                .then(json => setHistoryLocation(json));
        }
    }, [dataCustomer])
    const [latData, setLatData] = useState()
    const [lngData, setLngData] = useState()
    const [dateTime, setDateTime] = useState()
    useEffect(() => {
        if (historyLocation?.userLogIn[0]) {
            setLatData(historyLocation?.userLogIn[0]?.lat)
            setLngData(historyLocation?.userLogIn[0]?.lng)
            setDateTime(historyLocation?.userLogIn[0]?.createdAt)
        }
        _getArrayPageNumber()
    }, [historyLocation])
    // =============++++++ checkout +++++++===========+>>>>
    const _onchangLocation = (newLat, newLng, date) => {
        setLatData(newLat)
        setLngData(newLng)
        setDateTime(date)
    }
    // ====== pagination ===========>>>
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (historyLocation?.total % parseInt(match?.params?.limit) != 0) {
            _total = (parseInt(historyLocation?.total) / parseInt(match?.params?.limit)) + 1;
        } else {
            _total = parseInt(historyLocation?.total) / parseInt(match?.params?.limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${CUSTOMMER_HISTORY}${match?.params?.id}/limit/${match?.params?.limit}/page/${page}/?${startDate && endDate ? `startDate=${startDate}&&endDate=${endDate}` : ''}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <div style={HEADER}>
                <a href="" style={{ paddingBottom: 10 }} onClick={() => _customerList()}> Customer »</a> {dataCustomer?.localfirstname ? dataCustomer?.localfirstname : "-"}
            </div>
            <div style={{ height: 10 }}></div>
            <ul className="nav nav-tabs" id="myTab" style={{ width: "95%", marginLeft: "30px", border: "solid 0px #FFFF" }}>
                <li className="nav-item">
                    <a className="nav-link" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" onClick={() => _customerDetail()} style={{ cursor: "pointer", color: "blue" }}>Information</a>
                </li>
                <li className="nav-item" style={{ border: "solid 0px #FFFF" }}>
                    <a className="nav-link active" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false" style={{ border: "solid 0px #FFFF" }}>Location History</a>
                </li>
            </ul>
            <div style={BODY}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row">
                            <div className="col-md-6" >
                                <div style={{ paddingBottom: 30, fontSize: "20px", color: "#707070", fontWeight: "bold" }}>Location History</div>
                                <div className="row">
                                    <div className="col-md-5">
                                        <p>Date from</p>
                                        <input type="date" className="col-md-12 form-control" value={startDate} onChange={(e) => _startDate(e)} />
                                    </div>
                                    <div className="col-md-1"></div>
                                    <div className="col-md-5">
                                        <p>Date to</p>
                                        <input type="date" className="col-md-12 form-control" value={endDate} onChange={(e) => _endDate(e)} />
                                    </div>
                                </div>
                                <div style={{ height: 20 }}></div>
                                <p className="left" style={{ color: "#5C5C5C" }}>{_page} - {historyLocation?.total}</p>
                                {isLoading ? <AnimationLoading /> :
                                    <table className="table table-hover">
                                        <thead className="thead-light">
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Date&Time</th>
                                                <th scope="col">Latitude & Longitude</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {historyLocation?.userLogIn?.map((item, index) => {
                                                return (
                                                    <tr onClick={() => _onchangLocation(item?.lat, item?.lng, item?.createdAt)}>
                                                        <th scope="row">{index + 1 + parseInt(match?.params?.limit) * parseInt(match?.params?.page - 1)}</th>
                                                        <td>{formatDateTime(item?.createdAt)}</td>
                                                        <td>({item?.lat}),({item?.lng})</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                }
                                <div className="row center">
                                    {totalPage?.map((item, index) => {
                                        return (
                                            <button style={{
                                                width: 30,
                                                height: 30,
                                                border: "solid 1px #816aae",
                                                marginLeft: 2,
                                                backgroundColor: parseInt(match?.params?.page) == index + 1 ? "#9999ff" : "#fff",
                                                color: parseInt(match?.params?.page) == index + 1 ? "#fff" : "#000",
                                            }} onClick={() => _nextPage(item)}
                                                key={item}
                                            >{item}</button>
                                        )
                                    })}
                                </div>
                            </div>
                            <div style={{ width: 10, backgroundColor: "#E4E4E4" }}></div>
                            <div className="col-md-5">
                                <div style={{ paddingBottom: 30, fontSize: "20px", color: "#707070", fontWeight: "bold" }}>Google Map</div>
                                <div className="row">
                                    <div className="col-5">
                                        <p style={{ color: "#909090" }}>Date&Time</p>
                                    </div>
                                    <div className="col-7">
                                        <p>{formatDateTime(dateTime)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-5">
                                        <p style={{ color: "#909090" }}>Latitude & Longitude</p>
                                    </div>
                                    <div className="col-7">
                                        <p>({latData}),({lngData})</p>
                                    </div>
                                </div>
                                <div className="row">
                                </div>
                                <MapContainer
                                    lat={latData}
                                    lng={lngData}
                                    width="119%"
                                    height="100vh"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}