import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import ImageLogo from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import Form from 'react-bootstrap/Form'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import AnimationLoading from "../../../helper/loading.js"
import { Formik } from 'formik';
import axios from 'axios';
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    SETTING_LIST,
    CACULARTOR_LIST,
    CACULARTOR_DETAIL
} from '../../../consts/router'
import { URL_PHOTO_AW3, SETTING_STORE_CATEGORY, PRESIGNED_URL } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { successUpdate, errorAdd } from '../../../helper/sweetalert'

export default function SettingCategoryEdit() {
    const { history, location, match } = useReactRouter()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                setgetTokken(_localData)
            }
            getData();
        }
        fetchData();
    }, [])
    const _caculartor = () => {
        history.push(`${SETTING_LIST}`)
    }
    const _ConfigCaculartor = () => {
        history.push(`${CACULARTOR_LIST}/limit/${LIMIT}/page/1`)
    }
    const _settingCategoryDetail = () => {
        history.push(`${CACULARTOR_DETAIL}${match?.params?.id}`, match?.params?.id)
    }
    const [detailData, setDetailData] = useState()
    // ================= upload pic ==============================
    const [namePhoto, setNamePhoto] = useState('')
    const [file, setFile] = useState()
    const [imageLoading, setImageLoading] = useState()
    const handleUpload = async (event) => {
        setImageLoading("")
        try {
            setFile(event.target.files[0]);
            let formData = new FormData();
            let fileData = event.target.files[0]
            const responseUrl = await axios({
                method: 'post',
                url: PRESIGNED_URL,
                headers: getTokken,
                data: {
                    type: event.target.files[0].type
                }
            })
            setNamePhoto(responseUrl.data)
            let afterUpload = await axios({
                method: "put",
                url: responseUrl.data.url,
                data: fileData,
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setImageLoading(percentCompleted)
                }
            })
        } catch (error) {
        }
    }
    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} alt={image.name} style={{
            borderRadius: 5,
            height: 300,
            width: 300,
        }} />;
    };
    // ================================================================================================
    // ================= upload pic2 ==============================
    const [namePhotoBack, setNamePhotoBack] = useState('')
    const [fileBack, setFileBack] = useState()
    const [imageLoadingBack, setImageLoadingBack] = useState()
    const handleUploadBack = async (event) => {
        setImageLoadingBack("")
        try {
            setFileBack(event.target.files[0]);
            let fileDataBack = event.target.files[0]
            const responseUrlBack = await axios({
                method: 'post',
                url: PRESIGNED_URL,
                headers: getTokken,
                data: {
                    type: event.target.files[0].type
                }
            })
            setNamePhotoBack(responseUrlBack.data)
            let afterUpload = await axios({
                method: "put",
                url: responseUrlBack.data.url,
                data: fileDataBack,
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompletedBack = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setImageLoadingBack(percentCompletedBack)
                }
            })
        } catch (error) {
        }
    }
    const ImageThumbBack = ({ imageBack }) => {
        return <img src={URL.createObjectURL(imageBack)} alt={imageBack.name} style={{
            borderRadius: 5,
            height: 300,
            width: 300,
        }} />;
    };
    const [ckEditer, setCkEditer] = useState(detailData?.detail)
    
    useEffect(() => {
        if (detailData)setCkEditer(detailData?.detail)
    }, [detailData])
    // ================================================================================================
    const _creatSetting = (values) => {
        if (!values.member_interest) {
            errorAdd("Member is empty...!")
            return;
        }
        if (!values.non_member_interest) {
            errorAdd("None member is empty...!")
            return;
        }
        if (!ckEditer) {
            errorAdd("None Terms and conditions of the loan application is empty...!")
            return;
        }
        axios({
            method: 'PUT',
            url: SETTING_STORE_CATEGORY + "/" + match?.params?.id,
            headers: getTokken,
            data: {
                member_interest: values?.member_interest,
                non_member_interest: values?.non_member_interest,
                image: namePhotoBack.filename,
                imageBackground: namePhoto.filename,
                name: values.name,
                detail: ckEditer
            }
        }).then(function (response) {
            successUpdate()
            setTimeout(() => {
                history.push(`${CACULARTOR_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    const [isLoading, setIsLoading] = useState(false)
    const getData = async () => {
        setIsLoading(true)
        await fetch(SETTING_STORE_CATEGORY + "/" + match?.params?.id, {
            method: "GET",
            headers: getTokken
        }).then(response => response.json())
            .then(json => setDetailData(json));
        setIsLoading(false)
    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading />
                : <div>
                    <Formik
                        initialValues={{
                            member_interest: detailData?.member_interest,
                            non_member_interest: detailData?.non_member_interest,
                            name: detailData?.name,
                            detail: detailData?.detail
                        }}
                        validate={values => { }}
                        onSubmit={(values, { setSubmitting }) => {
                            _creatSetting(values)
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                        }) => (
                            <form onSubmit={handleSubmit}>
                                <div style={HEADER}>
                                    <a href="#" onClick={() => _caculartor()}>Setting »</a><a href="" onClick={() => _ConfigCaculartor()}>Set up a Shopfront »</a><a href="" onClick={() => _settingCategoryDetail()}>{detailData?.name}</a> » Edit
                                    <div style={{ height: 20 }}></div>
                                </div>
                                    <div style={BODY_DISCOUNT_SHOPDETAIL}>
                                        <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Add Shop information</div>
                                        <div style={{ height: "20px" }}></div>
                                        <div className="row" style={{ paddingLeft: 50, paddingRight: 50 }}>
                                            <div className="col-md-4">
                                                <div style={{ textAlign: "center" }}>
                                                    <input type="file" id="file-upload" onChange={handleUpload} hidden />
                                                    <label for="file-upload">
                                                        <div style={{
                                                            backgroundColor: "#E4E4E4E4",
                                                            height: 300,
                                                            width: 300,
                                                            borderRadius: "5%",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            border: "solid 1px #707070"
                                                        }}>
                                                            {file ? <ImageThumb image={file} /> : <Image
                                                                src={URL_PHOTO_AW3 + detailData?.imageBackground}
                                                                alt="AeonIcon"
                                                                width="100"
                                                                height="100"
                                                                style={{
                                                                    height: 300,
                                                                    width: 300,
                                                                    borderRadius: "5%",
                                                                    cursor: "pointer",
                                                                    display: "flex"
                                                                }}
                                                            />}
                                                        </div>
                                                    </label>
                                                    {/* progass */}
                                                    {imageLoading ? <div className="progress" style={{ height: 20 }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${imageLoading}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoading} aria-valuemin="0" aria-valuemax="100">{imageLoading}%</div>
                                                    </div> : <div style={{ height: 20 }} />}
                                                </div>
                                                <h5 style={{
                                                    color: "#0660D4",
                                                    textAlign: "center"
                                                }}>Cover photo</h5>
                                                <div style={{ textAlign: "center" }}>
                                                    <input type="file" id="file-uploadBack" onChange={handleUploadBack} hidden />
                                                    <label for="file-uploadBack">
                                                        <div style={{
                                                            backgroundColor: "#E4E4E4E4",
                                                            height: 300,
                                                            width: 300,
                                                            borderRadius: "5%",
                                                            cursor: "pointer",
                                                            display: "flex",
                                                            border: "solid 1px #707070"

                                                        }}>
                                                            {fileBack ? <ImageThumbBack imageBack={fileBack} /> : <Image
                                                                src={URL_PHOTO_AW3 + detailData?.image}
                                                                alt="AeonIcon"
                                                                width="100"
                                                                height="100"
                                                                style={{
                                                                    height: 300,
                                                                    width: 300,
                                                                    borderRadius: "5%",
                                                                    cursor: "pointer",
                                                                    display: "flex"
                                                                }}
                                                            />}
                                                        </div>
                                                    </label>
                                                    {/* progass */}
                                                    {imageLoadingBack ? <div className="progress" style={{ height: 20 }}>
                                                        <div className="progress-bar" role="progressbar" style={{ width: `${imageLoadingBack}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoadingBack} aria-valuemin="0" aria-valuemax="100">{imageLoadingBack}%</div>
                                                    </div> : <div style={{ height: 20 }} />}
                                                </div>
                                                <h5 style={{ color: "#0660D4", textAlign: "center" }}>Photo</h5>
                                            </div>
                                            <div className="col-md-8">
                                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                                <div style={{ height: 10 }}></div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                                            <div style={{ fontWeight: "bold" }}>Category name</div>
                                                            <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                        </div>
                                                        <input className="form-control"
                                                            type="text"
                                                            name="name"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.name}
                                                        />
                                                    </div>
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                                            <div style={{ fontWeight: "bold" }}>Member percentage</div>
                                                            <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                        </div>
                                                        <input className="form-control"
                                                            type="number"
                                                            name="member_interest"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.member_interest}
                                                        />

                                                    </div>
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                                <div className="row">
                                                    <div className="col-12">
                                                        <div style={{ flexDirection: "row", display: "flex" }}>
                                                            <div style={{ fontWeight: "bold" }}>None member percentage</div>
                                                            <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                        </div>
                                                        <input className="form-control"
                                                            type="number"
                                                            name="non_member_interest"
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values.non_member_interest}
                                                        />

                                                    </div>
                                                </div>
                                                <div style={{ height: 10 }}></div>
                                                <div className="col-12 row">
                                                    <div>Terms and conditions of the loan application</div>
                                                    <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-2">
                                                        <p></p>
                                                    </div>
                                                    <div className="col-10">
                                                        <CKEditor
                                                            editor={ClassicEditor}
                                                            data={detailData?.detail}
                                                            onReady={editor => {
                                                            }}
                                                            onChange={(event, editor) => {
                                                                setCkEditer(editor.getData());
                                                            }}
                                                            onBlur={(event, editor) => {
                                                            }}
                                                            onFocus={(event, editor) => {
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* button */}
                                        </div>
                                        <div style={{ height: 30 }}></div>
                                        <div className="row center">
                                            <div className="col-md-12">
                                                <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _ConfigCaculartor()}><i className="fas fa-pencil-alt"></i> Cancel</button>
                                                <button type="submit" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>Confirm</button>
                                            </div>
                                        </div>
                                    </div>
                            </form>
                        )}
                    </Formik>
                </div>
            }
        </div>
    )
}
