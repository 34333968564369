
import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import {
    TITLE_HEADER,
    HEADER,
    BODY_DSTAFF_DETAIL,
    LIMIT
} from "../../../consts"
import {
    STAFF_LIST,
    STAFF_EDIT
} from '../../../consts/router'
import { gender, stutus } from '../../../helper'
import { STAFFS_DETAIL, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { successDelete, errorAdd } from '../../../helper/sweetalert'
import { formatDate } from '../../../common/super'
import profileImage from "../../../image/profile.png"
import AnimationLoading from "../../../helper/loading.js"

export default function StaffDetail() {
    const { history, location, match } = useReactRouter()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    // history
    const _staffList = () => {
        history.push(`${STAFF_LIST}/limit/${LIMIT}/page/1`)
    }
    const _staffEdit = () => {
        history.push(`${STAFF_EDIT}${match?.params?.id}`, match?.params?.id)
    }
    // get data staffdetail
    const [staffDetaill, setStaffDetaill] = useState()
    const _delete = async () => {
        await fetch(STAFFS_DETAIL + match?.params?.id, {
            method: "DELETE",
            headers: getTokken
        }).then(function (response) {
            successDelete()
            setTimeout(() => {
                history.push(`${STAFF_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isLoading, setIsLoading] = useState(false)
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(STAFFS_DETAIL + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setStaffDetaill(json));
        setIsLoading(false)

    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading /> : <div>
                <h6 style={HEADER}>
                    <a href="#" onClick={() => _staffList()}>All employees » </a> {staffDetaill?.firstName}
                </h6>
                <div style={BODY_DSTAFF_DETAIL}>
                    <div style={{ padding: 20 }}>
                        <div style={{ padding: 30, marginLeft: 30, fontSize: "20px", fontWeight: "bold", color: "#5C5C5C" }}>Employee information</div>
                        <div className="col-md-12" style={{ textAlign: 'right' }}>
                            <button type="button" className="btn app-button col-md-1" onClick={handleShow}> <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 10 }} /> Delete</button>
                            <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _staffEdit()}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10 }} /> Edit</button>
                        </div>
                        <div className="row" style={{ padding: 20 }}>
                            <div className="col-md-4">
                                {staffDetaill?.image ? (
                                    <center>
                                        <Image src={URL_PHOTO_AW3 + staffDetaill?.image} alt="AeonIcon" width="150" height="150" style={{
                                            height: 200,
                                            width: 200,
                                            border: "solid 1px #707070",
                                            padding: 5,
                                            borderRadius: '50%',
                                        }} />
                                    </center>
                                ) : (<center>
                                    <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                        height: 200,
                                        width: 200,
                                        border: "solid 1px #707070",
                                        padding: 5,
                                        borderRadius: '50%',
                                    }} />
                                </center>)}
                                <div style={{ height: 10 }}></div>
                                <h3 style={{ textAlign: "center", color: "#0660D4", fontWeight: "bold" }}>{staffDetaill?.firstName + " " + staffDetaill?.lastName}</h3>
                                <div style={{ height: 20 }}></div>
                                {/* <div className="row">
                                    <p style={{ color: "#919191", fontWeight: "bold" }}>ສ້າງໂດຍ :</p>
                                    <p style={{ marginLeft: 20 }}>{staffDetaill?.createdBy}</p>
                                </div>
                                <div className="row">
                                    <p style={{ color: "#919191", fontWeight: "bold" }}>ອັບເດດໂດຍ :</p>
                                    <p style={{ marginLeft: 20 }}>{staffDetaill?.createdBy}</p>
                                </div> */}
                                <div style={{ height: 30 }}></div>
                            </div>
                            <div className="col-md-8">
                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                <div style={{ height: 10 }}></div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Name</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.firstName ? staffDetaill?.firstName : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Last name</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.lastName ? staffDetaill?.lastName : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Gender</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{gender(staffDetaill?.gender ? staffDetaill?.gender : "-")}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Date of birth</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{formatDate(staffDetaill?.dateOffBirth)}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Status</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{stutus(staffDetaill?.status ? staffDetaill?.status : "-")}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Religion</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.relegion ? staffDetaill?.relegion : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>System usage rights</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.userauth?.role ? staffDetaill?.userauth?.role : "-"}</p>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Address</div>
                                <div style={{ height: 10 }}></div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Province</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.province ? staffDetaill?.province : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>District</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.district ? staffDetaill?.district : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>village</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.village ? staffDetaill?.village : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Other</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.other ? staffDetaill?.other : "-"}</p>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Contact information</div>
                                <div style={{ height: 10 }}></div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Phone number</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.phoneNumber ? staffDetaill?.phoneNumber : "-"}</p>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>System usage information</div>
                                <div style={{ height: 10 }}></div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Email</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.email ? staffDetaill?.email : "-"}</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Password</p>
                                    </div>
                                    <div className="col-9">
                                        <p>******</p>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Other</div>
                                <div style={{ height: 10 }}></div>
                                <div className="row">
                                    <div className="col-3">
                                        <p style={{ color: "#909090" }}>Note</p>
                                    </div>
                                    <div className="col-9">
                                        <p>{staffDetaill?.note ? staffDetaill?.note : "-"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row center">
                            <div>Do you want Delete USER : </div>
                            <div style={{ marginLeft: 10, marginRight: 10, color: "red" }}>{staffDetaill?.email}</div>
                            <div> ?</div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleClose}>
                            Cancel
          </Button>
                        <Button style={{ backgroundColor: "#816aae", color: "#FFF", border: 0 }} onClick={() => _delete()}>
                            Confirm
          </Button>
                    </Modal.Footer>
                </Modal>
            </div>
            }
        </div>
    )
}
