import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import moment from 'moment';
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST2,
	BODY_DEALERLIST,
	LIMIT
} from "../../../consts"
import { CATEGORY_STORE, PRODUCT_MODEL } from '../../../consts/api'
import { MODEL } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import { Button, Modal } from "react-bootstrap";
import SelectSearch from 'react-select-search';
import "./style.css";

export default function ModelList() {
	const { history, location, match } = useReactRouter()
	const _limit = match.params.limit;
	const _page = match.params.page;
	const newDate = new Date();
	const [isLoading, setIsLoading] = useState(false)

	// ====state_fillter ===
	const [categoryId, setCategoryId] = useState("")
	const [categoryName, setCategoryName] = useState("")
	const [storeCategories, setStoreCategories] = useState([])

	const [startDate, setstartDate] = useState(moment(moment().subtract(90, 'days')).format("YYYY-MM-DD"))
	const [endDate, setendDate] = useState(moment(moment(newDate)).format("YYYY-MM-DD"))

	const [isDuplicateShow, setisDuplicateShow] = useState(false)
	const [currentProductId, setCurrentProductId] = useState(null)

	const [models, setModels] = useState([]);
	const [productTotal, setProductTotal] = useState(0);

	const [searchValue, setSearchValue] = useState("")
	const [pageNumber, setPageNumber] = useState(_page ?? 1);

	const [getTokken, setgetTokken] = useState("")
	const [totalPage, setTotalPage] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductDatas(_localData);
			}
		}
		fetchData();
	}, [])

	const getProductDatas = async (token) => {
		try {
			setIsLoading(true)
			// get product data
			await fetch(PRODUCT_MODEL + `/limit/${_limit}/page/${_page}/?${categoryId ? `category=${categoryId}&&` : ''}${startDate && endDate ? `startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				setProductTotal(json?.total ?? 0)
				setModels(json?.result ?? [])
				_getArrayPageNumber(json?.total)
			});
			// get category data
			await fetch(CATEGORY_STORE, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => setStoreCategories(json ?? []));
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}
	const onSearchProduct = async (category, _startDate, _endDate) => {
		try {
			setIsLoading(true)
			// get product data
			await fetch(`${PRODUCT_MODEL}/limit/${_limit}/page/${_page}/?${category ? `category=${category}&&` : ''}${_startDate && _endDate ? `startDate=${_startDate}&&endDate=${moment(moment(_endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
				method: "GET",
				headers: getTokken
			}).then(response => response.json()).then(json => {
				setProductTotal(json?.total ?? 0)
				setModels(json?.result ?? [])
				_getArrayPageNumber(json?.total)
			});
			setIsLoading(false)
		} catch (error) {
			console.log(error)
		}
	}
	
	const _getArrayPageNumber = (_modelTotal) => {
		let rowPage = [];
		let _total = 0;
		if (_modelTotal % parseInt(_limit) != 0) {
			_total = (parseInt(_modelTotal) / parseInt(_limit)) + 1;
		} else {
			_total = parseInt(_modelTotal) / parseInt(_limit);
		}
		for (let i = 1; i <= _total; i++) {
			rowPage.push(i);
		}
		setTotalPage(rowPage);
	};
	const _nextPage = (page) => {
		// history.push(`${MODEL}/limit/${_limit}/page/${page}`)
		try {
			setPageNumber(page)
			fetch(`${PRODUCT_MODEL}/limit/${_limit}/page/${page}/?${searchValue ? `code=${searchValue}&&` : ''}${categoryId ? `category=${categoryId}&&` : ''}${startDate && endDate ? `startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
				method: "GET",
				headers: getTokken
			}).then(response => response.json()).then(json => {
				setModels(json?.result ?? [])
			});
		} catch (error) {
			console.log(error);
		}
	}
	const handleChangeCategory = async (e) => {
		const _getSelectId = storeCategories.filter(item => item.name == e?.target?.value)
		if (_getSelectId.length > 0) {
			setCategoryId(_getSelectId[0]._id)
			setCategoryName(_getSelectId[0].name)
			onSearchProduct(_getSelectId[0]._id, startDate, endDate)
		} else {
			setCategoryId("")
			setCategoryName("")
			onSearchProduct(null, startDate, endDate)
		}
	}
	const _startDate = (e) => {
		setstartDate(e?.target?.value)
		onSearchProduct(categoryId, e?.target?.value, endDate)
	}
	const _endDate = (e) => {
		setendDate(e?.target?.value)
		onSearchProduct(categoryId, startDate, e?.target?.value)
	}
	const goToAddProduct = async () => {
		history.push(`${MODEL}/add`)
	}
	const goToDetail = (id) => {
		history.push(`${MODEL}/detail/${id}`)
	}
	const duplicate = () => {
		history.push(`${MODEL}/duplicate/${currentProductId}`)
	}
	const showDuplicateModal = (e, id) => {
		e.stopPropagation()
		setCurrentProductId(id)
		setisDuplicateShow(true)
	}

	const onSearch = (value) => {
		try {
			setSearchValue(value)
			fetch(`${PRODUCT_MODEL}/limit/${_limit}/page/${_page}/?${value ? `code=${value}&&` : ''}${categoryId ? `category=${categoryId}&&` : ''}${startDate && endDate ? `startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ''}`, {
				method: "GET",
				headers: getTokken
			}).then(response => response.json()).then(json => {
				setProductTotal(json?.total ?? 0)
				setModels(json?.result ?? [])
				_getArrayPageNumber(json?.total)
			});
		} catch (error) {
			console.log(error);
		}
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p>Product Description Template Management</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: 120, paddingTop: 24 }}>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row">
							<div className="col-md-3">
								<span style={{ fontWeight: "bold" }}>Code</span>
								<input type="text" className="form-control col-12" placeholder="Please input..." onChange={(e) => onSearch(e.target.value)} />
							</div>
							<div className="col-md-3">
								<span style={{ fontWeight: "bold" }}>Product Category</span>
								<select
									name="productCategory"
									className="btn-block form-control"
									onChange={(e) => handleChangeCategory(e)}
									value={categoryName}
								>
									<option value="">Please select</option>
									{storeCategories.length > 0 && storeCategories.map((item, index) =>
										<option key={index} value={item?.name}>{item?.name}</option>
									)}
								</select>
							</div>
							<div className="col-md-3">
								<span style={{ fontWeight: "bold" }}>Registered from</span>
								<input type="date" className="form-control col-12" value={startDate} onChange={(e) => _startDate(e)} />
							</div>
							<div className="col-md-3">
								<span style={{ fontWeight: "bold" }}>Register to</span>
								<input type="date" className="form-control col-12" value={endDate} onChange={(e) => _endDate(e)} />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST2, paddingTop: 24 }}>
				<div style={{ padding: 20, paddingTop: 0 }}>
					<div className="col-md-12">
						<div className="row" style={{ display: "flex", alignItems: "center" }}>
							<div className="col-md-10">
								<span>All Product Descriptions ({productTotal ?? 0})</span>
							</div>
							<div className="col-md-2">
								<button type="button" onClick={() => goToAddProduct()} className="btn btn-secondary col-md-12" style={{ float: "right", backgroundColor: "#816AAE" }}>Add Product Description</button>
							</div>
						</div>
					</div>
					{isLoading ? <AnimationLoading /> : <div>
						<div className="col-sm-12">
							<table className="table table-hover">
								<thead className="thead-light">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Product Category</th>
										<th scope="col">Code</th>
										<th scope="col">Name</th>
										<th scope="col">New name</th>
										<th scope="col">Created At</th>
										<th scope="col">Action</th>
									</tr>
								</thead>
								<tbody>
									{models.length > 0 && models.map((item, index) => {
										return <tr key={index} onClick={() => goToDetail(item?._id)}>
											<td>{index + 1 + parseInt(_limit) * parseInt(pageNumber - 1)}</td>
											<td>{item?.category?.name ?? "-"}</td>
											<td>{item?.code ?? "-"}</td>
											<td>{item?.name ?? "-"}</td>
											<td>{item?.newName ?? "-"}</td>
											<td>{formatDate(item?.createdAt)}</td>
											<td>
												<button onClick={(e) => showDuplicateModal(e, item._id)} type="button" className="btn btn-secondary" style={{ backgroundColor: "#816AAE", height: 30, padding: 0, paddingLeft: 16, paddingRight: 16 }}>Duplicate</button>
											</td>
										</tr>
									})}
								</tbody>
							</table>
							<div className="row center">
								{totalPage?.map((item, index) => {
									return (
										<button style={{
											width: 30,
											height: 30,
											border: "solid 1px #816aae",
											marginLeft: 2,
											backgroundColor: parseInt(pageNumber) == index + 1 ? "#816aae" : "#fff",
											color: parseInt(pageNumber) == index + 1 ? "#fff" : "#000",
										}} onClick={() => _nextPage(item)}
											key={item}
										>{item}</button>
									)
								})}
							</div>
						</div>
					</div>
					}
				</div>
			</div>
			<Modal centered show={isDuplicateShow} onHide={() => setisDuplicateShow(false)}>
				<Modal.Body >
					<div style={{ padding: 10 }}>
						<div style={{ fontWeight: "bold" }}>Does duplicate confirm?</div>
						<hr />
						<p>Do you really want to duplicate this product description?</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={() => setisDuplicateShow(false)}>
						Cancel
					</Button>
					<Button style={{ backgroundColor: "#816AAE", borderColor: "#816AAE" }} onClick={() => duplicate()}>Confirm</Button>
				</Modal.Footer>
			</Modal>
		</div>
	)
}
