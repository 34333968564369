import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { KEY_API_GOOGLEMAP } from '../../../consts/api'
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { Formik } from 'formik';
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    STORE,
    STORE_DISCOUNT_LIST
} from '../../../consts/router'
import { successAdd, errorAdd } from '../../../helper/sweetalert'
import { STORE_DISCOUNT_ADD, PRESIGNED_URL, CATEGORY_STORE } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

export default function DiscountShopAdd() {
    const { history, location, match } = useReactRouter()
    // history
    const _discountList = () => {
        history.push(`${STORE_DISCOUNT_LIST}/limit/${LIMIT}/page/1`)
    }
    // const [categoryStore, setCategoryStore] = useState()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    // useEffect(() => {
    //     fetch(CATEGORY_STORE, {
    //         method: "GET",
    //         headers: getTokken
    //     }).then(response => response.json())
    //         .then(json => setCategoryStore(json));
    // }, [])
    // upload pic
    const [namePhoto, setNamePhoto] = useState('')
    const [file, setFile] = useState()
    const [imageLoading, setImageLoading] = useState()
    const [genderData, setGenderData] = useState();
    const [statusData, setStatusData] = useState();
    const handleUpload = async (event) => {
        setImageLoading("")
        try {
            setFile(event.target.files[0]);
            let formData = new FormData();
            let fileData = event.target.files[0]
            const responseUrl = await axios({
                method: 'post',
                url: PRESIGNED_URL,
                headers: getTokken,
                data: {
                    type: event.target.files[0].type
                }
            })
            setNamePhoto(responseUrl.data)
            let afterUpload = await axios({
                method: "put",
                url: responseUrl.data.url,
                data: fileData,
                headers: {
                    "Content-Type": " file/*; image/*",
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "DELETE, POST, GET, OPTIONS",
                    "Access-Control-Allow-Headers":
                        "Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With",
                },
                onUploadProgress: function (progressEvent) {
                    var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                    setImageLoading(percentCompleted)
                }
            })
        } catch (error) {
        }
    }
    // lung jak upload leo pic ja ma so u nee
    const ImageThumb = ({ image }) => {
        return <img src={URL.createObjectURL(image)} alt={image.name} style={{
            borderRadius: "5",
            height: 300,
            width: 300,
        }} />;
    };
    // ========> 
    const [ckEditer, setCkEditer] = useState()
    const [getLatMap, setGetLatMap] = useState(17.9757940428051)
    const [getLngMap, setGetLngMap] = useState(102.568359375)
    // add discount Shop
    const _discountShopAdd = (values) => {
        // if (!values.storecategory) {
        //     errorAdd("Please select a title...!")
        //     return;
        // }
        axios({
            method: 'post',
            url: STORE_DISCOUNT_ADD,
            headers: getTokken,
            data: {
                name: values.name,
                discount: values.discount,
                detail: ckEditer,
                phoneNumber: values.phoneNumber,
                linkFacebook: values.linkFacebook,
                address: values.address,
                province: values.province,
                district: values.district,
                village: values.village,
                lat: getLatMap,
                lng: getLngMap,
                note: values.note,
                storeType: "DISCOUNTSHOP",
                image: namePhoto.filename,
                // storecategory: values.storecategory,
            }
        }).then(function (response) {
            successAdd()

            setTimeout(() => {
                history.push(`${STORE_DISCOUNT_LIST}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    // ========== getpotions ===========>
    const getLat = getLatMap
    const getLng = getLngMap
    const defaultCenter = {
        lat: getLat, lng: getLng
    }
    const mapStyles = {
        height: 400,
        width: "100%",
        borderRadius: 8
    };
    const onMarkerClick = (e) => {
        setGetLatMap(e.latLng.lat());
        setGetLngMap(e.latLng.lng());
    };
    const _getNewLat = (e) => {
        setGetLatMap(e.target.value)
    }
    const _getNewLng = (e) => {
        setGetLngMap(e.target.value)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _discountList()}>All Shops » </a> ຮ້ານ ຕົ້ນຕໍາລັບ ຫມີ່ທ່າຫິນປາກເຊ
            </h6>
            <Formik
                initialValues={{
                    name: '',
                    discount: '',
                    phoneNumber: '',
                    linkFacebook: '',
                    address: '',
                    province: '',
                    district: '',
                    village: '',
                    lat: '',
                    lng: '',
                    note: '',
                }}
                validate={values => { }}
                onSubmit={(values, { setSubmitting }) => {
                    _discountShopAdd(values)
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <form onSubmit={handleSubmit}>
                        <div style={BODY_DISCOUNT_SHOPDETAIL}>
                            <div style={{ padding: 20 }}>
                                <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Add shop (Discount Shop)</div>
                                <div className="row" style={{ padding: 20 }}>
                                    <div className="col-md-4 center">
                                        <input type="file" id="file-upload" onChange={handleUpload} hidden />
                                        <label for="file-upload">
                                            <div style={{
                                                backgroundColor: "#E4E4E4E4",
                                                height: 300,
                                                width: 300,
                                                borderRadius: "5%",
                                                cursor: "pointer",
                                                display: "flex"
                                            }}>
                                                {file ? <ImageThumb image={file} /> : <div style={{
                                                    borderRadius: "5",
                                                    display: "flex", height: 300,
                                                    width: 300, justifyContent: "center", alignItems: "center"
                                                }}>
                                                    <p style={{ color: "#fff", fontSize: 80, fontWeight: "bold" }}>+</p></div>}
                                            </div>
                                        </label>
                                        {/* progass */}
                                        {imageLoading ? <div className="progress" style={{ height: 20 }}>
                                            <div className="progress-bar" role="progressbar" style={{ width: `${imageLoading}%`, backgroundColor: "#816aae" }} aria-valuenow={imageLoading} aria-valuemin="0" aria-valuemax="100">{imageLoading}%</div>
                                        </div> : <div style={{ height: 20 }} />}
                                    </div>
                                    <div className="col-md-8">
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Shop name</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="name"
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Description</div>
                                            </div>
                                            {/* <div className="row"> */}
                                            <div className="col-12">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data=""
                                                    onReady={editor => {
                                                    }}
                                                    onChange={(event, editor) => {
                                                        setCkEditer(editor.getData());
                                                    }}
                                                    onBlur={(event, editor) => {
                                                    }}
                                                    onFocus={(event, editor) => {
                                                    }}
                                                />
                                            </div>
                                            {/* </div> */}
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Discounts</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    placeholder="5%"
                                                    type="discount"
                                                    name="discount"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.discount}
                                                />
                                            </div>
                                        </div>
                                        {/* <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Category</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <select name="storecategory"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    className="btn-block form-control">
                                                    <option value="-" selected={true} disabled={true}>Please select category...</option>
                                                    {categoryStore?.map((category, index) => {
                                                        return (
                                                            <option value={category?._id}>{category?.name}</option>
                                                        )
                                                    })}
                                                </select>
                                            </div>
                                        </div> */}
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Phone number</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="phoneNumber"
                                                    name="phoneNumber"
                                                    onChange={handleChange}
                                                    value={values.phoneNumber}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>facebook link</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="linkFacebook"
                                                    name="linkFacebook"
                                                    onChange={handleChange}
                                                    value={values.linkFacebook}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 20 }}></div>
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Location</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Location</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="address"
                                                    name="address"
                                                    onChange={handleChange}
                                                    value={values.address} />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>Province</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="province"
                                                    name="province"
                                                    onChange={handleChange}
                                                    value={values.province} />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>District</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="district"
                                                    name="district"
                                                    onChange={handleChange}
                                                    value={values.district}
                                                />
                                            </div>
                                        </div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div style={{ flexDirection: "row", display: "flex", marginLeft: 20 }}>
                                                <div style={{ fontWeight: "bold" }}>village</div>
                                                <div style={{ color: "red", fontWeight: "bold" }}>  *</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="village"
                                                    name="village"
                                                    onChange={handleChange}
                                                    value={values.village}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Latitude</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="lat"
                                                    name="lat"
                                                    onChange={handleChange}
                                                    value={getLatMap}
                                                    onChange={(e) => _getNewLat(e)}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Longitude</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="lng"
                                                    name="lng"
                                                    onChange={handleChange}
                                                    value={getLngMap}
                                                    onChange={(e) => _getNewLng(e)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ padding: 20 }}>
                                    <div className="col-md-4"></div>
                                    <div className="col-md-8">
                                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Other</div>
                                        <div style={{ height: 10 }}></div>
                                        <div className="row">
                                            <div className="col-12">
                                                <div style={{ paddingTop: 10 }}>Remark</div>
                                            </div>
                                            <div className="col-12">
                                                <input className="form-control btn-block"
                                                    type="note"
                                                    name="note"
                                                    onChange={handleChange}
                                                    value={values.note}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <LoadScript
                                    googleMapsApiKey={KEY_API_GOOGLEMAP}>
                                    <GoogleMap
                                        mapContainerStyle={mapStyles}
                                        zoom={12}
                                        center={defaultCenter}
                                        onClick={(e) => onMarkerClick(e)}
                                    >
                                        <Marker
                                            position={{ lat: getLat, lng: getLng }}
                                        />
                                    </GoogleMap>
                                </LoadScript>
                                <div className="row center">
                                    <div className="col-md-12">
                                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} ><i className="fas fa-pencil-alt"></i> Cancel</button>
                                        <button type="submit" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>Confirm</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                )}
            </Formik>
        </div>
    )
}
