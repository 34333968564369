import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faSyncAlt,
	faTimes
} from "@fortawesome/free-solid-svg-icons"
import {  Image } from 'react-bootstrap'
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST,
	LIMIT
} from "../../../consts"
import { URL_PHOTO_AW3,GET_ALL_DEALER_PRODUCT } from '../../../consts/api'
import { PRODUCT } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'

export default function ProductDetail() {
	const { history, location, match } = useReactRouter()
	const productId = match.params.id;

	const [isLoading, setIsLoading] = useState(false)
	const [product, setProduct] = useState({})

	const [getTokken, setgetTokken] = useState("")

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductData();
			}
		}
		fetchData();
	}, [])

	// const getProductDatas = async (token) => {
	// 	try {
	// 		setIsLoading(true)
	// 		await fetch(`${PRODUCT_API}/${productId}`, {
	// 			method: "GET",
	// 			headers: token
	// 		}).then(response => response.json()).then(json => {
	// 			console.log(json)
	// 			if (json?.description) {
	// 				let _description = JSON.parse(json?.description)
	// 				setDescription(_description ?? [])
	// 			}
	// 			setProduct(json)
	// 		});
	// 		setIsLoading(false)
	// 	} catch (error) {
	// 		console.log("error: ", error)
	// 		setIsLoading(false)
	// 	}
	// }

	const getProductData = async () => {
		setIsLoading(true);
		try {
			const _res = await axios.get(`${GET_ALL_DEALER_PRODUCT}/${productId}`);
			if (_res?.data) {
				setProduct(_res?.data);
				setIsLoading(false);
			}
		} catch (error) {
			setIsLoading(false);
		}
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p><span onClick={() => history.push(`${PRODUCT}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>Product Template Management</span> » ຂໍ້ມູນຂອງສິນຄ້າ</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%", paddingTop: 16 }}>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row" style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
							<p style={{ fontWeight: "bold", fontSize: 18 }}>ຂໍ້ມູນຂອງສິນຄ້າ</p>
							{/* <button onClick={() => history.push(`${PRODUCT}/edit/${productId}`)} type="button" className="btn btn-outline-secondary" style={{ float: "right", width: 100 }}>Edit</button> */}
						</div>
						{isLoading ? <AnimationLoading /> : <div className="row">
							<div className="col-md-3">
								<center>
									<Image src={product?.image ? `${URL_PHOTO_AW3}${product?.image}` : "https://themesfinity.com/wp-content/uploads/2018/02/default-placeholder.png"} alt="AeonIcon" width="150" height="150" style={{
										height: 200,
										width: 200,
										objectFit: "cover",
										borderRadius: 8,
										border: "solid 2px #707070",
										padding: 1
									}} />
								</center>
							</div>
							<div className="col-md-9" style={{ paddingLeft: 24 }}>
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>ຂໍ້ມູນທົ່ວໄປ</p>
								</div>
								<div style={{ height: 16 }} />
								<div className="row">
									<div className="col-md-3">
										<p>Product Category</p>
									</div>
									<div>
										<p style={{ backgroundColor: '#eee', paddingLeft: 16, paddingRight: 16, borderRadius: 8 }}>{product?.productCategory?.name ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>Brand</p>
									</div>
									<div>
										<p>{product?.brand?.name ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>Product Description</p>
									</div>
									<div style={{width: 500}}>
										<p>{product?.description ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>Model</p>
									</div>
									<div>
										<p>{product?.model?.name}</p>
									</div>
									{/* <div>
										<p>{description.length > 0 && description.map((item0, index0) => {
											return <span key={index0}><span style={{ fontWeight: "bold" }}>{item0?.name}</span>: {item0?.values?.map((item2, index2) => item2 + (item0?.values.length != index2 + 1 ? ", " : ""))}{(description.length != index0 + 1 ? ";  " : "")}</span>
										})}</p>
									</div> */}
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>ວັນທີ່ເພີ່ມ</p>
									</div>
									<div>
										<p>10 ທັນວາ 2021,</p>
									</div>
								</div>
							</div>
						</div>}
					</div>
				</div>
			</div>
		</div>
	)
}
