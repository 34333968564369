import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import queryString from 'query-string';
import moment from 'moment';

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    LOCATION_LAST_LONG,
    LOCATION_LAST_LOGIN,
    LOCATION_LIST
} from '../../../consts/router'
import { HISTORY_LOGIN_LAST, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import profileImage from "../../../image/profile.png"
import AnimationLoading from "../../../helper/loading.js"
import PaginationHelper from "../../../helper/PaginationHelper";

export default function LocationList() {
    const { history, location, match } = useReactRouter()
    const parsed = queryString.parse(location.state);
    const newDate = new Date();

    const { _limit, _page, Pagination_helper } = PaginationHelper();

    const [isLoading, setIsLoading] = useState(false)
    const [dataLocationLastLogin, setDataLocationLastLogin] = useState()
    // filter=====>
    const [fillterName, setfillterName] = useState(parsed?.localfirstname ? parsed?.localfirstname : '')
    const [startDate, setstartDate] = useState(parsed?.startDate ? parsed?.startDate : '2020-01-01')
    const [endDate, setendDate] = useState(parsed?.endDate ? parsed?.endDate : moment(moment(newDate)).format("YYYY-MM-DD"))
    const _historyLastLogin = (userauth, id) => {
        history.push(`${LOCATION_LAST_LONG}${userauth}/limit/${LIMIT}/page/1`, userauth)
    }
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [fillterName])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [startDate && endDate])
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
            }
        }
        fetchData();
    }, [endDate && startDate])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(HISTORY_LOGIN_LAST + "/limit/" + _limit + `/page/${_page}/?${fillterName ? `&&localfirstname=${fillterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}&&` : ''}`, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setDataLocationLastLogin(json));
        setIsLoading(false)
    }
    const _latLogin = () => {
        history.push(`${LOCATION_LAST_LOGIN}/limit/${LIMIT}/page/1/`)
    }
    const _history = () => {
        history.push(`${LOCATION_LIST}`)
    }
    useEffect(() => {
        if (dataLocationLastLogin?.total > 0) {
            _getArrayPageNumber()
        }
    }, [dataLocationLastLogin])
    const [totalPage, setTotalPage] = useState([]);
    const _getArrayPageNumber = () => {
        let rowPage = [];
        let _total = 0;
        if (dataLocationLastLogin?.total % parseInt(_limit) != 0) {
            _total = (parseInt(dataLocationLastLogin?.total) / parseInt(_limit)) + 1;
        } else {
            _total = parseInt(dataLocationLastLogin?.total) / parseInt(_limit);
        }
        for (let i = 1; i <= _total; i++) {
            rowPage.push(i);
        }
        setTotalPage(rowPage);
    };
    const _fillterName = (e) => {
        setfillterName(e?.target?.value)
    }
    const _startDate = (e) => {
        setstartDate(e?.target?.value)
    }
    const _endDate = (e) => {
        setendDate(e?.target?.value)
    }
    const _nextPage = (page) => {
        history.push(`${LOCATION_LIST}/limit/${_limit}/page/${page}/?${fillterName ? `&&localfirstname=${fillterName}&&` : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}&&` : ''}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <p>All Location Histories » </p>
            </h6>
            <ul className="nav nav-tabs" id="myTab" style={{ width: "95%", marginLeft: "30px", border: "solid 0px" }}>
                <li className="nav-item" style={{ border: "solid 1px #FFFF" }}>
                    <a className="nav-link active" id="home-tab" data-toggle="tab" role="tab" aria-controls="home" aria-selected="true" onClick={() => _history()} style={{ cursor: "pointer", border: "solid 1px #FFFF" }}>Location History</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="profile-tab" data-toggle="tab" role="tab" aria-controls="profile" aria-selected="false" onClick={() => _latLogin()} style={{ cursor: "pointer", color: "blue" }}>Login records</a>
                </li>
            </ul>
            <div style={BODY}>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div className="row" style={{ padding: 20 }}>
                        </div>
                        <div className="col-md-12 row" >
                            <div className="col-md-7" style={{ fontSize: "20px" }}>All Location History({dataLocationLastLogin?.total})</div>
                            <div className="col-md-5" style={{ margin: 0, padding: 0 }}>
                                <div style={{ fontWeight: "bold" }}>Search</div>
                                <input className="form-control" value={fillterName} placeholder="Name..." style={{ backgroundColor: "#E4E4E4" }} onChange={(e) => _fillterName(e)} />
                            </div>
                        </div>
                        <div style={{ height: 20 }}></div>
                        <div className="col-md-12 row" >
                            <div className="col-md-6"> </div>
                            <div className="col-md-3 float-right">
                                <div className="form-group" style={{ fontWeight: "bold", marginRight: 10 }}>
                                    <label for="exampleInputEmail1">Date from</label>
                                    <input type="date" className="form-control" id="exampleInputEmail1" value={startDate} onChange={(e) => _startDate(e)} />
                                </div>
                            </div>
                            <div className="col-md-3 float-right" style={{ padding: "0px 0px 0px 0px" }}>
                                <div className="form-group" style={{ fontWeight: "bold" }}>
                                    <label for="exampleInputEmail1">Date to</label>
                                    <input type="date" className="form-control" id="exampleInputEmail1" value={endDate} onChange={(e) => _endDate(e)} />
                                </div>
                            </div>
                        </div>
                        {/* <div className="row" style={{ paddingLeft: 30 }}>
                            <p style={{ color: "#5C5C5C" }}>{(_page - 1) * _limit}-{dataLocationLastLogin?.total}</p>
                        </div> */}
                        {isLoading ? <AnimationLoading /> : <div>
                            <div className="col-md-12">
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Picture</th>
                                            <th scope="col">Name</th>
                                            <th scope="col">Last name</th>
                                            <th scope="col">Latest Online</th>
                                            <th scope="col">Phone number</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {dataLocationLastLogin?.results?.map((item, index) => {
                                            return (
                                                <tr onClick={() => _historyLastLogin(item?.userauth?._id, item?._id)} key={index}>
                                                    <th scope="row">{index + 1 + parseInt(_limit) * parseInt(_page - 1)}</th>
                                                    <td>
                                                        {item?.image ? (
                                                            <center>
                                                                <Image src={URL_PHOTO_AW3 + item?.image} alt="AeonIcon" width="150" height="150" style={{
                                                                    height: 50,
                                                                    width: 50,
                                                                    borderRadius: '50%',
                                                                }} />
                                                            </center>
                                                        ) : (<center>
                                                            <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                                height: 50,
                                                                width: 50,
                                                                borderRadius: '50%',
                                                            }} />
                                                        </center>)}
                                                    </td>
                                                    <td>{item?.localfirstname}</td>
                                                    <td>{item?.localfamilyname}</td>
                                                    <td>{formatDate(item?.createdAt)}</td>
                                                    <td>{item?.mobileno}</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                                <div className="row center">
                                {Pagination_helper(dataLocationLastLogin?.total, "/locationList", `&&localfirstname=${fillterName}&& : ''}${startDate && endDate ? `&&startDate=${startDate}&&endDate=${endDate}&&` : ''}`)}
                                    {/* {totalPage?.map((item, index) => {
                                        return (
                                            <button style={{
                                                width: 30,
                                                height: 30,
                                                border: "solid 1px #816aae",
                                                marginLeft: 2,
                                                backgroundColor: parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                                                color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                                            }} onClick={() => _nextPage(item)}
                                                key={item}
                                            >{item}</button>
                                        )
                                    })} */}
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div >
            </div >
        </div >
    )
}