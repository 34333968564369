import React, { useState, useRef, useEffect } from "react"
import useReactRouter from "use-react-router"
import Image from "react-bootstrap/Image"
import ImageLogo from "../../../image/profile.png"
import { Modal, Button } from 'react-bootstrap'
import MapContainer from "../../../consts/locationMap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPen, faTrashAlt } from "@fortawesome/free-solid-svg-icons"
import {
    TITLE_HEADER,
    HEADER,
    BODY_DISCOUNT_SHOPDETAIL,
    LIMIT
} from "../../../consts"
import {
    STORE,
    DEALER_SHOP_EDIT,
} from '../../../consts/router'
import profileImage from "../../../image/profile.png"

import { STOREDEALER, DELETE_DEALER_SHOP, CATEGORY_STORE, URL_PHOTO_AW3 } from '../../../consts/api'
import { getLocalData } from '../../../consts/tokken'

import { successDelete, errorAdd } from '../../../helper/sweetalert'
import { formatDate } from '../../../common/super'
export default function DealerShopDetail() {
    const { history, location, match } = useReactRouter()
    // history
    const _dealerList = () => {
        history.push(`${STORE}/limit/${LIMIT}/page/1`)
    }
    const _dealerShopEdit = () => {
        history.push(`${DEALER_SHOP_EDIT}${match?.params?.id}`, match?.params?.id)
    }
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [dealerShop, setDealerShop] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [latData, setLatData] = useState()
    const [lngData, setLngData] = useState()
    const [getTokken, setgetTokken] = useState()
    useEffect(() => {
        const fetchData = async () => {
            const _localData = await getLocalData()
            if (_localData) {
                getData(_localData)
                setgetTokken(_localData)
            }
        }
        fetchData();
    }, [])
    // =========== google_map =========>>>>.
    useEffect(() => {
        if (dealerShop) {
            setLatData(dealerShop?.lat)
            setLngData(dealerShop?.lng)
        }
    }, [dealerShop])
    const getData = async (tokken) => {
        setIsLoading(true)
        await fetch(STOREDEALER + match?.params?.id, {
            method: "GET",
            headers: tokken
        }).then(response => response.json())
            .then(json => setDealerShop(json));
        setIsLoading(false)

    }
    const _confirmDelete = () => {
        fetch(DELETE_DEALER_SHOP + match?.params?.id, {
            method: "DELETE",
            headers: getTokken
        }).then(function (response) {
            successDelete()
            setTimeout(() => {
                history.push(`${STORE}/limit/${LIMIT}/page/1`)
            }, 1800);
        }).catch(function (error) {
            errorAdd('Something went wrong, please try again !')
        });
    }
    // =============++++++ checkout +++++++===========+>>>>
    return (
        <div style={TITLE_HEADER}>
            <h6 style={HEADER}>
                <a href="#" onClick={() => _dealerList()}>All Shops » </a> {dealerShop?.name}
            </h6>
            <div style={BODY_DISCOUNT_SHOPDETAIL}>
                <div style={{ padding: 20 }}>
                    <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>Shop information (Dealer Shop)</div>
                    <div className="col-md-12" style={{ textAlign: 'right' }}>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px #816AAE", color: "#816AAE" }} onClick={handleShow}> <FontAwesomeIcon icon={faTrashAlt} style={{ marginRight: 10 }} />delete</button>
                        <button type="button" className="btn btn-secondary col-md-1" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} onClick={() => _dealerShopEdit()}><FontAwesomeIcon icon={faPen} style={{ marginRight: 10 }} />edit</button>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-4">
                            {dealerShop?.image ? (
                                <center>
                                    <Image src={URL_PHOTO_AW3 + dealerShop?.image} alt="AeonIcon" width="150" height="150" style={{
                                        height: 200,
                                        width: 300,
                                        objectFit: "cover",
                                        borderRadius: 8,
                                        border: "solid 2px #707070",
                                        padding: 1

                                    }} />
                                </center>
                            ) : (
                                <center>
                                    <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                        height: 200,
                                        width: 300,
                                        objectFit: "cover",
                                        borderRadius: 8,
                                        border: "solid 2px #707070",
                                        padding: 1

                                    }} />
                                </center>
                            )}
                            <div style={{ height: 30 }}></div>
                            <h5 style={{ color: "#0660D4", textAlign: "center" }}>{dealerShop?.name}</h5>
                        </div>
                        <div className="col-md-8">
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Shop name</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.name ? dealerShop?.name : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Category</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.storecategory?.name}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Description</p>
                                </div>
                                {/* <div className="col-9">
                                    <p>{dealerShop?.detail ? dealerShop?.detail : "-"}</p>
                                </div> */}
                                <div className="col-9" dangerouslySetInnerHTML={{ __html: dealerShop?.detail }}>
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>General Information</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Phone number</p>
                                </div>
                                <div className="col-9">
                                    <p style={{ fontWeight: "bold" }}>{dealerShop?.phoneNumber ? dealerShop?.phoneNumber : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>facebook link</p>
                                </div>
                                <div className="col-md-9 wrap-text">
                                    <p>{dealerShop?.linkFacebook ? dealerShop?.linkFacebook : "-"}</p>
                                </div>
                            </div>
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>Location</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Location</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.address ? dealerShop?.address : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Province</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.province ? dealerShop?.province : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>District</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.district ? dealerShop?.district : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>village</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.village ? dealerShop?.village : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Latitude</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.lat ? dealerShop?.lat : "-"}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Longitude</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.lng ? dealerShop?.lng : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" style={{ padding: 20 }}>
                        <div className="col-md-4">
                            <div className="row">
                                <div className="col-3">
                                    <p>Create_By</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.createdBy ? dealerShop?.createdBy : "-"}</p>
                                    <p>{formatDate(dealerShop?.createdAt ? dealerShop?.createdAt : "-")}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>update_By</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.updatedby ? dealerShop?.updatedby : "-"}</p>
                                    <p>{formatDate(dealerShop?.updatedAt ? dealerShop?.updatedAt : "-")}</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>ອື່ນໆ</div>
                            <div style={{ height: 10 }}></div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Remark</p>
                                </div>
                                <div className="col-9">
                                    <p>{dealerShop?.note ? dealerShop?.note : "-"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ padding: 30 }}>
                    <MapContainer
                        lat={latData}
                        lng={lngData}
                        width="100%"
                        height="100vh"
                    />
                </div>
            </div>
            <Modal
                show={show}
                onHide={handleClose}
            >
                <Modal.Body >
                    <div className="row center" style={{ fontWeight: "bold" }}>
                        <div style={{ textAlign: "center" }}>Do you want delete </div>
                        <div style={{ color: "red", marginLeft: 10 }}>{dealerShop?.name}</div>
                        <div style={{ marginLeft: 10 }}> ?</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button variant="danger" onClick={() => _confirmDelete()}>Confirm</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
