import React, { useState, useEffect } from "react"
import "../utils/index.css"
import useReactRouter from "use-react-router"
import profileImage from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import AnimationLoading from "../../../helper/loading.js"

import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    PROMOTION_POPUP_LIST,
    PROMOTION_SLIDE_ADD,
    PROMOTION_SLIDE_LIST,
    PROMOTION_SLIDE_DETAIL,
    PROMOTION_LIST
} from '../../../consts/router'
import PromotionSlideEditnumber from "./PromotionSlideEditnumber"
import { URL_PHOTO_AW3, PROMOTION_DATA_SLIDE } from '../../../consts/api'
import { ACCESS_TOKEN } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
export default function PromotionList() {
    const { history, location, match } = useReactRouter()
    const _promotionSlideAdd = () => {
        history.push(`${PROMOTION_SLIDE_ADD}`)
    }
    const _promotionSlideDetail = () => {
        history.push(`${PROMOTION_SLIDE_DETAIL}`)
    }
    const _slideList = () => {
        history.push(`${PROMOTION_SLIDE_LIST}`)
    }
    const _popUpList = () => {
        history.push(`${PROMOTION_POPUP_LIST}`)
    }
    const _promotionList = () => {
        history.push(`${PROMOTION_LIST}`)
    }
    const [showAddConfirmModal, setShowAddConfirmModal] = useState(false);
    const _handleShowAddConfirmModalClose = () => setShowAddConfirmModal(false);
    const _handleShowAddConfirmModalShow = () => setShowAddConfirmModal(true);
    const [isLoading, setIsLoading] = useState(false)
    const [promotionSlide, setPromotionSlide] = useState()
    useEffect(() => {
        getData()
    }, [])
    const getData = async () => {
        setIsLoading(true)
        await fetch(PROMOTION_DATA_SLIDE + `/limit/${LIMIT}/page/1`, {
            method: "GET",
            headers: ACCESS_TOKEN
        }).then(response => response.json())
            .then(json => setPromotionSlide(json));
        setIsLoading(false)
    }
    const _statusPromotion = (data) => {
        if (data === "OPEN") {
            return <div style={{ border: "solid 2px #0660D4", borderRadius: 50, width: 80, textAlign: "center", color: "#0660D4" }}>OPEN</div>
        } else {
            return <div style={{ border: "solid 2px red", borderRadius: 50, width: 80, textAlign: "center", color: "red" }}>CLOSE</div>

        }
    }
    return (
        <div style={TITLE_HEADER}>
            {isLoading ? <AnimationLoading /> : <div>

                <div style={HEADER}>
                    <div style={{ paddingBottom: 10 }}> Promotion </div>
                </div>
                <ul className="nav nav-tabs" id="myTab" style={{ width: "95%", marginLeft: "30px" }}>
                    <li className="nav-item">
                        <a className="nav-link" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => _promotionList()}>Promotion</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => _slideList()}>Slide</a>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link " id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false" onClick={() => _popUpList()}>Pop up</a>
                    </li>
                </ul>
                <div style={BODY}>
                    <div className="tab-content" id="myTabContent">
                        {/* ສະໄລ */}
                        <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}>ສະໄລທັງໝົດ ({promotionSlide?.total})</div>
                            <div className="col-md-12" style={{ textAlign: 'right' }}>
                                <button type="button" className="btn btn-secondary col-md-2" style={{ backgroundColor: "#816AAE" }} onClick={() => _promotionSlideAdd()}>ເພີ່ມໂປຣໂມຊັນ</button>
                                <button type="button" className="btn btn-secondary col-md-2" style={{ backgroundColor: "#ffff", border: "solid 1px #E4E4EE", color: "#606060" }} onClick={() => _handleShowAddConfirmModalShow()}>ອັບເດດລຳດັບສະໄລ້</button>
                            </div>
                            <div className="col-sm-12" style={{ marginTop: 20 }}>
                                <table className="table table-hover">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ຮູບພາບ</th>
                                            <th scope="col">ID</th>
                                            <th scope="col">ຫົວຂໍ້</th>
                                            <th scope="col">ໄລຍະເວລາໂຊ ວ/ດ/ປ</th>
                                            <th scope="col">ລຳດັບ Slide</th>
                                            <th scope="col">ເວລາສ້າງ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {promotionSlide?.result?.map((item, index) => {
                                            return (
                                                <tr onClick={() => _promotionSlideDetail()}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>
                                                        {item?.image ? (
                                                            <Image src={URL_PHOTO_AW3 + item?.image} alt="AeonIcon" width="150" height="150" style={{
                                                                height: 50,
                                                                width: 50,
                                                                borderRadius: 5,
                                                            }} />
                                                        ) : (
                                                            <Image src={profileImage} alt="AeonIcon" width="150" height="150" style={{
                                                                height: 50,
                                                                width: 50,
                                                                borderRadius: 5,
                                                            }} />
                                                        )}
                                                    </td>
                                                    <td>Otto</td>
                                                    <td>{item?.title}</td>
                                                    <td>{formatDate(item?.startDate)}</td>
                                                    <td>{_statusPromotion(item?.status)}</td>
                                                    <td>{formatDate(item?.createdAt)}</td>
                                                </tr>
                                            )
                                        })}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <PromotionSlideEditnumber
                    showAddConfirmModal={showAddConfirmModal}
                    _handleShowAddConfirmModalClose={_handleShowAddConfirmModalClose}
                />
            </div >
            }
        </div >
    )
}
