import React, { useState, useEffect } from "react"
import "../utils/index.css"
import useReactRouter from "use-react-router"
import ImageLogo from "../../../image/profile.png"
import Image from "react-bootstrap/Image"
import {
    TITLE_HEADER,
    HEADER,
    BODY,
    BODY_TAB1
} from "../../../consts"
import {
    PROMOTION_LIST
}from '../../../consts/router'
export default function PromotionSlideAdd() {
    const { history, location, match } = useReactRouter()
    const _promotionList_page = () => {
        history.push(`${PROMOTION_LIST}`)
    }
    return (
        <div style={TITLE_HEADER}>
            <h5 style={HEADER}>
                <a href="#" onClick={() => _promotionList_page()}>ໄປຣໂມຊັນທັງໝົດ »</a> ເພີ່ມສະໄລ
            </h5>
            <div style={BODY_TAB1}>
                <div>
                    <p>ເລືອກໂປຣໂມຊັນ</p>
                    <select name="cars" id="cars" className="col-5 form-control">
                        <option value="ປ້ອນຫົວຂໍ້ ຫຼື ID">ປ້ອນຫົວຂໍ້ ຫຼື ID</option>
                        <option value="saab">Saab</option>
                        <option value="mercedes">Mercedes</option>
                        <option value="audi">Audi</option>
                    </select>
                </div>
            </div>
            <div style={BODY}>
                <div style={{ height: "20px" }}></div>
                <div className="row" style={{padding:50}}>
                    <div className="col-md-4">
                        <center>
                            <Image src={ImageLogo} alt="AeonIcon" width="150" height="150" style={{
                                height: 200,
                                width: 300,
                                borderRadius: 8,
                                marginLeft: 60,
                            }} />
                        </center>
                        <div style={{ height: 30 }}></div>
                        <h5 style={{ color: "#0660D4", textAlign: "center" }}>ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ</h5>
                        <div>ສ້າງ</div>
                        <div style={{ padding: 10 }}>2020/06/01 17:00</div>
                        <div>ອັບເດດ</div>
                        <div style={{ padding: 10 }}>2020/06/19 20:23</div>
                    </div>
                    <div className="col-md-8">
                        <div style={{ backgroundColor: "#F1F1F1", padding: 10, fontWeight: 'bold' }}>ຂໍ້ມູນທົ່ວໄປ</div>
                        <div className="row">
                            <div className="col-3">
                                <p>ID</p>
                            </div>
                            <div className="col-9">
                                <p>1234</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>ຫົວຂໍ້</p>
                            </div>
                            <div className="col-9">
                                <p>ໂປຣໂມຊັນເຄື່ອງໃຊ້ໄຟຟ້າ</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>link</p>
                            </div>
                            <div className="col-9">
                                <p>https://aeonlao.com/posts/promotions/single/21</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>ລາຍລະອຽດ</p>
                            </div>
                            <div className="col-9">
                                <p>ເງືອນໄຂໂປຣໂມຊັນ: 1. ບໍ່ມີຄ່າທຳນຽມ 2. ໂຊກຊັ້ນທີ1: ເມື່ອຜ່ອນໂທລະສັບມືຖື ແລະ ເຄື່ອງໃຊ້ໄຟຟ້າທົ່ວໄປ ໃນມູນຄ່າ 4 ລ້ານກີບຂື້ນໄປ ໃນໄລຍະ 12 ເດືອນຂື້ນໄປ, ຮັບທັນທີບັດຕື່ມເງິນລາວໂທລະຄົມ ມູນຄ່າ 50.000 ກີບ 3. ໂຊກຊັ້ນທີ2: ມີສິດລຸ້ນຮັບບັດຕື່ມນໍ້າມັນ ແລະ ສາຍຄໍຄຳທຸກໆທ້າຍເດືອນ. 4. ເລີ້ມຕັ້ງແຕ່ວັນທີ 01 ມິຖຸນາ - 31 ກໍລະກົດ 2020</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>ໄລຍະໂປຣໂມຊັນ</p>
                            </div>
                            <div className="col-9">
                                <p>2020/06/01 ~ 2020/07/31</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>ສະຖານະ</p>
                            </div>
                            <div className="col-3">
                                <p style={{ color: "#0660D4", border: "solid 1px #0660D4", borderRadius: 8, textAlign: "center" }}>ເຜີຍແຜ່</p>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-3">
                                <p>ໝາຍເຫດ</p>
                            </div>
                            <div className="col-3">
                                <p> - </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row center">
                    <div className="col-md-12">
                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#ffff", border: "solid 1px", color: "#606060" }} ><i className="fas fa-pencil-alt"></i> ຍົກເລີກ</button>
                        <button type="button" className="btn btn-secondary col-md-3" style={{ backgroundColor: "#816AAE", color: "#FFFF" }}>ຢືນຢັນ</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
