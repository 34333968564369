import React from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { KEY_API_GOOGLEMAP } from './api'
export default function MapContainer({ lat, lng, width, height }) {
    const getLat = lat
    const getLng = lng
    const defaultCenter = {
        lat: getLat, lng: getLng
    }
    const mapStyles = {
        height: height,
        width: width
    };
    const onMarkerClick = (evt) => {
    };
    return (
        <LoadScript
            googleMapsApiKey={KEY_API_GOOGLEMAP}>
            <GoogleMap
                mapContainerStyle={mapStyles}
                zoom={12}
                center={defaultCenter}
            >
                <Marker position={{ lat: getLat, lng: getLng }} onClick={onMarkerClick} />
            </GoogleMap>
        </LoadScript>
    )
}