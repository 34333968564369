import moment from "moment";
import React, { useState, useEffect } from "react";
import axios from "axios";
import styled from "styled-components";
import useReactRouter from "use-react-router";
import { BODY_DEALERLIST2 } from "../../../consts";
import {
  GET_MANY_SHOP_PORTAL,
  PRODUCT_BRAND,
  PRODUCT_MODEL,
  GET_ALL_DEALER_PRODUCT,
  USERS,
  EDIT_SHOP_PORTAL,
  ENPOINTE_API,
} from "../../../consts/api";
import { ADMIN_PORTAL } from "../../../consts/router";
import { getLocalData } from "../../../consts/tokken";
import AnimationLoading from "../../../helper/loading";
import { downloadExcel } from "../../../helper/exportToExcel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { COLOR_PRIMARY } from "../../../consts";
//helper
import { thousand, formatDate } from "../../../helper";
import PaginationHelper from "../../../helper/PaginationHelper";

export default function Product({ id }) {
  const { match, history } = useReactRouter();
  const _limit = 40;
  const _page = match.params.page;
  const [isLoading, setIsLoading] = useState(false);
  // const [startDate, setstartDate] = useState(moment(moment().subtract(30, "days")).format("YYYY-MM-DD"));
  // const [endDate, setendDate] = useState(moment().format("YYYY-MM-DD"));
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brands, setBrands] = useState([]);

  const [modelId, setModelId] = useState("");
  const [modelName, setModelName] = useState("");
  const [models, setModels] = useState([]);

  const [adminShopPortalData, setAdminShopPortalData] = useState({});
  const [shopCategories, setShopCategories] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [categoryId, setCategoryId] = useState("");

  const [shopProducts, setShopProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);
  const [staffData, setStaffData] = useState([]);
  const [exportExcelData, setExportExcelData] = useState();

  const [getTokken, setgetTokken] = useState();
  const [totalPage, setTotalPage] = useState([]);
  const { Pagination_helper } = PaginationHelper();

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        setgetTokken(_localData);
        getProductDatas(_localData);
        fetchExcelExportData(_localData);
        fetchAdminShopPortalData(_localData);
        getUserAuth(_localData);
      }
    };
    fetchData();
  }, []);

  const fetchExcelExportData = async (token) => {
    try {
      let reqUrl = `&${categoryId ? `productCategory=${categoryId}&` : ""}${
        brandId ? `brand=${brandId}&` : ""
      }${modelId ? `model=${modelId}&` : ""}${
        startDate && endDate
          ? `startDate=${startDate}&endDate=${moment(
              moment(endDate).add(1, "days")
            ).format("YYYY-MM-DD")}`
          : ""
      }`;

      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${_page}/?shopPortal=${id}&status=APPROVED${reqUrl}`,
        {
          method: "GET",
          headers: token,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setExportExcelData(json?.result ?? []);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const getProductDatas = async (token) => {
    try {
      setIsLoading(true);
      let reqUrl = `&${categoryId ? `productCategory=${categoryId}&` : ""}${
        brandId ? `brand=${brandId}&` : ""
      }${modelId ? `model=${modelId}&` : ""}${
        startDate && endDate
          ? `startDate=${startDate}&endDate=${moment(
              moment(endDate).add(1, "days")
            ).format("YYYY-MM-DD")}`
          : ""
      }`;

      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${_page}/?shopPortal=${id}&status=APPROVED${reqUrl}`,
        {
          method: "GET",
          headers: token,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setProductTotal(json?.total ?? 0);
          if (json?.total > 0) {
            _getArrayPageNumber(json?.total);
          }
          setShopProducts(json?.result ?? []);
        });
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const fetchAdminShopPortalData = async (token) => {
    try {
      setIsLoading(true);
      await fetch(`${GET_MANY_SHOP_PORTAL}/${id}`, {
        method: "GET",
        headers: token,
      })
        .then((response) => response.json())
        .then((json) => {
          setAdminShopPortalData(json);
          if (json.shopCategories) setShopCategories(json.shopCategories);
        });
      setIsLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
    }
  };

  const handleChangeCategory = async (e) => {
    setBrands([]);
    setModels([]);
    setBrandId("");
    setBrandName("");
    setModelId("");
    setModelName("");
    const _getSelectId = shopCategories.filter(
      (item) => item.name === e?.target?.value
    );
    if (_getSelectId.length > 0) {
      setCategoryId(_getSelectId[0]._id);
      setCategoryName(_getSelectId[0].name);
      onSearchProduct(
        _getSelectId[0]._id,
        brandId,
        modelId,
        startDate,
        endDate
      );
      onSearchExportExcel(
        _getSelectId[0]._id,
        brandId,
        modelId,
        startDate,
        endDate
      );
      getBrand(_getSelectId[0]._id);
      getModel(_getSelectId[0]._id);
    } else {
      setCategoryId("");
      setCategoryName("");
      onSearchProduct(null, null, null, startDate, endDate);
      onSearchExportExcel(null, null, null, startDate, endDate);
      setBrands([]);
      setModels([]);
      // getBrand(null)
      // getModel(null)
    }
  };

  const onSearchProduct = async (
    category,
    brand,
    model,
    _startDate,
    _endDate
  ) => {
    try {
      setIsLoading(true);
      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${_page}/?status=APPROVED&shopPortal=${id}&&${
          category ? `productCategory=${category}&&` : ""
        }${brand ? `brand=${brand}&&` : ""}${model ? `model=${model}&&` : ""}${
          _startDate && _endDate
            ? `startDate=${_startDate}&&endDate=${moment(
                moment(_endDate).add(1, "days")
              ).format("YYYY-MM-DD")}`
            : ""
        }`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setProductTotal(json?.total ?? 0);
          if (json?.total > 0) {
            _getArrayPageNumber(json?.total);
          }
          setShopProducts(json?.result ?? []);
        });
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onSearchExportExcel = async (
    category,
    brand,
    model,
    _startDate,
    _endDate
  ) => {
    try {
      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${10000}/page/${_page}/?status=APPROVED&shopPortal=${id}&&${
          category ? `productCategory=${category}&&` : ""
        }${brand ? `brand=${brand}&&` : ""}${model ? `model=${model}&&` : ""}${
          _startDate && _endDate
            ? `startDate=${_startDate}&&endDate=${moment(
                moment(_endDate).add(1, "days")
              ).format("YYYY-MM-DD")}`
            : ""
        }`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setExportExcelData(json?.result ?? []);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeBrand = async (e) => {
    const _getSelectId = brands.filter((item) => item._id === e?.target?.value);
    if (_getSelectId.length > 0) {
      setBrandId(_getSelectId[0]._id);
      setBrandName(_getSelectId[0].name);
      onSearchProduct(
        categoryId,
        _getSelectId[0]._id,
        modelId,
        startDate,
        endDate
      );
      onSearchExportExcel(
        categoryId,
        _getSelectId[0]._id,
        modelId,
        startDate,
        endDate
      );
    } else {
      setBrandId("");
      setBrandName("");
      onSearchProduct(categoryId, null, modelId, startDate, endDate);
      onSearchExportExcel(categoryId, null, modelId, startDate, endDate);
    }
  };
  const getBrand = async (category) => {
    try {
      await fetch(
        `${PRODUCT_BRAND}/?${category ? `category=${category}` : ""}`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setBrands(json ?? []);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const getModel = async (category) => {
    try {
      await fetch(
        `${PRODUCT_MODEL}/?${category ? `category=${category}` : ""}`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setModels(json ?? []);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };
  const _startDate = (e) => {
    setstartDate(e?.target?.value);
    onSearchProduct(categoryId, brandId, modelId, e?.target?.value, endDate);
    onSearchExportExcel(
      categoryId,
      brandId,
      modelId,
      e?.target?.value,
      endDate
    );
  };
  const _endDate = (e) => {
    setendDate(e?.target?.value);
    onSearchProduct(categoryId, brandId, modelId, startDate, e?.target?.value);
    onSearchExportExcel(
      categoryId,
      brandId,
      modelId,
      startDate,
      e?.target?.value
    );
  };
  // const _nextPage = async (page) => {
  //   try {
  //     setIsLoading(true);
  //     let reqUrl = `&&${categoryId ? `productCategory=${categoryId}&&` : ""}${brandId ? `brand=${brandId}&&` : ""}${modelId ? `model=${modelId}&&` : ""}${startDate && endDate ? `startDate=${startDate}&&endDate=${moment(moment(endDate).add(1, "days")).format("YYYY-MM-DD")}` : ""}`
  //     setPageNumber(page);
  //     let _url = `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${page}/?shopPortal=${match?.params?.id}&&status=APPROVED${reqUrl}`
  //     await fetch(_url, {
  //       method: "GET",
  //       headers: getTokken,
  //     }).then((response) => response.json()).then((json) => {
  //       setShopProducts(json?.result ?? []);
  //       if (json?.total > 0) {
  //         _getArrayPageNumber(json?.total)
  //       }
  //     });
  //     setIsLoading(false);
  //   } catch (error) {
  //     console.log("error: ", error);
  //     setIsLoading(false);
  //   }
  // };

  const _getArrayPageNumber = (_dataCustomerTotal) => {
    let rowPage = [];
    let _total = 0;
    if (_dataCustomerTotal % parseInt(_limit) !== 0) {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };

  const getUserAuth = async (token) => {
    await fetch(USERS + `/limit/10000/page/1`, {
      method: "GET",
      headers: token,
    })
      .then((response) => response.json())
      .then((json) => setStaffData(json?.results));
  };

  function logStaff(idStaff) {
    let data = staffData?.find((e) => e?.userauth?._id === idStaff);
    return `${data?.firstName || "-"}`;
  }

  const exportToExcel = async () => {
    if (exportExcelData) {
      const rows = await exportExcelData?.map((item, index) => ({
        index: index + 1,
        name: item?.productCategory?.name ?? "",
        brand: item?.brand?.name ?? "",
        description: item?.description ?? "",
        createdAt: moment(item?.createdAt).format("DD/MM/YYYY") ?? "",
      }));

      const titles = [
        "NO.",
        "Product Category",
        "Brand",
        "Product Description",
        "Created Date",
      ];

      const fileName = `All-Product-${moment().format("DDMMYYYYHHmmss")}.xlsx`;
      await downloadExcel(titles, rows, fileName);
    }
  };

  const handleChangeUnread = async (id) => {
    try {
      let dataToUpdate = {
        unReadNewApproved: false,
      };
      const res = await axios(
        `${ENPOINTE_API}/dealer-product/v2/update-unread-product-status/${id}`,
        {
          method: "put",
          headers: getTokken,
          data: dataToUpdate,
        }
      );

      if (res) console.log(res);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const _gotoProductDetail = (id) => {
    handleChangeUnread(id);
    history.push(`${ADMIN_PORTAL}/product/detail/${id}/page/${1}`);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: 18 }}>ສິນຄ້າທັງໝົດ</p>
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginBottom: 20,
          marginRight: 80,
          marginLeft: 10,
          //padding: 50,
          width: "95%",
          height: "auto",
          padding: "24px 0",
        }}
      >
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div className="row">
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Category</span>
                <select
                  name="productCategory"
                  className="btn-block form-control"
                  onChange={(e) => handleChangeCategory(e)}
                  value={categoryName}
                >
                  <option value="">All Categories</option>
                  {shopCategories.length > 0 &&
                    shopCategories.map((item, index) => (
                      <option key={index} value={item?.name}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Brand</span>
                <select
                  name="brand"
                  className="btn-block form-control"
                  onChange={(e) => handleChangeBrand(e)}
                  //   value={brandName}
                >
                  <option value="">All Brands</option>
                  {brands.length > 0 &&
                    brands.map((item, index) => (
                      <option key={index} value={item?._id}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              </div>
              <SpaceOnFilter />

              <div className="col-md-2">
                <span style={{ fontWeight: "bold" }}>Registered from</span>
                <input
                  type="date"
                  className="form-control col-12"
                  value={startDate}
                  onChange={(e) => _startDate(e)}
                />
              </div>
              <div className="col-md-2">
                <span style={{ fontWeight: "bold" }}>Register to</span>
                <input
                  type="date"
                  className="form-control col-12"
                  value={endDate}
                  onChange={(e) => _endDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-sm-12"
        style={{ ...BODY_DEALERLIST2, paddingTop: 24 }}
      >
        <div>
          <div className="col-md-12">
            <div
              className="row"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <p>All Products ({productTotal ?? 0})</p>
              </div>
              <div>
                {/* <ExcelButton name="Product" onClick={()=> exportToExcel()} /> */}
                <button
                  className="btn"
                  style={{
                    border: `1px solid ${COLOR_PRIMARY}`,
                    backgroundColor: "white",
                    color: COLOR_PRIMARY,
                  }}
                  onClick={() => exportToExcel()}
                >
                  <FontAwesomeIcon icon={faFileExcel} color={COLOR_PRIMARY} />
                  <span style={{ marginLeft: 10 }}>Download Excel</span>
                </button>
              </div>
            </div>
          </div>
          <div style={{ height: 24 }} />
          {isLoading ? (
            <AnimationLoading />
          ) : (
            <div>
              <div>
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">No.</th>
                      <th scope="col">Category</th>
                      <th scope="col">Brand</th>
                      <th scope="col">Description</th>
                      <th scope="col">Model</th>
                      <th scope="col">Price</th>
                      <th scope="col">Currency</th>
                      <th scope="col">Added date</th>
                      <th scope="col">Approve by</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shopProducts &&
                      shopProducts.map((product, index) => (
                        <tr
                          key={index}
                          className={`${
                            product?.unReadNewApproved ? "focus" : ""
                          }`}
                          onClick={() => _gotoProductDetail(product?._id)}
                        >
                          {/* <td>{index + 1 + parseInt(_limit) * parseInt(pageNumber - 1)}</td> */}
                          <th scope="row">
                            {index + 1 + parseInt(_limit) * parseInt(_page - 1)}
                          </th>
                          <td>{product?.productCategory?.name ?? "-"}</td>
                          <td>{product?.brand?.name ?? "-"}</td>
                          <td>{product?.description || "-"}</td>
                          <td>
                            {product?.model?.newName ??
                              product?.model?.name ??
                              "-"}
                          </td>
                          <td>
                            {product?.price ? thousand(product?.price) : "0"}
                          </td>
                          <td>{product?.currency || "-"}</td>
                          <td>{formatDate(product?.createdAt)}</td>
                          <td>{logStaff(product?.approvedBy)}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                <div className="row center">
                  {Pagination_helper(
                    productTotal ? productTotal : shopProducts?.length,
                    `/admin-portal/detail/${id}/tap/2`,
                    `${
                      startDate && endDate
                        ? `&&startDate=${startDate}&&endDate=${endDate}&&`
                        : ""
                    }`
                  )}
                </div>
                {/* <div className="row center">
                  {totalPage?.map((item, index) => {
                    return (
                      <button style={{
                        width: 30,
                        height: 30,
                        border: "solid 1px #816aae",
                        marginLeft: 2,
                        backgroundColor: parseInt(pageNumber) == index + 1 ? "#9999ff" : "#fff",
                        color: parseInt(pageNumber) == index + 1 ? "#fff" : "#000",
                      }} onClick={() => _nextPage(item)}
                        key={item}
                      >{item}</button>
                    )
                  })}
                </div> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SpaceOnFilter = styled.div`
  width: 40px;
`;
