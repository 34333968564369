const ADMIN_KEY = "EAON";
const URL_IMG_S3 =
  "https://aeon-lailaolab-bucket.s3-ap-southeast-1.amazonaws.com/";
const NUMBER_LIMIT_PAGE = 5;
const BACKGROUND_COLOR = "#F9F9F9";
const BACKGROUND_COLOR_DATA = "#FFFFFF";
const BUTTON_DANGER = "#FEBF0F";
const BUTTON_OUTLINE_DARK = "outline-dark";
const BUTTON_OUTLINE_DANGER = "outline-danger";
const BUTTON_OUTLINE_SECONDARY = "outline-secondary";
const BUTTON_SECONDARY = "secondary";
const THEME_COLOR = "#CC0000";
const MODAL_BUTTON_SIZE = 150;
const BUTTON_ADD_SIZE = 250;
const BUTTON_INDEX = 100;
const COLOR_TEXT_TITLE = "#909090";
const COLOR_BLACK_POINT = "#0D0D0D";
const COLOR_TEXT_USER_NAME = "#0660D4";
const FONT_HEADER_SIZE = 16;
export const COLOR_DELETE = "#E71C1C";
export const COLOR_PRIMARY = "#816AAE";
export const LIMIT = 40;
// ============================ EAON ==================================
const BODY = {
  width: "95%",
  minHeight: "95%",
  backgroundColor: "white",
  marginRight: 30,
  marginLeft: 32,
  paddingLeft: 32,
  paddingTop: 32,
  paddingRight: 32,
  paddingBottom: 40,
};
const BODY_TAB1 = {
  width: "95%",
  height: 170,
  backgroundColor: "white",
  marginBottom: 40,
  marginRight: 80,
  marginLeft: 32,
  padding: "40px 80px 0px 80px",
};
const BODY_TAB2 = {
  width: "95%",
  height: 170,
  backgroundColor: "white",
  marginBottom: 40,
  marginRight: 80,
  marginLeft: 32,
  padding: "40px 80px 0px 80px",
};
// ============================ EAON ==================================
const BODY_DEALERLIST = {
  //height: 250,
  backgroundColor: "white",
  marginBottom: 20,
  marginRight: 80,
  marginLeft: 30,
  //padding: 50,
  width: "95%",
};
const BODY_DEALERLIST2 = {
  height: "100%",
  backgroundColor: "white",
  marginBottom: 20,
  width: "100%",
};
const BODY_DISCOUNT_SHOPDETAIL = {
  minHeight: "200%",
  backgroundColor: "white",
  marginLeft: 30,
  width: "95%",
};
const BODY_CUSTOMER = {
  width: "95%",
  height: 250,
  backgroundColor: "white",
  marginBottom: 40,
  marginRight: 80,
  marginLeft: 32,
  padding: "40px",
};
const BODY_CUSTOMER2 = {
  width: "95%",
  height: "100%",
  backgroundColor: "white",
  marginBottom: 40,
  marginRight: 80,
  marginLeft: 32,
  padding: "40px",
};

const CANCEL_BUTTON = {
  zPosition: 100,
  width: 300,
  float: "right",
};
const CONFIRM_BUTTON = {
  zPosition: 100,
  width: 300,
};
const TITLE_HEADER = {
  paddingLeft: 50,
  backgroundColor: "#F9F9F9",
  width: "100%",
  // height: "100vh",
  // overflowY: "scroll",
};
const HEADER = {
  color: "#5C5C5C",
  backgroundColor: "#F9F9F9",
  height: 60,
  width: "100%",
  paddingTop: 30,
  paddingLeft: 35,
  fontSize: FONT_HEADER_SIZE,
};
const BODY11 = {
  width: "97%",
  minHeight: "100vh",
  backgroundColor: "white",
  marginRight: 32,
  paddingRight: 32,
  paddingBottom: 40,
};
const BODY1 = {
  minHeight: "100%",
  backgroundColor: "white",
  marginLeft: 30,
};

const BODY_DSTAFF_DETAIL = {
  minHeight: "150%",
  backgroundColor: "white",
  marginLeft: 30,
};
const BODY_SEARCH = {
  width: "97%",
  height: 294,
  backgroundColor: "white",
  marginBottom: 40,
  marginRight: 40,
  marginLeft: 32,
  padding: "40px 80px 0px 80px",
};
const BUTTON_GROUP = {
  display: "flex",
  justifyContent: "center",
  backgroundColor: "white",
  paddingTop: 32,
  paddingBottom: 24,
  paddingLeft: 55,
  marginLeft: 32,
  marginRight: 10,
};
const BUTTON_ADD = {
  width: BUTTON_ADD_SIZE,
  fontSize: 18,
  float: "right",
  marginRight: 15,
  color: "#FEBF0F",
  backgroundColor: "#372010",
};
const BODY_PROFILE_USER = {
  border: "1px solid " + COLOR_TEXT_TITLE,
  width: "100%",
  height: 480,
  display: "flex",
  flex: 1,
  alignItems: "center",
  paddingTop: 50,
  flexDirection: "column",
  borderRadius: 10,
  overflow: "hidden",
};
const CREATE_UPDATE_DATE = {
  paddingTop: 30,
  color: COLOR_TEXT_TITLE,
};
const GROUP_FORM = {
  width: "99% !important",
};
const BODY_HEADER_BUTTON = {
  display: "flex",
  flexDirection: "row",
  width: "95%",
  paddingBottom: 40,
};
const HEADER_TEXT = {
  width: "80%",
};
const BODY_DELETE_EDIT = {
  float: "right",
  width: BUTTON_ADD_SIZE,
};
const BUTTON_DELETE = {
  zPosition: 100,
  width: BUTTON_INDEX,
};
const BUTTON_EDIT = {
  zPosition: 100,
  width: BUTTON_INDEX,
  float: "right",
};
const TABLE_NONE_BORDER = {
  border: "none !important",
  width: "35%",
};
const PROFILE_BLOCK = {
  width: "30%",
};
const BODY_CONTAIN = {
  width: "70%",
};
const LIST_TITLE = {
  backgroundColor: "#F1F1F1",
  width: "100%",
  height: 40,
  paddingLeft: 10,
  paddingTop: 5,
  marginBottom: 10,
};
const FLEX_TO_ROW = {
  display: "flex",
  flexDirection: "row",
};
const ROW_TITLE = {
  width: "25%",
  color: COLOR_TEXT_TITLE,
};
const ROW_DETAIL = {
  width: "75%",
};
const BODY_ADD = {
  display: "flex",
  flexDirection: "row",
};
const TITLE_HEADER_ADD = {
  paddingLeft: 55,
  paddingTop: 15,
  backgroundColor: "#F9F9F9",
  width: "100%",
  height: "100vh",
  overflowY: "scroll",
};
const USERROLE = {
  ALL: "ທັງສອງ",
  PROVIDER: "ຜູ້ສະໜອງ",
  CONSUMMER: "ຜູ້ຈ້າງ",
};

const DIV_NAV = {
  paddingLeft: 32,
};
const NAV = {
  backgroundColor: "#F9F9F9",
  marginTop: -20,
  paddingTop: 10,
};
const IMG_ADD_BOX = {
  zIndex: 0,
  position: "absolute",
  marginTop: -205,
  marginLeft: 5,
};
const BOX_ADD_IMG_SIZE = {
  width: 200,
  height: 200,
};
const BUTTON_ADD_MIG = {
  fontSize: 50,
  color: "white",
};
const BUTTON_ADD_MIG2 = {
  fontSize: 50,
  color: "#8F8F8F",
};
const BUTTON_ADD_MIG1 = {
  fontSize: 40,
  color: "white",
};
// Ratting style
const HEADING_1 = {
  color: "#5C5C5C",
  fontSize: 25,
  marginTop: 20,
  marginBottom: 25,
};
const POINT_BOX = {
  marginTop: 20,
  marginBottom: 20,
  paddingTop: 10,
  width: 280,
  height: 70,
  color: "#909090",
  fontSize: 35,
  textAlign: "center",
  border: "1px solid " + COLOR_TEXT_TITLE,
  borderRadius: 8,
};
const BUTTON_UPDATE = {
  width: 160,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 4,
  border: "solid 1px #909090",
  float: "right",
};
const BTN_DEFAULT = {
  width: 160,
  paddingTop: 5,
  paddingBottom: 5,
  paddingLeft: 10,
  paddingRight: 10,
  borderRadius: 4,
  border: "solid 1px #909090",
  justifyContent: "center",
  alignItems: "center",
  marginLeft: "5%",
  marginRight: "5%",
};
const ROW_BODY = {
  width: "97%",
  minHeight: "AUTO",
  backgroundColor: "white",
  marginRight: 32,
  marginLeft: 32,
  marginBottom: 40,
  paddingLeft: 32,
  paddingTop: 32,
  paddingRight: 32,
  paddingBottom: 40,
};

const POINT_IMG = {
  width: 50,
  height: 50,
  borderRadius: 4,
  backgroundColor: "#ccc",
};
const LINE_BLOCK = {
  display: "inline-block",
  textAlign: "center",
  width: "100%",
  marginTop: 20,
  margingBottom: 20,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};
const LINE_BLOCKS = {
  display: "inline-block",
  textAlign: "center",
  width: "100%",
  marginTop: 20,
  margingBottom: 20,
  display: "flex",
};
const BOX_CONTENT = {
  display: "inline-block",
  width: "100%",
  height: "auto",
  paddingLeft: "15%",
  paddingRight: "15%",
  paddingTop: "3%",
  paddingBottom: "3%",
  border: "solid 1px #909090",
  borderRadius: 8,
};
const TABLE_ROW = {
  verticalAlign: "middle",
  cursor: "pointer",
  padding: "auto",
};
const STATUS_BOX = {
  width: 120,
  padding: 5,
  border: "solid 1px #dddddd",
  textAlign: "center",
  borderRadius: 15,
};
const IMG_CIRCLE = {
  border: "7px double #f1f1f1",
  width: 240,
  height: 240,
  backgroundColor: "white",
  borderRadius: "50%",
  justifyContent: "center",
};
const HEADER_LAYOUT = { marginLeft: 40, marginTop: 20 };
const BACK_GROUND_COLOR = { backgroundColor: "#F1F1F1" };
const MARGIN_TOP = { marginTop: -15 };
const LAYOUT_BACKGROUND_IMAGE = {
  border: "1px solid #bababa",
  width: 302,
  height: 202,
  backgroundColor: "white",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};
const FONT_REPORT_POINT = {
  color: "#0E60D4",
  fontSize: 20,
  fontWeight: "bold",
};

const customStyles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isSelected ? "#FFF" : styles.color,
    backgroundColor: state.isSelected ? "#86B7FE" : styles.color,
    borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
    "&:hover": {
      color: "#FFF",
      backgroundColor: "#86B7FE",
    },
  }),
  control: (styles, state) => ({
    ...styles,
    boxShadow: state.isFocused ? "0 0 0 .25rem rgba(13, 110, 253, .25)" : 0,
    borderColor: state.isFocused ? "#86B7FE" : "#CED4D9",
    "&:hover": {
      borderColor: state.isFocused ? "#D0EAE2" : "#CED4D9",
    },
  }),
};

export {
  ADMIN_KEY,
  BODY_TAB1,
  BODY_TAB2,
  URL_IMG_S3,
  BODY_DEALERLIST2,
  BODY_DSTAFF_DETAIL,
  BODY_CUSTOMER2,
  BODY_DISCOUNT_SHOPDETAIL,
  BODY_DEALERLIST,
  FONT_REPORT_POINT,
  NUMBER_LIMIT_PAGE,
  BACKGROUND_COLOR,
  BACKGROUND_COLOR_DATA,
  BUTTON_DANGER,
  BUTTON_OUTLINE_DARK,
  BUTTON_OUTLINE_DANGER,
  THEME_COLOR,
  TITLE_HEADER,
  BODY,
  BODY1,
  BODY11,
  BOX_ADD_IMG_SIZE,
  BUTTON_ADD_MIG,
  BUTTON_ADD_MIG2,
  FLEX_TO_ROW,
  ROW_TITLE,
  ROW_DETAIL,
  TABLE_NONE_BORDER,
  BODY_SEARCH,
  BODY_CUSTOMER,
  BUTTON_GROUP,
  BODY_CONTAIN,
  LIST_TITLE,
  HEADER,
  COLOR_BLACK_POINT,
  MODAL_BUTTON_SIZE,
  USERROLE,
  BUTTON_ADD,
  DIV_NAV,
  NAV,
  TITLE_HEADER_ADD,
  BODY_PROFILE_USER,
  CREATE_UPDATE_DATE,
  COLOR_TEXT_TITLE,
  COLOR_TEXT_USER_NAME,
  BODY_HEADER_BUTTON,
  HEADER_TEXT,
  BODY_DELETE_EDIT,
  BUTTON_DELETE,
  BUTTON_EDIT,
  FONT_HEADER_SIZE,
  PROFILE_BLOCK,
  IMG_ADD_BOX,
  GROUP_FORM,
  CANCEL_BUTTON,
  BODY_ADD,
  BUTTON_INDEX,
  BUTTON_OUTLINE_SECONDARY,
  BUTTON_SECONDARY,
  HEADING_1,
  POINT_BOX,
  ROW_BODY,
  BUTTON_UPDATE,
  POINT_IMG,
  BTN_DEFAULT,
  LINE_BLOCK,
  LINE_BLOCKS,
  BOX_CONTENT,
  TABLE_ROW,
  STATUS_BOX,
  IMG_CIRCLE,
  HEADER_LAYOUT,
  BACK_GROUND_COLOR,
  MARGIN_TOP,
  LAYOUT_BACKGROUND_IMAGE,
  customStyles,
};
