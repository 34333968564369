import React from 'react'
import {
    Modal,
} from 'react-bootstrap'
export default function PromotionSlideEditnumber({
    showAddConfirmModal,
    _handleShowAddConfirmModalClose,
}) {
    return (
        <Modal
            show={showAddConfirmModal}
            onHide={_handleShowAddConfirmModalClose}
            size='lg'>
            <Modal.Title style={{ textAlign: 'center', paddingTop: 20 }}>
            </Modal.Title>
            <Modal.Body
                style={{
                    marginLeft: 50,
                    marginRight: 5,
                    // color: Consts.SECONDARY_COLOR0,
                    padding: 50,
                    paddingTop: 0
                }}>
                <div>

                    <div className="row">
                        <div className="col-md-4">
                            <button className="btn button-white">ລີເຊັດລຳດັບ</button>
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                            <button className="btn button-white" onClick={() => _handleShowAddConfirmModalClose()}>ຍົກເລີກ</button>
                            <button className="btn btn-app right">ຢືນຢັນການແກ້ໄຂ</button>
                        </div>
                    </div>
                    <div style={{ height: 20 }}></div>
                    <table className="table table-hover">
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ຮູບພາບ</th>
                                <th scope="col">ID</th>
                                <th scope="col">ຫົວຂໍ້</th>
                                <th scope="col">ໄລຍະເວລາໂຊ ວ/ດ/ປ</th>
                                <th scope="col">ສະຖານະ</th>
                                <th scope="col">ເວລາສ້າງ</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                            </tr>
                            <tr>
                                <th scope="row">1</th>
                                <td>Mark</td>
                                <td>Otto</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                                <td>@mdo</td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </Modal.Body>
        </Modal>

    )
}
