import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import AnimationLoading from "../../../helper/loading.js"
import queryString from 'query-string';
import moment from 'moment';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
	faSyncAlt,
	faTimes
} from "@fortawesome/free-solid-svg-icons"
import { Modal, Button, Image } from 'react-bootstrap'
import {
	TITLE_HEADER,
	HEADER,
	BODY_DEALERLIST2,
	BODY_DEALERLIST,
	LIMIT
} from "../../../consts"
import { PRODUCT_MODEL } from '../../../consts/api'
import { MODEL } from '../../../consts/router'
import { getLocalData } from '../../../consts/tokken'

import { formatDate } from '../../../common/super'
import Lottie from "react-lottie";

export default function ModelDetail() {
	const { history, location, match } = useReactRouter()
	const modelId = match.params.id;

	const [isLoading, setIsLoading] = useState(false)
	const [model, setModel] = useState({})
	const [isDeleteShow, setIsDeleteShow] = useState(false)
	const [isPopupShow, setIsPopupShow] = useState(false);
	const [isPopupSuccess, setIsPopupSuccess] = useState(false);
	const [getTokken, setgetTokken] = useState("")

	useEffect(() => {
		const fetchData = async () => {
			const _localData = await getLocalData()
			if (_localData) {
				setgetTokken(_localData)
				getProductDatas(_localData);
			}
		}
		fetchData();
	}, [])

	const getProductDatas = async (token) => {
		try {
			setIsLoading(true)
			await fetch(`${PRODUCT_MODEL}/${modelId}`, {
				method: "GET",
				headers: token
			}).then(response => response.json()).then(json => {
				console.log(json)
				setModel(json)
			});
			setIsLoading(false)
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}

	const onDeleteModel = async () => {
		try {
			setIsLoading(true)
			const deleteProduct = await axios(`${PRODUCT_MODEL}/delete/${modelId}`, {
				method: "POST",
				headers: getTokken
			})
			setIsLoading(false)
			if (deleteProduct?.data?.message == "SUCCESSFULLY") {
				setIsPopupSuccess(true)
				setIsPopupShow(true)
				setTimeout(() => {
					setIsPopupShow(false)
					history.push(`${MODEL}/limit/40/page/1`)
				}, 2000);
			} else {
				setIsPopupSuccess(false)
				setIsPopupShow(true)
			}
		} catch (error) {
			console.log("error: ", error)
			setIsLoading(false)
		}
	}

	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p><span onClick={() => history.push(`${MODEL}/limit/40/page/1`)} style={{ textDecoration: 'underline', color: "blue", cursor: "pointer" }}>Product Description Template Management</span> » Product Description</p>
			</h6>
			<div className="col-sm-12" style={{ ...BODY_DEALERLIST, height: "100%", paddingTop: 16 }}>
				<div className="tab-content" id="myTabContent">
					<div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
						<div className="row" style={{ width: "100%", display: "flex", justifyContent: "space-between", marginBottom: 16 }}>
							<p style={{ fontWeight: "bold", fontSize: 18 }}>Product Description</p>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<button onClick={() => history.push(`${MODEL}/edit/${modelId}`)} type="button" className="btn btn-outline-secondary" style={{ float: "right", width: 100 }}>Edit</button>
								<div style={{ width: 16 }} />
								<button onClick={() => setIsDeleteShow(true)} type="button" className="btn btn-outline-danger" style={{ float: "right", width: 100 }}>Delete</button>
							</div>
						</div>
						{isLoading ? <AnimationLoading /> : <div className="row" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
							<div className="col-md-9" style={{ paddingLeft: 24 }}>
								<div style={{ height: 40, backgroundColor: "#eee", width: "100%", paddingTop: 8, paddingLeft: 16 }}>
									<p style={{ fontWeight: "bold" }}>ຂໍ້ມູນທົ່ວໄປ</p>
								</div>
								<div style={{ height: 16 }} />
								<div className="row">
									<div className="col-md-3">
										<p>Product Category</p>
									</div>
									<div>
										<p style={{ backgroundColor: '#eee', paddingLeft: 16, paddingRight: 16, borderRadius: 8 }}>{model?.category?.name ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>Code</p>
									</div>
									<div>
										<p>{model?.code ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>Name</p>
									</div>
									<div>
										<p>{model?.name ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>New name</p>
									</div>
									<div>
										<p>{model?.newName ?? "-"}</p>
									</div>
								</div>
								<div className="row">
									<div className="col-md-3">
										<p>ວັນທີ່ເພີ່ມ</p>
									</div>
									<div>
										<p>{moment(model.createdAt).format("DD/MM/YYYY HH:mm")}</p>
									</div>
								</div>
							</div>
						</div>}
					</div>
				</div>
			</div>

			<Modal centered show={isDeleteShow} onHide={() => setIsDeleteShow(false)}>
				<Modal.Body >
					<div style={{ padding: 10 }}>
						<div style={{ fontWeight: "bold" }}>Does delete confirm?</div>
						<hr />
						<p>Do you really want to delete this model?</p>
					</div>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-secondary" onClick={() => setIsDeleteShow(false)}>
						Cancel
					</Button>
					<Button style={{ backgroundColor: "#816AAE", borderColor: "#816AAE" }} onClick={() => onDeleteModel()}>Confirm</Button>
				</Modal.Footer>
			</Modal>

			<Modal centered show={isPopupShow} onHide={() => setIsPopupShow(false)}>
				<Modal.Body>
					{isPopupSuccess ? <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Successfully</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets9.lottiefiles.com/private_files/lf30_imtpbtnl.json",
							}}
							width={300}
							height={300} />
					</div> : <div style={{ padding: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
						<div style={{ fontWeight: "bold", fontSize: 24 }}>Delete Failed</div>
						<div style={{ fontSize: 18 }}>Oop!, Some thing when wrong, please try again</div>
						<Lottie
							options={{
								loop: true,
								autoplay: true,
								path: "https://assets5.lottiefiles.com/packages/lf20_gzlupphk.json",
							}}
							width={300}
							height={300} />
					</div>}
				</Modal.Body>
				{!isPopupSuccess && <Modal.Footer>
					<Button style={{ backgroundColor: "#816AAE" }} onClick={() => setIsPopupShow(false)}>OK</Button>
				</Modal.Footer>}
			</Modal>

		</div>
	)
}
