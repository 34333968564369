// ========== api CRUD ===========
export const URL_PHOTO_AW3 =
  "https://aeon-lailaolab-bucket.s3-ap-southeast-1.amazonaws.com/";
export const KEY_API_GOOGLEMAP = "AIzaSyBRqXyZHvFiVgoPhHpunA9-R1z2X2pJ74M";
// const ENPOINTE = "http://localhost:8000";
const ENPOINTE = "https://aeon-api-v1-5.lailaolab.com:8000"; // Production
// const ENPOINTE = "http://3.0.175.253:8000"; //Dev

// const ENPOINTE = "https://aeon-api.lailaolab.com:8000";
// ========================+++>>>>>>>>>>>>>>>>>>>>>>>
export const SIGIN_ADMIN = `${ENPOINTE}/api/admin-auths/signin`;
export const STORE_API = `${ENPOINTE}/api/stores/`;
export const PRESIGNED_URL = `${ENPOINTE}/api/aws/images/presigned-url`;
// staff
export const STAFFS_LIST = `${ENPOINTE}/api/staffs`;
export const STAFFS_DETAIL = `${ENPOINTE}/api/staffs/`;
export const ADD_STAFFS = `${ENPOINTE}/api/staffs`;
export const ADD_SHOP_PORTAL = `${ENPOINTE}/api/shop-portal/create`;
export const GET_MANY_SHOP_PORTAL = `${ENPOINTE}/api/shop-portal`;
export const EDIT_SHOP_PORTAL = `${ENPOINTE}/api/shop-portal/edit`;
// Shop Product
export const SHOP_PRODUCT_API = `${ENPOINTE}/api/shop-product`;
export const GET_ALL_DEALER_PRODUCT = `${ENPOINTE}/api/dealer-product/v2`;
// Brand
export const PRODUCT_BRAND = `${ENPOINTE}/api/brand`;
// Model
export const PRODUCT_MODEL = `${ENPOINTE}/api/model`;
// Product
export const PRODUCT_API = `${ENPOINTE}/api/product`;
export const GET_PRODUCT_BY_ID = `${ENPOINTE}/api/dealer-product/v2/`;
// Dealer
export const DEALER_FOR_SHOP_PORTTAL = `${ENPOINTE}/api/dealer`;
export const UPDATE_DEALER_STATUS = `${ENPOINTE}/api/dealer-product/v2/edit-status`;
export const UPDATE_DEALER_STATUS_MANY = `${ENPOINTE}/api/dealer-product/v2/edit-status`;
export const UPDATE_DEALER_REJECT_ONE = `${ENPOINTE}/api/dealer-product/v2/rejected/`;
export const DELETE_DEALER_REJEDT_ONE = `${ENPOINTE}/api/dealer-product/v2/delete/`;
export const DELETE_DEALER_ALL_REJECT = `${ENPOINTE}/api/dealer-product/v2/delete-all`;

// dealer shop
export const STOREDEALER = `${ENPOINTE}/api/stores/`;
export const DEALER_SHOP = `${ENPOINTE}/api/stores/store-type/DEALERSHOP`;
export const DEALER_ADD = `${ENPOINTE}/api/stores/dealler-shop`;
export const DELETE_DEALER_SHOP = `${ENPOINTE}/api/stores/`;
// disCount shop
export const DISCOUNT_SHOP = `${ENPOINTE}/api/stores/store-type/DISCOUNTSHOP`;
export const STORE_DISCOUNT_ADD = `${ENPOINTE}/api/stores/dealler-shop`;
// =======> cateGory =======>
export const CATEGORY_STORE = `${ENPOINTE}/api/store-categories/`;
// history
export const HISTORY_LOGIN = `${ENPOINTE}/api/user-logins`;
export const HISTORY_LOGIN_LAST = `${ENPOINTE}/api/user-logins/last-login`;
export const HISTORY_LOGIN_LOCATION = `${ENPOINTE}/api/users/user/location-traking`;
// user
export const USERS = `${ENPOINTE}/api/users/`;
// custommer
export const CUSTOMERS = `${ENPOINTE}/api/dmz/customers/`;
//  setting
export const SETTING_STORE_CATEGORY = `${ENPOINTE}/api/store-categories`;
// promotion
export const PROMOTION_DATA_LIST = `${ENPOINTE}/api/promotions/`;
export const PROMOTION_DATA_SLIDE = `${ENPOINTE}/api/promotions/SLIDE`;
export const PROMOTION_CREATE = `${ENPOINTE}/api/promotions`;
//notify
export const NOTIFY_DATA_LIST = `${ENPOINTE}/api/notify/`;
export const NOTIFY_CREATE = `${ENPOINTE}/api/notify/create`;
export const NOTIFY_UPDATE = `${ENPOINTE}/api/notify`;
// popUp
export const GET_POP_UP = `${ENPOINTE}/api/pop-ups`;
// isso report
export const GET_ISSU_REPORT = `${ENPOINTE}/api/issue-reports/`;

export const DELETE_CUSTOMER = `${ENPOINTE}/api/user-auths/delete/`;

export const ENPOINTE_API = `${ENPOINTE}/api`;
