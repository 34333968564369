import React, { useState } from "react";
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import useReactRouter from "use-react-router";
// Custom import
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Login from "../pages/login/components/Login";
import Navbar from "../Layout/Navbar";
import CustomSideNav from "../Layout/SideNav";
import styled from "styled-components";
// promotion
import PromotionList from "../pages/promotion/components/PromotionList";
import PromotionAdd from "../pages/promotion/components/PromotionAdd";
import PromotionSlideAdd from "../pages/promotion/components/PromotionSlideAdd";
import PromotionPopupAdd from "../pages/promotion/components/PromotionPopupAdd";
import PromotionPopupEdit from "../pages/promotion/components/PromotionPopupEdit";
import PromotionSlideDetail from "../pages/promotion/components/PromotionSlideDetail ";
import PromotionEdit from "../pages/promotion/components/PromotionEdit";
import PromotionDetail from "../pages/promotion/components/PromotionDetail";
import SlideList from "../pages/promotion/components/SlideList";
import PopUpList from "../pages/promotion/components/PopUpList";
// Customer
import CustomerList from "../pages/customer/components/CustomerList";
import CustomerDetail from "../pages/customer/components/CustomerDetail";
import CustomerHistory from "../pages/customer/components/CustomerHistory";
// dealer
import Store from "../pages/store/components/DealerList";
import DiscountList from "../pages/store/components/DiscountList";
import DiscountShopDetail from "../pages/store/components/DiscountShopDetail";
import DealerShopDetail from "../pages/store/components/DealerShopDetail";
import DealerShopAdd from "../pages/store/components/DealerShopAdd";
import DiscountShopAdd from "../pages/store/components/DiscountShopAdd";
import DealerShopEdit from "../pages/store/components/DealerShopEdit";
import DiscountShopEdit from "../pages/store/components/DiscountShopEdit";
// admin portal
import AdminPortal from "../pages/adminPortal/components/AdminPortalList";
import AdminPortalAdd from "../pages/adminPortal/components/AdminPortalAdd";
import AdminPortalEdit from "../pages/adminPortal/components/AdminPortalEdit";
import AdminPortalDetail from "../pages/adminPortal/components/AdminPortalDetail";

import AllProductRequesting from "../pages/adminPortal/components/AllProductRequesting";

// Product
import Product from "../pages/product/components/ProductList";
import ProductAdd from "../pages/product/components/ProductAdd";
import ProductEdit from "../pages/product/components/ProductEdit";
import ProductDetail from "../pages/product/components/ProductDetail";
import ProductDuplicate from "../pages/product/components/ProductDuplicate";
// Notification
import NotificationList from "../pages/notification/components/NotificationList";
import NotificationAdd from "../pages/notification/components/NotificationAdd";
import NotificationDetail from "../pages/notification/components/NotificationDetail";
import NotificationEdit from "../pages/notification/components/NotificationEdit";
// IssueReportList
import IssueReportList from "../pages/issueReport/components/IssueReportList";
import IssueReportDetail from "../pages/issueReport/components/IssueReportDetail";
// Staff
import StaffList from "../pages/staff/components/StaffList";
import StaffDetail from "../pages/staff/components/StaffDetail";
import StaffAdd from "../pages/staff/components/StaffAdd";
import StaffEdit from "../pages/staff/components/StaffEdit";
// CaculartorList
import SettingList from "../pages/setting/component/SettingList";
import ConfigCaculartor from "../pages/setting/component/ConfigCaculartor";
import SettingCategoryDetail from "../pages/setting/component/SettingCategoryDetail";
import SettingCategoryEdit from "../pages/setting/component/SettingCategoryEdit";
import SettingCategoryAdd from "../pages/setting/component/SettingCategoryAdd";
// LocationList
import LocationList from "../pages/location/component/LocationList";
import LocationLastlogin from "../pages/location/component/LocationLastlogin";
import HistoryLastLogin from "../pages/location/component/HistoryLastLogin";
// Dashboard
import Dashboard from "../pages/dashboard/components/Dashboard";
// Model
import ModelList from "../pages/model/components/ModelList";
import ModelAdd from "../pages/model/components/ModelAdd";
import ModelDetail from "../pages/model/components/ModelDetail";
import ModelEdit from "../pages/model/components/ModelEdit";
import ModelDuplicate from "../pages/model/components/ModelDuplicate";
// Import page const
import {
  DASHBOARD,
  // custommer
  CUSTOMMER_LIST,
  CUSTOMMER_DETAIL,
  CUSTOMMER_HISTORY,
  // promotion
  PROMOTION_LIST,
  PROMOTION_ADD,
  PROMOTION_SLIDE_ADD,
  PROMOTION_POPUP_ADD,
  PROMOTION_POPUP_EDIT,
  PROMOTION_SLIDE_DETAIL,
  PROMOTION_EDIT,
  PROMOTION_DETAIL,
  PROMOTION_SLIDE_LIST,
  PROMOTION_POPUP_LIST,
  // Store
  STORE,
  STORE_DISCOUNT_LIST,
  DISCOUNT_SHOP_DETAIL,
  DEALER_SHOP_DETAIL,
  DEALER_SHOP_ADD,
  DISCOUNT_SHOP_ADD,
  DEALER_SHOP_EDIT,
  DISCOUNT_SHOP_EDIT,
  // notification
  NOTIFICATION_LIST,
  NOTIFICATION_ADD,
  NOTIFICATION_DETAIL,
  NOTIFICATION_EDIT,
  // issueReporT
  ISSUE_REPORT_LIST,
  ISSUE_REPORT_DETAIL,
  // staff
  STAFF_LIST,
  STAFF_DETAIL,
  STAFF_ADD,
  STAFF_EDIT,
  // Setting
  SETTING_LIST,
  CACULARTOR_LIST,
  CACULARTOR_DETAIL,
  CACULARTOR_EDIT,
  CACULARTOR_ADD,
  // locationList
  LOCATION_LIST,
  LOCATION_LAST_LOGIN,
  LOCATION_LAST_LONG,
  // Admin Portal
  ADMIN_PORTAL,
  PRODUCT,
  MODEL,
  ALL_PRODUCT_REQUESTING,
  NOTICE_LIST,
  NOTIFY_LIST,
  NOTIFY_ADD,
  NOTIFY_DETAIL,
  NOTIFY_EDIT,
} from "../consts/router";
import ProductWaiting from "../pages/adminPortal/components/ProductWaiting";
import ProductWaitingDetail from "../pages/adminPortal/components/ProductWaitingDetail";
import NotifyList from "../pages/promotion/components/NotifyList";
import NotifyAdd from "../pages/promotion/components/NotifyAdd";
import NotifyDetail from "../pages/promotion/components/NotifyDetail";
import NotifyEdit from "../pages/promotion/components/NotifyEdit";
// =======
const Main = styled.main`
  /* position: relative; */
  overflow: hidden;
  transition: all 0.15s;
  margin-left: ${(props) => (props.expanded ? 160 : 5)}px;
`;
function Routes() {
  const [expanded, setExpanded] = useState(false);
  const _onToggle = (exp) => {
    setExpanded(exp);
  };
  return (
    <Router>
      <Switch>
        {/* Before login routes */}
        <PublicRoute exact path="/" component={Login} />
        {/* Private Route: After login routes (has SideNav and NavBar) */}
        <Route
          render={({ location, history }) => (
            <React.Fragment>
              {/* sidenav */}
              <Navbar />
              <CustomSideNav
                location={location}
                history={history}
                onToggle={(exp) => _onToggle(exp)}
              />
              <Main expanded={expanded}>
                <div
                  style={{
                    marginTop: 60,
                    backgroundColor: "#eee",
                    minHeight: "100vh",
                  }}
                >
                  {/* Customer */}
                  <PrivateRoute
                    path={CUSTOMMER_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <CustomerList />}
                  />
                  <PrivateRoute
                    path={CUSTOMMER_DETAIL + ":id"}
                    exact
                    component={(props) => <CustomerDetail />}
                  />
                  <PrivateRoute
                    path={CUSTOMMER_HISTORY + ":id/limit/:limit/page/:page"}
                    exact
                    component={(props) => <CustomerHistory />}
                  />
                  {/* Notice */}
                  <PrivateRoute
                    path={NOTIFY_LIST + "/limit/:limit/page/:page"}
                    component={(props) => <NotifyList />}
                  />
                  <PrivateRoute
                    path={NOTIFY_ADD}
                    exact
                    component={(props) => <NotifyAdd />}
                  />
                  <PrivateRoute
                    path={NOTIFY_DETAIL + ":id"}
                    exact
                    component={(props) => <NotifyDetail />}
                  />
                  <PrivateRoute
                    path={NOTIFY_EDIT + ":id"}
                    exact
                    component={(props) => <NotifyEdit />}
                  />
                  {/* Promotion */}
                  <PrivateRoute
                    // path={PROMOTION_LIST}
                    path={PROMOTION_LIST + "/limit/:limit/page/:page"}
                    component={(props) => <PromotionList />}
                  />
                  <PrivateRoute
                    path={PROMOTION_ADD}
                    exact
                    component={(props) => <PromotionAdd />}
                  />
                  <PrivateRoute
                    path={PROMOTION_SLIDE_ADD}
                    exact
                    component={(props) => <PromotionSlideAdd />}
                  />
                  <PrivateRoute
                    path={PROMOTION_POPUP_ADD}
                    exact
                    component={(props) => <PromotionPopupAdd />}
                  />
                  <PrivateRoute
                    path={PROMOTION_POPUP_EDIT}
                    exact
                    component={(props) => <PromotionPopupEdit />}
                  />
                  <PrivateRoute
                    path={PROMOTION_SLIDE_DETAIL + ":id"}
                    exact
                    component={(props) => <PromotionSlideDetail />}
                  />
                  <PrivateRoute
                    path={PROMOTION_EDIT + ":id"}
                    exact
                    component={(props) => <PromotionEdit />}
                  />
                  <PrivateRoute
                    path={PROMOTION_DETAIL + ":id"}
                    exact
                    component={(props) => <PromotionDetail />}
                  />
                  <PrivateRoute
                    path={PROMOTION_SLIDE_LIST}
                    exact
                    component={(props) => <SlideList />}
                  />
                  <PrivateRoute
                    path={PROMOTION_POPUP_LIST}
                    exact
                    component={(props) => <PopUpList />}
                  />
                  {/* dealer */}
                  <PrivateRoute
                    path={STORE + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <Store />}
                  />
                  <PrivateRoute
                    path={STORE_DISCOUNT_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <DiscountList />}
                  />
                  <PrivateRoute
                    path={DISCOUNT_SHOP_DETAIL + ":id"}
                    exact
                    component={(props) => <DiscountShopDetail />}
                  />
                  <PrivateRoute
                    path={DEALER_SHOP_DETAIL + ":id"}
                    exact
                    component={(props) => <DealerShopDetail />}
                  />
                  <PrivateRoute
                    path={DEALER_SHOP_ADD}
                    exact
                    component={(props) => <DealerShopAdd />}
                  />
                  <PrivateRoute
                    path={DISCOUNT_SHOP_ADD}
                    exact
                    component={(props) => <DiscountShopAdd />}
                  />
                  <PrivateRoute
                    path={DEALER_SHOP_EDIT + ":id"}
                    exact
                    component={(props) => <DealerShopEdit />}
                  />
                  <PrivateRoute
                    path={DISCOUNT_SHOP_EDIT + ":id"}
                    exact
                    component={(props) => <DiscountShopEdit />}
                  />
                  {/* Notification */}
                  <PrivateRoute
                    path={NOTIFICATION_LIST}
                    exact
                    component={(props) => <NotificationList />}
                  />
                  <PrivateRoute
                    path={NOTIFICATION_ADD}
                    exact
                    component={(props) => <NotificationAdd />}
                  />
                  <PrivateRoute
                    path={NOTIFICATION_DETAIL + ":id"}
                    exact
                    component={(props) => <NotificationDetail />}
                  />
                  <PrivateRoute
                    path={NOTIFICATION_EDIT + ":id"}
                    exact
                    component={(props) => <NotificationEdit />}
                  />
                  {/* IssueReportList */}
                  <PrivateRoute
                    path={ISSUE_REPORT_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <IssueReportList />}
                  />
                  <PrivateRoute
                    path={ISSUE_REPORT_DETAIL + ":id"}
                    exact
                    component={(props) => <IssueReportDetail />}
                  />
                  {/* StaffList */}
                  <PrivateRoute
                    path={STAFF_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <StaffList />}
                  />
                  <PrivateRoute
                    path={STAFF_DETAIL + ":id"}
                    exact
                    component={(props) => <StaffDetail />}
                  />
                  <PrivateRoute
                    path={STAFF_ADD}
                    exact
                    component={(props) => <StaffAdd />}
                  />
                  <PrivateRoute
                    path={STAFF_EDIT + ":id"}
                    exact
                    component={(props) => <StaffEdit />}
                  />
                  {/* srtting */}
                  <PrivateRoute
                    path={SETTING_LIST}
                    exact
                    component={(props) => <SettingList />}
                  />
                  <PrivateRoute
                    path={CACULARTOR_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <ConfigCaculartor />}
                  />
                  <PrivateRoute
                    path={CACULARTOR_DETAIL + ":id"}
                    exact
                    component={(props) => <SettingCategoryDetail />}
                  />
                  <PrivateRoute
                    path={CACULARTOR_EDIT + ":id"}
                    exact
                    component={(props) => <SettingCategoryEdit />}
                  />
                  <PrivateRoute
                    path={CACULARTOR_ADD}
                    exact
                    component={(props) => <SettingCategoryAdd />}
                  />
                  {/* LocationList */}
                  <PrivateRoute
                    path={LOCATION_LIST + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <LocationList />}
                  />
                  <PrivateRoute
                    path={LOCATION_LAST_LOGIN + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <LocationLastlogin />}
                  />
                  <PrivateRoute
                    path={LOCATION_LAST_LONG + ":id/limit/:limit/page/:page"}
                    exact
                    component={(props) => <HistoryLastLogin />}
                  />
                  {/* Admin Portal */}
                  <PrivateRoute
                    path={ADMIN_PORTAL + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <AdminPortal />}
                  />
                  <PrivateRoute
                    path={ALL_PRODUCT_REQUESTING + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <AllProductRequesting />}
                  />

                  <PrivateRoute
                    path={ADMIN_PORTAL + "/add"}
                    exact
                    component={(props) => <AdminPortalAdd />}
                  />
                  <PrivateRoute
                    path={ADMIN_PORTAL + "/edit/:id"}
                    exact
                    component={(props) => <AdminPortalEdit />}
                  />
                  <PrivateRoute
                    path={
                      ADMIN_PORTAL +
                      "/detail/:id/tap/:tap/limit/:limit/page/:page"
                    }
                    exact
                    component={(props) => <AdminPortalDetail />}
                  />
                  <PrivateRoute
                    path={ADMIN_PORTAL + "/product/detail/:id/page/:page"}
                    exact
                    component={(props) => <ProductWaitingDetail />}
                  />
                  {/* Product */}
                  <PrivateRoute
                    path={PRODUCT + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <Product />}
                  />
                  <PrivateRoute
                    path={PRODUCT + "/add"}
                    exact
                    component={(props) => <ProductAdd />}
                  />
                  <PrivateRoute
                    path={PRODUCT + "/edit/:id"}
                    exact
                    component={(props) => <ProductEdit />}
                  />
                  <PrivateRoute
                    path={PRODUCT + "/detail/:id"}
                    exact
                    component={(props) => <ProductDetail />}
                  />
                  <PrivateRoute
                    path={PRODUCT + "/duplicate/:id"}
                    exact
                    component={(props) => <ProductDuplicate />}
                  />
                  {/* Dashboard */}
                  <PrivateRoute
                    path={DASHBOARD}
                    exact
                    component={(props) => <Dashboard />}
                  />
                  {/* Model */}
                  <PrivateRoute
                    path={MODEL + "/limit/:limit/page/:page"}
                    exact
                    component={(props) => <ModelList />}
                  />
                  <PrivateRoute
                    path={MODEL + "/add"}
                    exact
                    component={(props) => <ModelAdd />}
                  />
                  <PrivateRoute
                    path={MODEL + "/edit/:id"}
                    exact
                    component={(props) => <ModelEdit />}
                  />
                  <PrivateRoute
                    path={MODEL + "/detail/:id"}
                    exact
                    component={(props) => <ModelDetail />}
                  />
                  <PrivateRoute
                    path={MODEL + "/duplicate/:id"}
                    exact
                    component={(props) => <ModelDuplicate />}
                  />
                </div>
              </Main>
            </React.Fragment>
          )}
        />
      </Switch>
    </Router>
  );
}

export default Routes;
