import moment from "moment";
import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import { BsCheckLg } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { BODY_DEALERLIST2, COLOR_PRIMARY, customStyles } from "../../../consts";
import {
  GET_MANY_SHOP_PORTAL,
  PRODUCT_BRAND,
  GET_ALL_DEALER_PRODUCT,
  UPDATE_DEALER_STATUS_MANY,
  UPDATE_DEALER_REJECT_ONE,
  ENPOINTE_API,
} from "../../../consts/api";

import { getLocalData } from "../../../consts/tokken";
import AnimationLoading from "../../../helper/loading";

import "./style.css";
import styled from "styled-components";

//helper
import { formatDate, thousand } from "../../../helper";
import { FormCheck, Form } from "react-bootstrap";
import { ADMIN_PORTAL } from "../../../consts/router";
import Select from "react-select";

export default function ProductWaiting({ id }) {
  const { match, history } = useReactRouter();
  const _limit = 40;
  const _page = match.params.page;
  const shopPortalId = match?.params?.id;

  const [isLoading, setIsLoading] = useState(false);
  // const [startDate, setstartDate] = useState(moment(moment().subtract(30, "days")).format("YYYY-MM-DD"));
  const [startDate, setstartDate] = useState();
  const [endDate, setendDate] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  const [totalData, setTotalData] = useState(0);

  const [brandId, setBrandId] = useState("");
  const [brandName, setBrandName] = useState("");
  const [brands, setBrands] = useState([]);

  const [shopCategories, setShopCategories] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  const [totalItems, setTotalItems] = useState(0);

  const [shopProducts, setShopProducts] = useState([]);
  const [productTotal, setProductTotal] = useState(0);

  const [getTokken, setgetTokken] = useState();
  const [totalPage, setTotalPage] = useState([]);
  const [productData, setProductData] = useState();

  const [button, setButton] = useState("");
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");
  const [statusProduct, setStatusProduct] = useState();
  // approved
  const [dataProductIdArr, setDataProductIdArr] = useState([]);
  const [confirmApprovedSelect, setConfirmApprovedSelect] = useState(false);
  const [confirmApprovedAll, setConfirmApprovedAll] = useState(false);
  const [showFormShowReason, setFormShowReason] = useState(false);

  //important state!!
  //id for update state
  const [idWhenApprove, setIdWhenApprove] = useState("");
  const [otherReason, setOtherReason] = useState(true);
  const [getReason, setGetReason] = useState("");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        setgetTokken(_localData);
        getProductDatas(_localData);
        fetchAdminShopPortalData(_localData);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    getShopProductByFilter();
  }, [categoryId, brandId, startDate, endDate]);

  useEffect(() => {
    getShopProductByFilter();
  }, [pageNumber]);

  const getProductDatas = async (token) => {
    try {
      setIsLoading(true);
      let reqUrl = `&${categoryId ? `productCategory=${categoryId}&` : ""}${
        brandId ? `brand=${brandId}&` : ""
      }${
        startDate && endDate
          ? `startDate=${startDate}&endDate=${moment(
              moment(endDate).add(1, "days")
            ).format("YYYY-MM-DD")}`
          : ""
      }`;

      await fetch(
        `${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${_page}/?shopPortal=${shopPortalId}&status=REQUESTING${reqUrl}`,
        {
          method: "GET",
          headers: token,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          setProductTotal(json?.total ?? 0);
          if (json?.total > 0) {
            console.log("json?.total:::", json?.total);
            _getArrayPageNumber(json?.total);
            setTotalData(json?.total);
            setShopProducts(json?.result ?? []);
            setTotalPage(parseInt(json?.total / _limit) + 1);
          }
        });
      setIsLoading(false);
    } catch (error) {
      console.log("error: ", error);
      setIsLoading(false);
    }
  };

  const getShopProductByFilter = async () => {
    try {
      var data;
      if (categoryId && !brandId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REQUESTING&shopPortal=${shopPortalId}&productCategory=${categoryId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if (brandId && !categoryId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REQUESTING&shopPortal=${shopPortalId}&brand=${brandId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if (categoryId && brandId) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REQUESTING&shopPortal=${shopPortalId}&productCategory=${categoryId}&brand=${brandId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      } else if ((!categoryId && !brandId && startDate) || endDate) {
        data = await axios.get(`
         ${GET_ALL_DEALER_PRODUCT}/limit/${_limit}/page/${pageNumber}?status=REQUESTING&shopPortal=${shopPortalId}&startDate=${moment(
          startDate
        ).format("YYYY/MM/DD")}&endDate=${moment(
          moment(endDate).add(1, "days")
        ).format("YYYY/MM/DD")}
        `);
      }
      console.log("data ==>>> ", data);
      if (data.status >= 300) return { error: true, msg: "NOT_FOUND" };
      if (data?.data) {
        setTotalItems(data?.data?.total);
        setShopProducts(data?.data?.result);
        _getArrayPageNumber(data?.data?.total);
        setTotalPage(parseInt(data?.data?.total / _limit) + 1);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAdminShopPortalData = async (token) => {
    try {
      setIsLoading(true);
      const _categories = await axios(
        GET_MANY_SHOP_PORTAL + "/" + shopPortalId,
        {
          method: "GET",
          headers: token,
        }
      );
      await setShopCategories(_categories.data.shopCategories);
      await setIsLoading(false);
    } catch (err) {
      console.log("err: ", err);
      setIsLoading(false);
    }
  };

  const handleChangeCategory = (id) => {
    if (id) {
      getBrand(id);
    }
    setCategoryId(id);
    setBrandName("");
    setBrands(null);
    setBrandId("");
  };

  const handleChangeBrand = (e) => {
    setBrandName(e);
    setBrandId(e.value);
  };
  const getBrand = async (category) => {
    try {
      await fetch(
        `${PRODUCT_BRAND}/?${category ? `category=${category}` : ""}`,
        {
          method: "GET",
          headers: getTokken,
        }
      )
        .then((response) => response.json())
        .then((json) => {
          const options = json.map((d) => ({
            value: d._id,
            label: d.name,
          }));
          setBrands(options ?? []);
        });
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const _startDate = (e) => {
    setstartDate(e?.target?.value);
  };
  const _endDate = (e) => {
    setendDate(e?.target?.value);
  };
  const _nextPage = async (page) => {
    setPageNumber(parseInt(page));
  };

  const _getArrayPageNumber = (_dataCustomerTotal) => {
    let rowPage = [];
    let _total = 0;
    if (_dataCustomerTotal % parseInt(_limit) != 0) {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(_dataCustomerTotal) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setProductData(rowPage);
  };

  const updateState = async () => {
    setIsLoading(true);
    setShow(false);

    try {
      let _data = {
        id: idWhenApprove,
        status: statusProduct,
        type: "ONE",
      };

      const _approvedSelect = await axios(UPDATE_DEALER_STATUS_MANY, {
        method: "post",
        headers: getTokken,
        data: _data,
      });

      if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
        await _handleCloseConfirmSelect();
        await getProductDatas();
        await setIsLoading(false);
      }
    } catch (error) {
      console.log("error --->", error);
    }
  };

  // TODO:_onComfirmReject
  const _handleCloseFormReason = () => {
    setFormShowReason(false);
    setGetReason("");
  };
  const _onComfirmReject = async () => {
    setIsLoading(true);
    setFormShowReason(false);

    try {
      let _data = {
        note: getReason,
      };

      const _approvedSelect = await axios(
        UPDATE_DEALER_REJECT_ONE + idWhenApprove,
        {
          method: "post",
          headers: getTokken,
          data: _data,
        }
      );

      if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
        await _handleCloseFormReason();
        await getProductDatas();
        await setIsLoading(false);
      }
    } catch (error) {
      console.log("error --->", error);
    }
  };

  // TODO: select product

  const _selectCheckBox = (e) => {
    const { name, checked } = e.target;

    if (name === "allSelect") {
      let tempProduct = shopProducts?.map((product) => {
        return { ...product, isChecked: checked };
      });
      let tempProduct2 = JSON.parse(JSON.stringify(tempProduct));
      setShopProducts(tempProduct);
      if (checked === true) {
        let arr1 = [];
        for (var b = 0; b < tempProduct2?.length; b++) {
          delete tempProduct2[b]?.__typename;
          delete tempProduct2[b]?.isChecked;
          arr1.push({ ...tempProduct2[b] });
        }
        setDataProductIdArr(arr1);
      } else {
        setDataProductIdArr([]);
      }
    } else {
      let dataArray = [...dataProductIdArr];
      for (var i = 0; i < shopProducts?.length; i++) {
        if (shopProducts[i]?._id === name) {
          delete shopProducts[i]?.__typename;
          dataArray.push({ ...shopProducts[i] });
          setDataProductIdArr(dataArray);
        }
      }
      if (checked === true) {
        setDataProductIdArr(dataArray);
      } else {
        const arrayId = dataProductIdArr.filter(
          (product) => product?._id !== name
        );
        setDataProductIdArr(arrayId);
      }
      let tempProduct = shopProducts?.map((product) =>
        product?._id === name ? { ...product, isChecked: checked } : product
      );
      setShopProducts(tempProduct);
    }
  };
  //TODO: run approved select

  const _handleCloseConfirmSelect = () => setConfirmApprovedSelect(false);
  const _handleShowConfirmSelect = () => setConfirmApprovedSelect(true);
  const _onApprovedSelect = () => {
    _handleShowConfirmSelect();
  };

  const _onComfirmApprovedSelect = async () => {
    try {
      let _productId = [];
      for (let i = 0; i < dataProductIdArr.length; i++) {
        _productId.push(dataProductIdArr[i]._id);
      }

      let _data = {
        id: _productId,
        status: "APPROVED",
        type: "MANY",
      };

      const _approvedSelect = await axios(UPDATE_DEALER_STATUS_MANY, {
        method: "post",
        headers: getTokken,
        data: _data,
      });

      if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
        await _handleCloseConfirmSelect();
        await getProductDatas();
      }
    } catch (error) {
      console.log("error --->", error);
    }
  };

  const _handleCloseConfirmAll = async () => {
    setConfirmApprovedAll(false);
  };

  const _onComfirmApprovedAll = async () => {
    setIsLoading(true);
    setShow(false);

    try {
      let _data = {
        shopPortal: shopPortalId,
        startDate: startDate,
        endDate: moment(endDate).add(1, "days").format("YYYY-MM-DD"),
        status: "APPROVED",
        type: "ALL",
      };

      const _approvedSelect = await axios(UPDATE_DEALER_STATUS_MANY, {
        method: "post",
        headers: getTokken,
        data: _data,
      });

      if (_approvedSelect?.data?.message === "SUCCESSFULLY") {
        await _handleCloseConfirmAll();
        await getProductDatas();
        await setIsLoading(false);
        await window.location.reload(true);
      }
    } catch (error) {
      console.log("error --->", error);
    }
  };

  const _onSelectReason = (e) => {
    if (e?.target?.value === "OtherReason") {
      setOtherReason(false);
    }
    setGetReason(e?.target?.value);
  };

  const handleChangeUnread = async (id) => {
    try {
      let dataToUpdate = {
        unReadNewRequesting: false,
      };
      const res = await axios(
        `${ENPOINTE_API}/dealer-product/v2/update-unread-product-status/${id}`,
        {
          method: "put",
          headers: getTokken,
          data: dataToUpdate,
        }
      );

      if (res) console.log(res);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  const _gotoProductDetail = (id) => {
    handleChangeUnread(id);
    history.push(`${ADMIN_PORTAL}/product/detail/${id}/page/${1}`);
  };

  return (
    <div>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          padding: "0 10px",
        }}
      >
        <p style={{ fontWeight: "bold", fontSize: 18 }}>
          Products waiting for approve ({totalData})
        </p>
      </div>
      <div
        style={{
          backgroundColor: "white",
          marginBottom: 20,
          //padding: 50,
          width: "100%",
          height: "auto",
          paddingTop: 24,
        }}
      >
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div style={{ display: "flex" }}>
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Product Category</span>
                <select
                  name="productCategory"
                  className="btn-block form-control"
                  onChange={(e) => handleChangeCategory(e.target.value)}
                  value={categoryId}
                >
                  <option value="">All Categories</option>
                  {shopCategories.length > 0 &&
                    shopCategories.map((item, index) => (
                      <option key={index} value={item?._id}>
                        {item?.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-3">
                <span style={{ fontWeight: "bold" }}>Brand</span>

                <Select
                  placeholder="All Brands"
                  styles={customStyles}
                  value={brandName}
                  options={brands}
                  onChange={(e) => handleChangeBrand(e)}
                />
              </div>
              <SpaceOnFilter />

              <div style={{ display: "flex" }}>
                <div style={{ width: "183px", margin: "0 10px" }}>
                  <span style={{ fontWeight: "bold" }}>Registered from</span>
                  <input
                    type="date"
                    className="form-control col-12"
                    value={startDate}
                    onChange={(e) => _startDate(e)}
                  />
                </div>
                <div style={{ width: "183px", margin: "0 10px" }}>
                  <span style={{ fontWeight: "bold" }}>Register to</span>
                  <input
                    type="date"
                    className="form-control col-12"
                    value={endDate}
                    onChange={(e) => _endDate(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col-sm-12"
        style={{ ...BODY_DEALERLIST2, paddingTop: 24 }}
      >
        <div>
          {/* <div> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <p>
                {" "}
                {shopProducts.length > 0 ? shopProducts.length : 0} /{" "}
                {totalItems}
              </p>
            </div>

            <div>
              <button
                type="button"
                className="btn"
                style={{
                  border: `1px solid ${COLOR_PRIMARY}`,
                  backgroundColor: "#ffffff",
                  color: COLOR_PRIMARY,
                }}
                onClick={() => _onApprovedSelect()}
                disabled={dataProductIdArr.length > 1 ? false : true}
              >
                <span style={{ marginLeft: 10 }}>Approve Selecteds</span>
              </button>
              &nbsp;&nbsp;
              <button
                type="button"
                className="btn"
                style={{ backgroundColor: COLOR_PRIMARY, color: "#ffffff" }}
                onClick={() => setConfirmApprovedAll(true)}
                disabled={shopProducts.length > 0 ? false : true}
              >
                <span style={{ marginLeft: 10 }}>Approve All</span>
              </button>
            </div>
          </div>
          {/* </div> */}
          {isLoading ? (
            <AnimationLoading />
          ) : (
            <div>
              <div style={{ marginTop: 30 }}>
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th>
                        <FormCheck
                          className="ms-4"
                          onChange={(e) => _selectCheckBox(e)}
                          name="allSelect"
                          checked={
                            shopProducts?.filter(
                              (product) => product?.isChecked !== true
                            ).length < 1
                          }
                        />
                      </th>
                      <th>No.</th>
                      <th>Category</th>
                      <th>Brand</th>
                      <th>Model</th>
                      <th>Description</th>
                      <th>Price</th>
                      <th>Currency</th>
                      <th>Created at</th>
                      <th>Manage</th>
                    </tr>
                  </thead>
                  <tbody>
                    {shopProducts &&
                      shopProducts?.map((ele, index) => (
                        <tr
                          key={index}
                          className={ele?.unReadNewRequesting ? "focus" : ""}
                          onClick={() => _gotoProductDetail(ele?._id)}
                        >
                          <td onClick={(e) => e.stopPropagation()}>
                            <FormCheck
                              className="ms-4"
                              name={ele?._id}
                              checked={ele?.isChecked || false}
                              onChange={(e) => _selectCheckBox(e)}
                            />
                          </td>
                          <td>
                            {index +
                              1 +
                              parseInt(_limit) * parseInt(pageNumber - 1)}
                          </td>
                          <td>{ele?.productCategory?.name ?? "-"}</td>
                          <td>{ele?.brand?.name ?? "-"}</td>
                          <td>
                            {ele?.model?.newName ?? ele?.model?.name ?? "-"}
                          </td>
                          <td style={{ width: 300 }}>
                            {ele?.description || "-"}
                          </td>
                          <td>{thousand(ele?.price) || "-"}</td>
                          <td>{ele?.currency || "-"}</td>
                          <td>{formatDate(ele?.createdAt)}</td>
                          <td
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <button
                              className="product-waiting-reject-button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setTitle("Approved This Product");
                                setText("Do you want to approve this product?");
                                setButton("Approve");
                                setIdWhenApprove(ele?._id);
                                setStatusProduct("APPROVED");
                                setShow(true);
                              }}
                            >
                              {" "}
                              <BsCheckLg size="18px" /> Approve
                            </button>
                            <div style={{ padding: 5 }}></div>
                            <button
                              className="product-waiting-approve-button"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                setTitle("Reject This Product");
                                setText("Do you want to reject product?");
                                setButton("Reject");
                                setStatusProduct("REJECTED");
                                setIdWhenApprove(ele?._id);
                                setFormShowReason(true);
                              }}
                            >
                              {" "}
                              <MdClose size="18px" fontWeight="blod" />
                              Reject
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {totalPage && productData?.length > 0 && (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ul className="pagination">
                      {Array.from(Array(totalPage), (e, i) => (
                        <a
                          className="page-link"
                          key={i}
                          style={{
                            backgroundColor:
                              parseInt(pageNumber) == i + 1
                                ? "#9999ff"
                                : "#fff",
                            color:
                              parseInt(pageNumber) == i + 1 ? "#fff" : "#000",
                            cursor: "pointer",
                          }}
                          onClick={() => _nextPage(i + 1)}
                        >
                          {i + 1}
                        </a>
                      ))}
                    </ul>
                  </div>
                )}
              </div>

              <Modal
                show={show}
                onHide={handleClose}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    {title}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}
                  >
                    {text}
                  </div>
                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div className="btn text-danger" onClick={handleClose}>
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{ backgroundColor: "#816AAE", border: "none" }}
                      onClick={updateState}
                    >
                      {button}
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              {/* approved select */}
              <Modal
                show={confirmApprovedSelect}
                onHide={_handleCloseConfirmSelect}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    Approve
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}
                  >
                    Do you want to approve product?
                  </div>
                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn text-danger"
                      onClick={_handleCloseConfirmSelect}
                    >
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{
                        backgroundColor: "#816AAE",
                        border: "none",
                        width: 120,
                      }}
                      onClick={() => _onComfirmApprovedSelect()}
                    >
                      Yes
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              {/* select resone reject */}
              {/* approved select */}
              <Modal
                show={showFormShowReason}
                onHide={_handleCloseFormReason}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    Reject Reason
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form.Control
                    as="select"
                    onChange={(e) => _onSelectReason(e)}
                    hidden={otherReason ? false : true}
                  >
                    <option value=""> Choose type of reject reason....</option>
                    <option value="ຮູບພາບບໍ່ແຈ້ງ, ຮູບບໍ່ກົງກັບສິນຄ້າ, ບໍ່ມີຮູບພາບ">
                      ຮູບພາບບໍ່ແຈ້ງ, ຮູບບໍ່ກົງກັບສິນຄ້າ, ບໍ່ມີຮູບພາບ
                    </option>
                    <option value="ຂໍ້ມູນໝວດສິນຄ້າບໍ່ຖືກຕ້ອງ">
                      ຂໍ້ມູນໝວດສິນຄ້າບໍ່ຖືກຕ້ອງ
                    </option>
                    <option value="ຂໍ້ມູນຍີ່ຫໍ້ສິນຄ້າບໍ່ຖືກຕ້ອງ">
                      ຂໍ້ມູນຍີ່ຫໍ້ສິນຄ້າບໍ່ຖືກຕ້ອງ
                    </option>
                    <option value="ຂໍ້ມູນລຸ້ນສິນຄ້າບໍ່ຖືກຕ້ອງ">
                      ຂໍ້ມູນລຸ້ນສິນຄ້າບໍ່ຖືກຕ້ອງ
                    </option>
                    <option value="ຂໍ້ມູນລາຍລະອຽດສິນຄ້າບໍ່ຖືກຕ້ອງ">
                      ຂໍ້ມູນລາຍລະອຽດສິນຄ້າບໍ່ຖືກຕ້ອງ
                    </option>
                    <option value="OtherReason">
                      ອື່ນ:........................
                    </option>
                  </Form.Control>

                  <Form.Control
                    type="text"
                    onChange={(e) => setGetReason(e?.target?.value)}
                    hidden={otherReason}
                    placeholder="ກະລຸນາປ້ອນເຫດຜົນ...."
                  />

                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn text-danger"
                      onClick={_handleCloseFormReason}
                    >
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{
                        backgroundColor: "#816AAE",
                        border: "none",
                        width: 120,
                      }}
                      disabled={getReason === "" ? true : false}
                      onClick={() => _onComfirmReject()}
                    >
                      Yes
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              <Modal
                show={confirmApprovedAll}
                onHide={_handleCloseConfirmAll}
                // backdrop="static"
                keyboard={false}
              >
                <Modal.Header style={{ borderBottom: "none" }}>
                  <Modal.Title
                    style={{
                      fontSize: 24,
                      color: "#5C5C5C",
                      fontWeight: "bold",
                    }}
                  >
                    Approve
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div
                    style={{ fontSize: 18, color: "#5C5C5C", paddingLeft: 25 }}
                  >
                    Do you want to approve all product?
                  </div>
                  <div style={{ height: 30 }} />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className="btn text-danger"
                      onClick={_handleCloseConfirmAll}
                    >
                      Cancel
                    </div>
                    <p style={{ padding: 10 }}></p>
                    <Button
                      style={{
                        backgroundColor: "#816AAE",
                        border: "none",
                        width: 120,
                      }}
                      onClick={() => _onComfirmApprovedAll()}
                    >
                      Yes
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

const SpaceOnFilter = styled.div`
  width: 40px;
`;
