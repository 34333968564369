import React, { useState } from "react"
import useReactRouter from "use-react-router"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons'
import "../utils/index.css"
import {
    TITLE_HEADER,
    HEADER,
    BODY,
    LIMIT
} from "../../../consts"
import {
    CACULARTOR_LIST
} from '../../../consts/router'
export default function Caculartor() {
    const { history, location, match } = useReactRouter()
    const _ConfigCaculartor = () => {
        history.push(`${CACULARTOR_LIST}/limit/${LIMIT}/page/1`)
    }
    return (
        <div style={TITLE_HEADER}>

            <h6 style={HEADER}>
                <p>Setting » </p>
            </h6>
            <div style={BODY}>
                <div className="row" style={{ padding: 50, }}>
                    <button className="col-2 center divButton" onClick={() => _ConfigCaculartor()}>
                        <FontAwesomeIcon icon={faMoneyCheckAlt} style={{ fontSize: "40px" }} />
                        <div style={{}}>Set up the calculator</div>
                        <p style={{ fontSize: 12 }}>Customize Shop category and product percentage</p>
                    </button>
                </div>
            </div>
        </div>
    )
}