import React, { useState, useEffect } from "react";
import useReactRouter from "use-react-router";
import Image from "react-bootstrap/Image";
import AnimationLoading from "../../../helper/loading.js";
import queryString from "query-string";
import moment from "moment";
import { CSVLink } from "react-csv";
import { AiFillPlusCircle } from "react-icons/ai";

import {
  TITLE_HEADER,
  HEADER,
  BODY_DEALERLIST2,
  BODY_DEALERLIST,
  LIMIT,
  COLOR_PRIMARY,
} from "../../../consts";
import { URL_PHOTO_AW3 } from "../../../consts/api";
import {
  DEALER_SHOP_DETAIL,
  DEALER_SHOP_ADD,
  STORE_DISCOUNT_LIST,
  STORE,
} from "../../../consts/router";
import profileImage from "../../../image/profile.png";
import {
  DEALER_SHOP,
  DISCOUNT_SHOP,
  CATEGORY_STORE,
} from "../../../consts/api";
import { getLocalData } from "../../../consts/tokken";

import { formatDate } from "../../../common/super";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faFileExcel } from "@fortawesome/free-solid-svg-icons";

export default function DealerList() {
  const { history, location, match } = useReactRouter();
  const _limit = match.params.limit;
  const _page = match.params.page;
  const parsed = queryString.parse(location.search);
  const newDate = new Date();
  const [togglePage, setTogglePage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const _discountShop = () => {
    history.push(`${STORE_DISCOUNT_LIST}/limit/${LIMIT}/page/1`);
  };
  // history
  const _dealerShopDetail = (id) => {
    history.push(`${DEALER_SHOP_DETAIL}${id}`, id);
  };
  const _dealerShopAdd = () => {
    history.push(`${DEALER_SHOP_ADD}`);
  };
  // get discountShop
  const [getDealerShops, setGetDealerShops] = useState();
  const [categoryStore, setCategoryStore] = useState();
  const _renderName = (e) => {
    let res = categoryStore?.filter((x, index) => x._id == e);
    if (res?.length > 0) return res[0]?.name;
  };
  // ====state_fillter ===
  const [fillterName, setfillterName] = useState(
    parsed?.name ? parsed?.name : ""
  );
  // const [fillterId, setfillterId] = useState(parsed?._id ? parsed?._id : "");
  const [storecategory_name, setstorecategory_name] = useState(
    parsed?.storecategory_name ? parsed?.storecategory_name : ""
  );
  const [startDate, setstartDate] = useState(
    parsed?.startDate ? parsed?.startDate : "2020-01-01"
  );
  const [endDate, setendDate] = useState(
    parsed?.endDate
      ? parsed?.endDate
      : moment(moment(newDate)).format("YYYY-MM-DD")
  );
  // =====
  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, [fillterName]);
  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, [startDate && endDate]);
  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, [endDate && startDate]);
  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, [storecategory_name]);
  useEffect(() => {
    const fetchData = async () => {
      const _localData = await getLocalData();
      if (_localData) {
        getData(_localData);
      }
    };
    fetchData();
  }, []);
  const getData = async (tokken) => {
    setIsLoading(true);
    let url = DEALER_SHOP + `/limit/${_limit}/page/${_page}/?storeType=DEALERSHOP`
    if (fillterName) {
      const startsWithNumber = /^[0-9]/.test(fillterName);
      if (startsWithNumber) {
        url += `&&Id=${fillterName}`;
      } else {
        url += `&&name=${fillterName}`;
      }
    }
    if (storecategory_name) {
      url += `&&storecategory_name=${storecategory_name}`;
    }
    if (startDate && endDate) {
      url += `&&startDate=${startDate}&&endDate=${moment(
        moment(endDate).add(1, "days")
      ).format("YYYY-MM-DD")}`;
    }

    // DEALER_SHOP +
    //   `/limit/${_limit}/page/${_page}/?storeType=DEALERSHOP${fillterName ? `&&name=${fillterName}` : ""
    //   }${storecategory_name ? `&&storecategory_name=${storecategory_name}` : ""
    //   }${startDate && endDate
    //     ? `&&startDate=${startDate}&&endDate=${moment(
    //       moment(endDate).add(1, "days")
    //     ).format("YYYY-MM-DD")}`
    //     : ""
    //   }`

    await fetch(url,
      {
        method: "GET",
        headers: tokken,
      }
    )
      .then((response) => response.json())
      .then((json) => setGetDealerShops(json));
    await fetch(CATEGORY_STORE, {
      method: "GET",
      headers: tokken,
    })
      .then((response) => response.json())
      .then((json) => setCategoryStore(json));
    setIsLoading(false);
  };
  useEffect(() => {
    if (getDealerShops?.total > 0) {
      _getArrayPageNumber();
    }
    console.log("getDealerShop: ", getDealerShops);
  }, [getDealerShops]);
  const [totalPage, setTotalPage] = useState([]);
  const _getArrayPageNumber = () => {
    let rowPage = [];
    let _total = 0;
    if (getDealerShops?.total % parseInt(_limit) != 0) {
      _total = parseInt(getDealerShops?.total) / parseInt(_limit) + 1;
    } else {
      _total = parseInt(getDealerShops?.total) / parseInt(_limit);
    }
    for (let i = 1; i <= _total; i++) {
      rowPage.push(i);
    }
    setTotalPage(rowPage);
  };
  const _nextPage = (page) => {
    history.push(
      `${STORE}/limit/${_limit}/page/${page}/?${fillterName ? `&&name=${fillterName}&&` : ""
      }${storecategory_name ? `&&storecategory_name=${storecategory_name}&&` : ""
      }${startDate && endDate
        ? `&&startDate=${startDate}&&endDate=${endDate}`
        : ""
      }`
    );
  };
  // ===== fillter
  const _fillterName = (e) => {
    setfillterName(e?.target?.value)
  };
  const _category = (e) => {
    setstorecategory_name(e?.target?.value);
  };
  const _startDate = (e) => {
    setstartDate(e?.target?.value);
  };
  const _endDate = (e) => {
    setendDate(e?.target?.value);
  };
  return (
    <div style={TITLE_HEADER}>
      <h6 style={HEADER}>
        <p>All Shops » </p>
      </h6>
      <ul
        className="nav nav-tabs col-sm-11"
        id="myTab"
        style={{ marginLeft: 30, border: "solid 0px" }}
      >
        <li className="nav-item" style={{ border: "solid 0px" }}>
          <a
            style={{ border: "solid 0px" }}
            className="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#home"
            role="tab"
            aria-controls="home"
            aria-selected="true"
          >
            Dealer Shop
          </a>
        </li>
        <li className="nav-item">
          <a
            className="nav-link"
            id="contact-tab"
            data-toggle="tab"
            href="#contact"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            onClick={() => _discountShop()}
            style={{ cursor: "pointer", color: "blue" }}
          >
            Discount Shop
          </a>
        </li>
      </ul>
      <div className="col-sm-12" style={BODY_DEALERLIST}>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home"
            role="tabpanel"
            aria-labelledby="home-tab"
          >
            <div>
              <p style={{ fontWeight: "bold" }}>Search</p>
              <input
                type="text"
                className="col-md-12 form-control"
                value={fillterName}
                onChange={(e) => _fillterName(e)}
              />
            </div>
            <div style={{ height: 10 }}></div>
            <div className="row">
              <div className="col-md-4">
                <p style={{ fontWeight: "bold" }}>Category</p>
                <select
                  name="cars"
                  id="cars"
                  className="btn-block form-control"
                  value={storecategory_name}
                  onChange={(e) => _category(e)}
                >
                  <option value="">All</option>
                  {categoryStore?.map((item, index) => {
                    return <option value={item?._id}>{item?.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-md-4">
                <p style={{ fontWeight: "bold" }}>Registered from</p>
                <input
                  type="date"
                  className="form-control col-12"
                  value={startDate}
                  onChange={(e) => _startDate(e)}
                />
              </div>
              <div className="col-md-4">
                <p style={{ fontWeight: "bold" }}>Register to</p>
                <input
                  type="date"
                  className="form-control col-12"
                  value={endDate}
                  onChange={(e) => _endDate(e)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-12" style={BODY_DEALERLIST2}>
        <div style={{ display: togglePage, padding: 20 }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div>All Shops ({getDealerShops?.total})</div>
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                style={{ backgroundColor: COLOR_PRIMARY }}
                onClick={() => _dealerShopAdd()}
              >
                <AiFillPlusCircle style={{ fontSize: 20 }} />
                <span style={{ marginLeft: 10 }}>Add Shop</span>
              </button>

              {/* <CSVLink
								data={
									getDealerShops?.results?.map((dealer,index) => ({
										"NO.": index + 1,
										"Shop name": dealer?.name ?? "",
										"Category": _renderName(dealer?.storecategory?._id),
										"Phone number": dealer?.phoneNumber,
										"Registration date": moment(dealer?.createdAt).format('DD/MM/YYYY'),
										"Village": dealer?.village ?? "",
										"District": dealer?.district ?? "",
										"Province": dealer?.province ?? "",
										"Latitude": dealer?.lat ?? "",
										"Longtitude": dealer?.lng ?? "",
										"LinkFacebook": dealer?.linkFacebook ?? "",
									})) || []
								}
								filename={"Shops"}
								style={{ textDecoration: "none" }}
							>
								<button
									className="btn"
									style={{
										border: `1px solid ${COLOR_PRIMARY}`,
										backgroundColor: "white",
										color: COLOR_PRIMARY
									}}
								>
									<FontAwesomeIcon icon={faFileExcel} color={COLOR_PRIMARY} />
									<span style={{ marginLeft: 10 }}>Download Excel</span>
								</button>
							</CSVLink> */}
            </div>
          </div>
          {isLoading ? (
            <AnimationLoading />
          ) : (
            <div>
              <div className="col-sm-12">
                <table className="table table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">ID</th>
                      <th scope="col">Pictures</th>
                      <th scope="col">Shop name</th>
                      <th scope="col">Category</th>
                      <th scope="col">Phone number</th>
                      <th scope="col">Registration date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {getDealerShops?.results?.map((dealer, index) => {
                      return (
                        <tr onClick={() => _dealerShopDetail(dealer?._id)}>
                          <th scope="row">
                            {index + 1 + parseInt(_limit) * parseInt(_page - 1)}
                          </th>
                          <td>{dealer?._id}</td>
                          <td>
                            {dealer?.image ? (
                              <Image
                                src={URL_PHOTO_AW3 + dealer?.image}
                                alt="AeonIcon"
                                width="150"
                                height="150"
                                style={{
                                  height: 50,
                                  width: 50,
                                  borderRadius: 5,
                                }}
                              />
                            ) : (
                              <Image
                                src={profileImage}
                                alt="AeonIcon"
                                width="150"
                                height="150"
                                style={{
                                  height: 50,
                                  width: 50,
                                  borderRadius: 5,
                                }}
                              />
                            )}
                          </td>
                          <td>{dealer?.name}</td>
                          <td>{_renderName(dealer?.storecategory?._id)}</td>
                          <td>{dealer?.phoneNumber}</td>
                          <td>{formatDate(dealer?.createdAt)}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <div className="row center">
                  {totalPage?.map((item, index) => {
                    return (
                      <button
                        style={{
                          width: 30,
                          height: 30,
                          border: "solid 1px #816aae",
                          marginLeft: 2,
                          backgroundColor:
                            parseInt(_page) == index + 1 ? "#9999ff" : "#fff",
                          color: parseInt(_page) == index + 1 ? "#fff" : "#000",
                        }}
                        onClick={() => _nextPage(item)}
                        key={item}
                      >
                        {item}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
