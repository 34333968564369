import React, { useState, useEffect } from "react"
import useReactRouter from "use-react-router"
import {
	TITLE_HEADER,
	HEADER,
	BODY,
} from "../../../consts"
import "../utils/index.css"
import {
	NOTIFICATION_ADD,
	NOTIFICATION_DETAIL
} from "../../../consts/router"
export default function NotificationList() {
	const { history, location, match } = useReactRouter()
	const _notificationAdd = () => {
		history.push(`${NOTIFICATION_ADD}`)
	}
	const _notificationDetail = () => {
		history.push(`${NOTIFICATION_DETAIL}`)
	}
	return (
		<div style={TITLE_HEADER}>
			<h6 style={HEADER}>
				<p>ແຈ້ງເຕືອນທັງໝົດ » </p>
			</h6>
			<div style={BODY}>
				<div className="row">
					<div className="col-md-8">
						<div style={{ padding: 30, marginLeft: 30, fontSize: "18px" }}> ແຈ້ງເຕືອນໂປຣໂມຊັນທັງໝົດ (20)</div>
					</div>
					<div className="col-md-4">
						<div style={{ fontWeight: "bold", paddingBottom: 10 }}>ຄົ້ນຫາ</div>
						<input type="text" placeholder="ປ້ອນ ຫົວຂໍ້ ຫຼື ID…" className="form-control" />
					</div>
				</div>
				<div className="col-md-12">
					<div className="right">
						<button type="button" className="btn btn-secondary" style={{ backgroundColor: "#816AAE" }} onClick={() => _notificationAdd()}>ສ້າງແຈ້ງເຕືອນ</button>
					</div>
				</div>
				<div style={{height:50}}></div>
				<div>
					<div className="col-md-12">
						<table className="table table-hover">
							<thead className="thead-light">
								<tr>
									<th scope="col">#</th>
									<th scope="col">ໂປຣໂມຊັ່ນ ID</th>
									<th scope="col">ໂປຣໂມຊັ່ນທີ່ເລືອກ</th>
									<th scope="col">ຫົວຂໍ້</th>
									<th scope="col">ເວລາແຈ້ງເຕືອນ</th>
									<th scope="col">ເວລາສ້າງ</th>
								</tr>
							</thead>
							<tbody>
								<tr onClick={() => _notificationDetail()}>
									<th scope="row">1</th>
									<td>1234</td>
									<td>ໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>ມາແລ້ວໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>2020/6/28 17:00</td>
									<td>2020/6/28</td>
								</tr>
								<tr >
									<th scope="row">1</th>
									<td>1234</td>
									<td>ໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>ມາແລ້ວໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>2020/6/28 17:00</td>
									<td>2020/6/28</td>
								</tr>
								<tr >
									<th scope="row">1</th>
									<td>1234</td>
									<td>ໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>ມາແລ້ວໂປໂມຊັ່ນເຄື່ອງໃຊ້ໄຟຟ້າ</td>
									<td>2020/6/28 17:00</td>
									<td>2020/6/28</td>
								</tr>

							</tbody>
						</table>
					</div>
				</div>
			</div>
		</div>
	)
}
